import {
  Grid, Typography, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
    padding: 5px;
    width: 100%;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      height: 2rem;
    }

    &:hover {
      background: #f3f7fc;
    }
`;

export const GridWrapper = styled(Grid)`
      display: flex;
      flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1.7rem;
  font-weight: 400;
  color: #464f5b;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: .75rem;
  }
`;

export const ItemWrapper = styled(IconButton)`
  height: 100% !important;
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
