import React from 'react';
import { IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import {
  AvailableText, BalanceCard, BalanceText, Container, ContentContainer, Header, HeaderText, LimitText
} from './walletBalance.styles';
import { parseJson } from '../../../affiliates/utils';

const WalletContainer = ({ data }) => {
  const { credit_limit: cLimit } = parseJson(data?.business?.creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
  return (
    <Container>
      <Header>
        <HeaderText>
          Credit Wallet
        </HeaderText>
      </Header>
      <BalanceCard>
        <ContentContainer>
          <div>
            <AvailableText variant="body2">Available Wallet Balance</AvailableText>
            <BalanceText variant="h5">
              ₦
              {data?.business?.creditWalletBalance}
            </BalanceText>
            <LimitText variant="body2">
              ₦
              {cLimit}
              {' '}
              Limit
            </LimitText>
          </div>
          <IconButton>
            <LockIcon color="action" />
          </IconButton>
        </ContentContainer>
      </BalanceCard>
    </Container>
  );
};

WalletContainer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,

};

export default WalletContainer;
