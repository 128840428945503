import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 12px;
  margin-top: 20px;

`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 15px;
  border-bottom: .5px solid #E3E7EA;
`;

export const HeaderText = styled('div')`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #383D43;

`;

export const BalanceCard = styled(Card)`
  padding: 5px 15px;
  border-radius: 4px;
  border: .5px solid #E3E7EA;
  box-shadow: 0px 5.42px 43.33px 0px #30303008;

`;

export const AvailableText = styled(Typography)`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12.64px;
  line-height: 100%;
  color: #464F5B;
`;

export const BalanceText = styled(Typography)`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16.25px;
  line-height: 100%;
  color: #464F5B;
  padding: 7px 0px
`;

export const LimitText = styled(Typography)`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12.83px;
  line-height: 100%;
  color: #5F6F7B;
  padding-top: 20px;
`;

export const ContentContainer = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0 0;
`;
