import { styled } from '@mui/material/styles';
import {
  Alert, Button, Grid, Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CheckIcon } from '../../../assets/svgs';

export const PaymentSummaryCardHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ECECEC;

  @media (min-width: 992px) {
    padding-top: 1.5rem;
    padding-bottom: 17px;
    border-bottom: 1px solid #E8E8E8;
  }
  @media (max-width: 991px) {
    margin-bottom: 5rem;
  }
`;

export const PaymentSummaryCardHeaderTitle = styled('p')`
  margin: 0;
  color: #383d43;
  font-weight: 700;
  font-size: 2rem;

  @media (min-width: 992px) {
    font-size: 18px;
    font-weight: 600;
    color: #383d43;
  }
`;

export const PaymentSummaryRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  @media (max-width: 991px) {
    margin-bottom: 4rem;
  }
`;

export const PaymentSummaryRowTitle = styled('p')`
  color: #7a8b96;
  font-size: 2rem;
  line-height: 32px;
  font-weight: 500;
  margin: 0;

  &:nth-child(2n) {
    margin-left: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const PaymentSummaryRowBoldTitle = styled('p')`
  color: #383d43;
  font-size: 2rem;
  line-height: 32px;
  font-weight: 500;
  margin: 0;

  &:nth-child(2n) {
    margin-left: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 14px;
    font-weight: 500;
    color: #383d43;
  }
`;

export const PaymentSummaryGrandTotalRow = styled('div')`
  display: flex;
  align-items: center;
  border-top: 1px solid #E8E8E8;
  padding-top: 20px;
  margin-top: 20px;
  justify-content: space-between;

  @media (max-width: 991px) {
    padding-top: 35px;
    border-top: 2px solid #ECECEC;
  }
`;

export const PayNowButton = styled(Button)`
  background-color: #235A91;
  border-radius: 5px;
  color: #fff;
  padding: 15px 10px;
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 600;
  @media (max-width: 991px) {
    font-size: 1.7rem;
    margin-top: 1rem;
    padding: 25px 10px;
  }
  &:hover {
    background-color: #235A91;
  }
  &:disabled {
    background-color: #235A91;
    color: #FFFFFF;
  }
`;

export const ContinueButton = styled(Button)`
  border: 1px solid #235A91;
  font-size: 1rem;
  border-radius: 5px;
  color: #235A91;
  padding: 15px 10px;
  margin-top: 20px;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 1.7rem;
    padding: 25px 10px;
  }
`;

export const TipTool = styled('span')`
`;

export const ButtonWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 4px 20px 0px #00000026;
  background: #FFFFFF;
  padding: 30px 40px 50px;
  width: 100%;
  z-index: 10;
  gap: 10px;
  margin-bottom: 2.5rem;
`;

export const RfqContainer = styled(Grid)`
  display: flex;
  width: 100%;
  background-color: #ECF6FF;
  box-shadow: 4px 4px 6.6px 0px #FFFFFF40;
  justify-content: space-between;
  padding: 20px 30px;
  border-radius: 6px;
  margin: 35px 0px 5px;
  align-items: center;

  @media(max-width: 991px) {
    margin-top: 5rem;
    padding: 35px;
  }

`;

export const TestWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ViewItem = styled(Grid)`
  color: #383d43;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.82px;
  cursor: pointer;

  @media(max-width: 991px) {
      font-size: 2rem;
  }
`;

export const Info = styled(Grid)`
  color: #464f5b;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.56px;
  @media(max-width: 991px) {
    font-size: 1.8rem;
  }
`;

export const CheckImg = styled(CheckIcon)`
&.MuiSvgIcon-root {
  width: 1.4rem;
  height: 1.4rem;

  @media(max-width: 991px) {
    width: 3rem;
    height: 3rem;
  }
}
`;

export const AlertBox = styled(Alert)`
  background-color: #fef3f2;
  border-radius: 5px;
  margin-bottom: 1.5rem;
`;

export const RedInfoIcon = styled(InfoIcon)`
  color: #E74C3C;
`;

export const AlertHeader = styled(Typography)`
  color: #383D43;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

`;

export const AlertText = styled(Typography)`
  color: #5F6F7B;
  font-family: Inter;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-top: 7px;

`;
