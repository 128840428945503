import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  TableContainer, Table, useMediaQuery, Grid
} from '@mui/material';
import MainContent from '../customComponents/mainContent';
import { AFFILIATE_INVOICE_QUERY } from '../../queries/affiliates';
import {
  ContentBox, HeaderCell, DetailBox, FooterGrid, MobileContainer, FilterBox, FilterImg,
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle, AddCategory, TextWrapper, TableGrid, MainTableHead, MainTableBody, SubTitle
} from './paymentsContainer.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import PaymentsFilter from './filter/paymentsFilter';
import filterImg from '../../assets/images/marketOrder/filter-search.png';
import MobileReturnRow from './mobileReturnRow';
import MobileFilter from './mobileFilter';
import TablePagination from '../shared/tablePagination';
import { addHyphen } from '../shared/methods';

const PaymentsContainer = () => {
  const headers = [
    'S/N', 'Affiliate Name', 'Order Number', 'Invoice', 'Amount Paid', 'Invoice Number', 'Payment Status', 'Credit Days', 'Action'
  ];
  const isSmall = useMediaQuery('(max-width: 991px)');

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const initialFilter = {
    invoiceNo: null,
    orderNo: null,
    affiliateName: '',
    status: ''
  };

  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initialFilter);

  const {
    orderNo, invoiceNo, affiliateName, status
  } = state;

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (!value && [name] in filters) {
      setFilters((_state) => {
        const copy = { ..._state };
        delete copy[name];
        return copy;
      });
      return;
    }
    setFilters((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    status,
    orderNo,
    invoiceNo,
    affiliateName
  };

  const {
    loading, error, data, refetch
  } = useQuery(AFFILIATE_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const handleSetfilter = () => {
    setState({ ...filters });
  };

  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);

  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  const handleClearFilter = () => {
    setFilters(initialFilter);
  };

  const affiliateInvoices = data?.affiliateInvoices || [];
  const invoicesTotalNumber = data?.invoicesTotalNumber || 0;

  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <ContentBox>
        {!isSmall && (
          <>
            <TitleGridContainer container item xs={12} justifyContent="space-between">
              <TitleTextGridContainer item md={5}>
                <PrimaryTitle variant="h5">Payment</PrimaryTitle>
                <SubTitle>View all of your downloads here </SubTitle>
              </TitleTextGridContainer>
              <Grid container md={7} spacing={2} justifyContent="flex-end">
                <AddCategory
                  data-testid="export"
                  item
                  className="mpAdmin-uat-customerCategory-add-category"
                >
                  <TextWrapper>
                    Bulk Upload
                  </TextWrapper>
                </AddCategory>
              </Grid>
            </TitleGridContainer>
            <DetailBox>
              <PaymentsFilter
                handleFilterChange={handleFilterChange}
                handleSetFilter={handleSetfilter}
              />
              <TableGrid item container>

                {loading ? <ProductsTableLoader /> : (
                  <TableContainer item container>
                    <Table aria-label="payments table" data-testid="mp-uat-payments-table">
                      <MainTableHead item container>
                        {headers.map((header) => (
                          <HeaderCell key={header} data-testid={`mp-uat-payments-${addHyphen(header)}`}>{header}</HeaderCell>
                        ))}
                      </MainTableHead>
                      <MainTableBody item container>
                        {affiliateInvoices.map((paymentItem, index) => (
                          <ReturnRow
                            key={paymentItem?.id}
                            paymentItem={paymentItem}
                            index={index}
                            refetch={refetch}
                          />
                        ))}
                      </MainTableBody>
                    </Table>
                  </TableContainer>
                )}
              </TableGrid>
              {!displayMobileFilter && (
                <FooterGrid item container>
                  <TablePagination
                    total={invoicesTotalNumber}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                </FooterGrid>
              )}
            </DetailBox>
          </>
        )}

        {isSmall && (
          displayMobileFilter ? (
            <MobileFilter
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleSetFilter={handleSetfilter}
              handleMobile={handleMobileFilter}
              handleClear={handleClearFilter}
            />
          )
            : (
              <MobileContainer>
                <FilterBox>
                  <FilterImg src={filterImg} alt="filter" onClick={handleMobileFilter} />
                </FilterBox>
                {loading ? <ProductsTableLoader /> : (
                  affiliateInvoices.map((paymentItem) => (
                    <MobileReturnRow
                      key={paymentItem?.id}
                      paymentItem={paymentItem}
                    />
                  ))
                )}
              </MobileContainer>
            )
        )}
      </ContentBox>
    </MainContent>
  );
};

export default PaymentsContainer;
