import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  BodyCell, ColorGrid, Dot, MainTableRow, Text
} from './returnRow.styles';
import currencyFormatter from '../shared/currencyFormatter';
import AddPaymentDialog from './addPaymentDialog/addPaymentDialog';

const ReturnRow = ({ paymentItem, index, refetch }) => {
  const navigate = useNavigate();
  const [paymentDialog, setPaymentDialog] = useState(false);
  const {
    id, business, creditDays, order, status, total, amountPaid
  } = paymentItem;
  const invoiceValue = currencyFormatter(total);
  const amount = currencyFormatter(amountPaid);

  const handleClick = (type) => {
    if (type === 'affiliateName') return navigate(`/manage-affiliates/${business.id}/details`);
    return navigate(`/orders/${order.id}/detail`);
  };

  const handleDialog = () => {
    setPaymentDialog(!paymentDialog);
  };
  const getColorGrid = (bkg, name) => (
    <ColorGrid item container>
      <Dot background={bkg} />
      {name}
    </ColorGrid>
  );

  const getStatus = () => {
    switch (status) {
      case 'PAID':
        return getColorGrid('#00B588;', 'Paid');
      case 'PENDING':
        return getColorGrid('#F29053', 'Pending');
      case 'PARTIALLY_PAID':
        return getColorGrid('#E79800', 'Partially Paid');
      case 'OVERDUE':
        return getColorGrid('#E74C3C', 'OverDue');
      default:
        return getColorGrid('#FF0F0F', `${status}`);
    }
  };

  return (
    <>
      <MainTableRow item container>
        <BodyCell>{index + 1}</BodyCell>
        <BodyCell onClick={() => handleClick('affiliateName')}>
          {business.name}
        </BodyCell>
        <BodyCell onClick={() => handleClick('order')}>
          {order.id}
        </BodyCell>

        <BodyCell>
          {invoiceValue}
        </BodyCell>
        <BodyCell>
          {amount}
        </BodyCell>
        <BodyCell>
          {id}
        </BodyCell>
        <BodyCell>
          {getStatus()}
        </BodyCell>
        <BodyCell>
          {creditDays}
        </BodyCell>
        <BodyCell onClick={handleDialog}>
          <Text>Record Payment</Text>
        </BodyCell>
      </MainTableRow>
      <AddPaymentDialog
        open={paymentDialog}
        close={handleDialog}
        payment={paymentItem}
        refetch={refetch}
      />
    </>
  );
};

ReturnRow.propTypes = {
  paymentItem: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
};

export default ReturnRow;
