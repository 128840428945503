import { styled } from '@mui/material/styles';
import {
  DialogContent
} from '@mui/material';
import { EmptyBag } from '../../../assets/svgs';

export const DialogContentWrapper = styled(DialogContent)`
  width: 34rem;
  padding: 2rem 2.5rem;
`;

export const EmptyBagIcon = styled(EmptyBag)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 90%;
  }

  @media (min-width: 992px) {
    &.MuiSvgIcon-root {
      width: 50%;
      height: 50%;
    }
  }
`;
