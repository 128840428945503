import React, { useState } from 'react';
import { Box, Grid, InputAdornment } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import MainContent from '../customComponents/mainContent';
import {
  FooterGrid, MainContainer, OrderGridContainer, ArrowBackText, TitleBox,
  CreditWalletTypo, RepayButton, NewOfferBox, NewOfferSubTitle, NewOfferTTitle,
  CreditBalanceBox, RemainText, BalanceText, TotalBalanceBox, TotalText,
  TotalBoldText, SearchImg, SearchField, IconBox, TransactionBox, TransactionText,
  FilterGriid, FilterButton, OrderBox, ListBox, DetailTypo,
  BoldDetailTypo, ViewDetailButton, StatusBox
} from './creditWallet.styles';
import AffiliateMenu from '../navigation/affiliateNavigation/affiliateMenu';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import {
  CreditIcon, NewOffer, CreditTime, CreditReceipt, FilterIcon,
} from '../../assets/svgs';
import SearchIcon from '../../assets/images/search-normal.png';
import TablePagination from '../shared/tablePagination';
import FilterPopper from './filterPopper';
import PaymentRepayDialog from './repayDialog';
import { GET_CREDIT_ORDERS } from '../../queries/orders';
import currencyFormatter from '../shared/currencyFormatter';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import { JSONParse } from '../../utils/json';
import { BUSINESS_USERS_QUERY } from '../../queries/businesses';

const CreditWallet = () => {
  const initialState = {
    orderId: '', dateFrom: '', dateTo: '', status: ''
  };
  const [closeMenu, setCloseMenu] = useState(false);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [repayDialog, setRepayDialog] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [state, setState] = useState(initialState);
  const [displayNotice, setDisplayNotice] = useState(true);

  const {
    orderId, dateFrom, dateTo, status
  } = state;

  const { data: businessData } = useQuery(BUSINESS_USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data } = useQuery(GET_CREDIT_ORDERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      orderId: orderId?.length > 2 ? orderId : undefined,
      dateFrom,
      dateTo,
      status,
      pageCount,
      pageNumber
    }
  });

  const { orderInvoices, orderInvoicesTotalNumber } = data || [];
  const { businessUsers } = businessData || [];
  const business = businessUsers?.length > 0 ? businessUsers[0]?.business : {};
  const creditWallet = business?.creditWallet;
  const creditWalletBalance = business?.creditWalletBalance;
  const parsedWallet = JSONParse(creditWallet);
  const { credit_days: creditDays, credit_limit: creditLimit } = parsedWallet;

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const handleSubmit = (dateRange) => {
    const { dateFrom: from, dateTo: to } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(from).format('YYYY-MM-DD'),
      dateTo: moment(to).format('YYYY-MM-DD')
    }));
  };

  const handleRepayDialog = () => {
    setRepayDialog(!repayDialog);
  };

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      orderId: value
    }));
  };

  const handleStatusFilter = (statusName) => {
    setState((prevState) => ({
      ...prevState,
      status: statusName
    }));
    setFilterButtonEl(null);
  };

  const getStatus = (stat) => {
    switch (stat) {
      case 'PENDING':
        return 'pending';
      case 'PAID':
        return 'paid';
      case 'OVERDUE':
        return 'overdue';
      default:
        return 'pending';
    }
  };

  return (
    <MainContent>
      <MainContainer item container xs>
        <Box style={{ display: 'flex', cursor: 'pointer', marginBottom: '2rem' }} onClick={() => navigate('/new-order')}>
          <ArrowBackIcon sx={{ fill: '#235a91', fontSize: '15px', marginTop: '2px' }} />
          <ArrowBackText>Back to Home</ArrowBackText>
        </Box>
        <Grid container spacing={3}>
          <Hidden mdDown>
            <AffiliateMenu closeMenu={closeMenu} handleClose={handleCloseMenu} />
          </Hidden>

          <Grid item xs={12} md={closeMenu ? 10.8 : 9.3} lg={closeMenu ? 10.8 : 9.3}>
            <OrderGridContainer>
              <TitleBox>
                <CreditWalletTypo>
                  Credit Wallet
                </CreditWalletTypo>

                <RepayButton onClick={handleRepayDialog}>
                  Repay Credit
                </RepayButton>
              </TitleBox>

              {displayNotice && (
                <NewOfferBox container>
                  <Grid item xs={0.4}>
                    <NewOffer style={{ fontSize: '1.5625rem' }} />
                  </Grid>

                  <Grid item xs={11}>
                    <NewOfferTTitle>
                      OGApharmacy Credit Wallet
                    </NewOfferTTitle>

                    <NewOfferSubTitle>
                      Order seamlessly with your credit limit, track transactions in real-time, and receive instant updates—all in one place. Manage your finances effortlessly and pay when it’s convenient.
                    </NewOfferSubTitle>
                  </Grid>

                  <Grid item xs={0.6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CloseIcon sx={{ fontSize: '1.125rem', fill: '#235a91', cursor: 'pointer' }} onClick={() => setDisplayNotice(!displayNotice)} />
                  </Grid>
                </NewOfferBox>
              )}

              <Grid container spacing={1} style={{ marginTop: '.5rem' }}>
                <Grid item xs={4} container>
                  <CreditBalanceBox container>
                    <Grid item xs={11}>
                      <RemainText>
                        Remaining Credit Balance
                      </RemainText>

                      <BalanceText>
                        {`₦ ${currencyFormatter(creditWalletBalance || 0)}`}
                      </BalanceText>
                    </Grid>

                    <Grid item xs={1}>
                      <IconBox style={{ background: 'rgba(227, 239, 251, 0.1)' }}>
                        <CreditIcon style={{ fontSize: '.9rem' }} />
                      </IconBox>
                    </Grid>
                  </CreditBalanceBox>

                </Grid>

                <Grid item xs={4}>
                  <TotalBalanceBox container>
                    <Grid item xs={11}>
                      <TotalText>
                        Total Credit Allocated
                      </TotalText>

                      <TotalBoldText>
                        {`₦ ${currencyFormatter(+creditLimit || 0)}`}
                      </TotalBoldText>
                    </Grid>

                    <Grid item xs={1}>
                      <IconBox style={{ background: 'rgba(214, 255, 231, 1)' }}>
                        <CreditReceipt style={{ fontSize: '.9rem' }} />
                      </IconBox>
                    </Grid>
                  </TotalBalanceBox>
                </Grid>

                <Grid item xs={4}>
                  <TotalBalanceBox container>
                    <Grid item xs={11}>
                      <TotalText>
                        Total Credit Days
                      </TotalText>

                      <TotalBoldText>
                        {creditDays || 0}
                      </TotalBoldText>
                    </Grid>

                    <Grid item xs={1}>
                      <IconBox style={{ background: 'rgba(255, 244, 222, 1)' }}>
                        <CreditTime style={{ fontSize: '.9rem', fill: 'rgba(255, 244, 222, 1)' }} />
                      </IconBox>
                    </Grid>
                  </TotalBalanceBox>
                </Grid>

                <TransactionBox>
                  <TransactionText>
                    Transaction History
                  </TransactionText>
                </TransactionBox>

                <FilterGriid container spacing={1}>
                  <Grid item xs={9.2}>
                    <SearchField
                      variant="outlined"
                      type="number"
                      value={orderId}
                      name="search"
                      size="medium"
                      placeholder="Search..."
                      onChange={handleInputChange}
                      InputProps={{
                        autoComplete: 'off',
                        'data-testid': 'search',
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchImg alt="search icon" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <CustomDateRanges
                      xs={12}
                      styles={{ height: '2.125rem', width: '100%' }}
                      placeholderStyle={{ fontSize: '0.75rem', color: 'rgba(166, 179, 186, 1)', fontWeight: '500' }}
                      creditWallet
                      handleSubmit={handleSubmit}
                    />
                  </Grid>

                  <Grid item xs={0.8}>
                    <FilterButton onClick={handleFilterButtonClick}>
                      <FilterIcon style={{ fill: '#ffffff', fontSize: '1rem' }} />
                      Filter
                    </FilterButton>
                  </Grid>
                </FilterGriid>

                <OrderBox>
                  {loading ? <ProductsTableLoader /> : (
                    orderInvoices?.map(({
                      id, dueDate, total, status: orderStatus, order
                    }) => (
                      <ListBox container key={id}>
                        <Grid item xs={8}>
                          <Box style={{ display: 'flex' }}>
                            <DetailTypo>Order ID:</DetailTypo>
                            &nbsp; &nbsp;
                            <BoldDetailTypo>
                              #
                              {order?.id}
                            </BoldDetailTypo>
                            &nbsp; &nbsp;
                            <StatusBox status={getStatus(orderStatus)}>
                              {getStatus(orderStatus)}
                            </StatusBox>
                          </Box>

                          <Box style={{ display: 'flex' }}>
                            <DetailTypo>Due Date</DetailTypo>
                            &nbsp; &nbsp;
                            <BoldDetailTypo>
                              {moment(dueDate).format('ddd, Do MMM YYYY HH:mm')}
                            </BoldDetailTypo>
                          </Box>
                        </Grid>

                        <Grid item xs={4} style={{ alignItems: 'end', display: 'flex', flexDirection: 'column' }}>
                          <BoldDetailTypo>
                            {`₦ ${currencyFormatter(+total)}`}
                          </BoldDetailTypo>
                          <ViewDetailButton>View Details</ViewDetailButton>
                        </Grid>
                      </ListBox>
                    ))
                  )}
                </OrderBox>

                <FooterGrid item container className="joy-new-order-pagination">
                  <TablePagination
                    total={orderInvoicesTotalNumber}
                    pageCount={+pageCount}
                    setPageCount={setPageCount}
                    pageNumber={+pageNumber}
                    setPageNumber={setPageNumber}
                    hideRowsPerPage
                    displayShowResult
                  />
                </FooterGrid>
              </Grid>
            </OrderGridContainer>
          </Grid>
        </Grid>
      </MainContainer>

      <PaymentRepayDialog
        open={repayDialog}
        handleClose={handleRepayDialog}
      />

      <FilterPopper
        filterButtonEl={filterButtonEl}
        handleFilterButtonClick={handleFilterButtonClick}
        handleStatus={handleStatusFilter}
      />
    </MainContent>
  );
};

export default CreditWallet;
