import React from 'react';
import { useMediaQuery } from '@mui/material';
import { MobileSupportText, SupportBox, SupportText } from './customerSupport.styles';

export const CustomerSupport = () => {
  const isSmallScreen = useMediaQuery('(max-width: 991px)');

  return (
    <SupportBox>
      {isSmallScreen ? (
        <MobileSupportText>
          Our Customer Service Support Line:
          {' '}
          <u>+234 905 579 3553</u>
        </MobileSupportText>
      ) : (
        <SupportText>
          Need help shopping on our marketplace? Our Customer Service Support Line:
          {' '}
          <u>+234 905 579 3553</u>
        </SupportText>
      )}
    </SupportBox>
  );
};

export default CustomerSupport;
