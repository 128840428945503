import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ItemGrid, ItemContent, ItemTypo, ItemTyp, DetailContent,
  ColorGrid, ItemGridRight, ItemGridLeft, ItemPlaceTypo
} from './quoteReqReturnRow.styles';
import { JSONParse } from '../../../utils/json';

const QuoteReqReturnRow = ({ orderItem, index }) => {
  const navigate = useNavigate();

  const {
    id, paymentSummary, datePlaced: date, meta, metaStatus
  } = orderItem;

  const datePlaced = moment(date).format('ddd, Do MMM YYYY HH:mm');
  const { total } = JSONParse(paymentSummary);

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid name={name} style={{ color, backgroundColor: bkg }} item container className={`mpAdmin-uat-ordersPage-${name}`}>
      {name}
    </ColorGrid>
  );

  const safeParseJSON = (string, fallback = {}) => {
    try {
      const sanitizedString = string
        .replace(/'/g, '"')
        .replace(/None/g, 'null')
        .replace(/\bTrue\b/g, 'true')
        .replace(/\bFalse\b/g, 'false');
      return JSON.parse(sanitizedString);
    } catch (error) {
      console.error(`Error parsing JSON string: ${string}`, error);
      return fallback;
    }
  };

  const getStatus = () => {
    switch (metaStatus) {
      case 'ORDER_SENT':
        return getColorGrid('#E79800', '#FFFBC5', 'Pending');
      case 'ORDER_RECEIVED':
        return getColorGrid('#008F3D', '#FFFBC5', 'Confirmed');
      default:
        return getColorGrid('#E79800', '#FFFBC5', 'Pending');
    }
  };

  const parsedMeta = safeParseJSON(meta);
  const priceUpdated = parsedMeta?.price_updated || false;
  const amount = priceUpdated ? currencyFormatter(total) : getStatus();

  const handleViewDetails = () => {
    navigate(`/quote-details/${id}`);
  };

  return (
    <ItemGrid container className={`mpFe-uat-orders-cards-${index}`}>
      <ItemContent container item xs={12} md={12}>
        <ItemGridRight onClick={() => handleViewDetails(id)}>
          <DetailContent>
            <Grid container item gap={1} flexDirection="row">
              <ItemTypo>
                #
                {id}
              </ItemTypo>
            </Grid>
            <Grid container item gap={1} flexDirection="row">
              <ItemPlaceTypo>
                Requested on:
              </ItemPlaceTypo>
              <ItemTypo>
                {datePlaced}
              </ItemTypo>
            </Grid>
            <Grid container item gap={1} flexDirection="row">
              <ItemPlaceTypo>
                Total Price:
              </ItemPlaceTypo>
              <ItemTypo>
                {priceUpdated && '₦'}
                {amount}
              </ItemTypo>
            </Grid>
            <Hidden mdUp>
              <Grid container item gap={1} flexDirection="row">
                <ItemTyp>
                  {priceUpdated && '₦'}
                  {priceUpdated && amount}
                </ItemTyp>
                <button
                  type="button"
                  onClick={handleViewDetails}
                  onKeyPress={(e) => { if (e.key === 'Enter') handleViewDetails(); }}
                  style={{
                    background: 'none', border: 'none', padding: 0, cursor: 'pointer'
                  }}
                >
                  {getColorGrid('#235A91', '#E5EEF9', 'View Quote')}
                </button>
              </Grid>
            </Hidden>
          </DetailContent>
        </ItemGridRight>
        <ItemGridLeft>
          <DetailContent>
            <Hidden mdDown>
              <ItemTypo>
                {priceUpdated && '₦'}
                {priceUpdated && amount}
              </ItemTypo>
              <Grid container item gap={1} marginTop={2.5} flexDirection="row">
                <button
                  type="button"
                  onClick={handleViewDetails}
                  onKeyPress={(e) => { if (e.key === 'Enter') handleViewDetails(); }}
                  style={{
                    background: 'none', border: 'none', padding: 0, cursor: 'pointer'
                  }}
                >
                  {getColorGrid('#235A91', '#E5EEF9', 'View Quote')}
                </button>
              </Grid>
            </Hidden>
          </DetailContent>
        </ItemGridLeft>
      </ItemContent>
    </ItemGrid>
  );
};

QuoteReqReturnRow.propTypes = {
  orderItem: PropTypes.shape(Object),
  index: PropTypes.number.isRequired
};

QuoteReqReturnRow.defaultProps = {
  orderItem: {},
};

export default QuoteReqReturnRow;
