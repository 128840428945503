import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { Grid, Table } from '@mui/material';
import toast from 'react-hot-toast';
import {
  TableContainerWrapper, TableComponent, TableHeader, ExportText,
  MainTableHead, TableGrid, MainTableBody, PrimaryTitle, TitleGridContainer, TitleTextGridContainer, AddCategory, TextWrapper, FilterGrid, NumberText, ExportGrid,
} from './customerCategories.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import Filter from './filter';
import { useStateValue } from '../../../providers/stateProvider';
import { DivFullWidth } from '../indevidualCategory/categoryContainer.styles';
import { ExportIcon } from '../../../assets/svgs';
import CreateCategoryDialog from './createCategoryDialog/createCategoryDialog';
import TablePagination from '../../shared/tablePagination';
import { BUSINESS_CATEGORIES } from '../../../queries/categories';
import { EXPORT_MUTATION } from '../../../mutations/reports';
import { addHyphen } from '../../shared/methods';

const headers = [
  'Category ID', 'Category Name', 'Markup (%)', 'Credit Limit', 'Credit Days', 'Grace Days', 'No of Affiliate', 'Actions',
];

const startDate = moment().startOf('day').format('YYYY-MM-DD');
const endDate = moment().endOf('day').format('YYYY-MM-DD');
const initialState = {
  searchText: '',
  dateFilter: {
    dateFrom: startDate,
    dateTo: endDate
  }
};

const CustomerCategories = () => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [, dispatch] = Object.values(useStateValue());
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [createCategoryDialog, setCreateCategoryDialog] = useState(false);
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());
  const { searchText } = state;

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleDialog = () => {
    setCreateCategoryDialog(!createCategoryDialog);
  };
  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const handleClick = async () => {
    const randomNum = Math.floor(Math.random() * 10000);
    const NewfileName = `Customer Category ${randomNum}`;
    exportMutation({
      variables: {
        type: 'customer_category',
        name: NewfileName,
        status: ''
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} data-testid={`mp-uat-customerCategory-page-${addHyphen(header)}`}>{header}</TableHeader>
  ));

  const variables = {
    pageCount,
    pageNumber,
    search: searchValue
  };

  const {
    loading, error, data, refetch
  } = useQuery(BUSINESS_CATEGORIES, {
    fetchPolicy: 'no-cache',
    variables,
  });

  // if (error) return <div>{error.message}</div>;
  const {
    businessCategories = [], businessCategoriesTotalNumber = 0
  } = data || {};

  const rows = businessCategories;
  return (
    <>
      <TitleGridContainer container item xs={12} justifyContent="space-between">
        <TitleTextGridContainer item md={5}>
          <PrimaryTitle variant="h5">Customer Categories</PrimaryTitle>
        </TitleTextGridContainer>
        <Grid container md={7} spacing={2} justifyContent="flex-end">
          <AddCategory
            data-testid="export"
            item
            onClick={() => setCreateCategoryDialog(true)}
            className="mpAdmin-uat-customerCategory-add-category"
          >
            <TextWrapper>
              Add Category
            </TextWrapper>
          </AddCategory>
        </Grid>
      </TitleGridContainer>
      <FilterGrid container>
        <Grid xs={2}>
          <NumberText className="mpAdmin-uat-customerCategory-count">
            {businessCategoriesTotalNumber}
            {' '}
            Category(s)
          </NumberText>
        </Grid>
        <Grid xs={9}>
          <Filter
            state={state}
            handleSearch={handleSearch}
          />
        </Grid>
        <Grid item xs={1}>
          <ExportGrid style={{ cursor: 'pointer' }} onClick={handleClick} className="mpAdmin-uat-customerCategory-export">
            <ExportIcon />
            <ExportText>Export</ExportText>
          </ExportGrid>
        </Grid>
      </FilterGrid>
      <TableContainerWrapper elevation={0}>
        <TableGrid item container>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableComponent item container data-testid="mp-uat-customerCategory">
                <Table>
                  <MainTableHead item container>
                    {returnHeaders()}
                  </MainTableHead>
                  <MainTableBody item container>
                    {rows?.map((_row) => (
                      <ReturnRow
                        row={_row}
                        key={_row}
                        refetch={refetch}
                      />
                    ))}
                  </MainTableBody>
                </Table>
              </TableComponent>
            )}
        </TableGrid>
        <DivFullWidth>
          <TablePagination
            total={businessCategoriesTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </DivFullWidth>
      </TableContainerWrapper>
      <CreateCategoryDialog
        open={createCategoryDialog}
        close={handleDialog}
        refetch={refetch}
      />
    </>
  );
};

export default CustomerCategories;
