import {
  Typography, Checkbox, InputBase, FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormField = styled(FormControl)`
  margin-bottom: 20px;
  & .MuiFilledInput-root {
    background-color:  #fff;
    border-radius: 8px;
    padding: 5px;
    border: 1px solid #CAD1D7;
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  & .MuiSelect-filled.MuiSelect-filled {
    text-align: initial;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
  & .MuiFormLabel-root.Mui-disabled {
    color: #858383;
  }
  @media(max-width: 991px) {
    .MuiFilledInput-root {
      font-size: 2.4rem;
      border-radius: 10px;
      padding: 1rem;
  }
  & .MuiInputLabel-filled {
      font-size: 1.8rem;
    }
  & .MuiSelect-iconFilled {
    font-size: 3rem;
    }
  }
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CreditNameTypo = styled(Typography)`
  font-size: .7rem;
`;

export const CreditDaysInput = styled(InputBase)`
  font-size: .7rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;
