import React from 'react';
import { Grid, Paper } from '@mui/material';
import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';

export const StatisticPaper = styled(Paper)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
`;

const getGridStyles = ({ admin, diff, affiliate }) => {
  if (typeof diff !== 'undefined') {
    return css`
      padding: 5px 15px;
    `;
  }
  if (admin) {
    return css`
      padding: 20px 15px;
    `;
  }
  return css`
    ${affiliate ? 'border: 1px solid #E3E7EA; border-radius: 5px; justify-content: space-between; padding: 24px 15px;' : 'padding: 24px 15px;'}
    @media(max-width: 991px) {
      // padding: 5.5rem 4rem;
    }
  `;
};

export const StatisticWrapper = styled(({ ...otherProps }) => (
  <Grid {...otherProps} />
))`
  align-items: center;

  ${getGridStyles}
`;

export const StatisticGrid = styled(Grid)`
  flex-direction: column;
  margin-left: 30px;
`;

export const StatisticHeader = styled(Grid)`
  color: ${({ affiliate }) => (affiliate ? '#464F5B' : '#424242')};
  margin-bottom: 10px;
  font-weight: ${({ affiliate }) => (affiliate ? '400' : '600')};
  font-size: ${({ affiliate }) => (affiliate ? '24px' : '35px')};

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const StatisticContent = styled(Grid)`
  color: ${({ affiliate }) => (affiliate ? '#464F5B' : '#424242')};
  font-weight: ${({ affiliate }) => (affiliate ? '600' : '700')};
  font-size: ${({ affiliate }) => (affiliate ? '18px' : '3.6rem')};
  text-transform: capitalize;
  padding-top: 14px;

  @media(max-width: 991px) {
    font-size: 24px;
  }

  @media(min-width: 992px) {
    font-size: 20px;
    padding-top: 0px;
  }
`;

export const DiffGrid = styled(Grid)`
  color: ${({ diff }) => (diff < 0 ? '#FF4142' : '#3CC480')};
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const DiffImage = styled('img')`
  height: 5px;
  width: 5px;
  margin-right: 2px;

  @media(min-width: 992px) {
    height: 11px;
    width: 11px;
  }
`;

export const IconImage = styled('img')`
  height: 80px;

  @media(min-width: 992px) {
    height: 50px;
  }
`;
