import { useQuery } from '@apollo/client';
import {
  Box,
  Grid,
  Hidden,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import searchGif from '../../assets/images/marketOrder/search.gif';
import trackOrder from '../../assets/images/marketOrder/TrackOrderImg.png';
import { GET_SINGLE_ORDER } from '../../queries/orders';
import { parseJson } from '../affiliates/utils';
import MainContent from '../customComponents/mainContent';
import {
  BottomPaper,
  CTextField,
  ContentBox,
  EmptyBox,
  HeaderTypo,
  SearchGif,
  SubHeader,
  TopPaper, TrackGrid,
  TrackImg,
  TrackOrderButton, TrackOrderButtonText, TrackText,
  Typo, TrackOrderGridContainer, OrdersPrimaryTitle,
  ArrowBackText
} from './trackOrderContainer.styles';
import TrackOrderStepper from './trackOrderStepper';
import AffiliateMenu from '../navigation/affiliateNavigation/affiliateMenu';
import MobileFooter from '../orders/marketplaceOrder/mobileFooter/mobileFooter';

export default function TrackOrderContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state;

  const [textInput, setTextInput] = useState('');
  const [variable, setVariable] = useState(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [steps, setSteps] = useState([]);
  const [closeMenu, setCloseMenu] = useState(false);

  useEffect(() => {
    if (state) {
      setTextInput(state.orderId);
      setVariable(state.orderId);
    }
  }, []);

  const {
    loading, error, data
  } = useQuery(GET_SINGLE_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { id: variable }
  });
  let dataMeta;
  if (data?.singleOrder && !error) {
    const { meta } = data?.singleOrder ?? '';
    try {
      dataMeta = parseJson(meta.replace(/'/g, '"'));
    } catch (e) {
      return null;
    }
  }
  const { status, tracking_information } = dataMeta || {};
  const {
    date_placed, date_approved, date_sorted, date_ready_for_dispatch, date_dispatched,
    partially_fulfilled, date_completed
  } = tracking_information || {};

  const handleActive = () => {
    switch (status) {
      case 'ORDER_CLOSED':
        return setActiveStep(5);
      case 'ORDER_SENT':
        return setActiveStep(0);
      case 'ORDER_DISPATCHED':
        return setActiveStep(4);
      case 'ORDER_RECEIVED':
        return setActiveStep(1);
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return setActiveStep(3);
      case 'ORDER_SORTED':
        return setActiveStep(2);
      default:
        return setActiveStep(0);
    }
  };

  const handleTrackOrderButton = () => {
    setVariable(textInput);
  };

  const returnTextField = () => (
    <CTextField
      variant="outlined"
      placeholder="Enter Tracking Order"
      value={textInput}
      size="large"
      name="orderId"
      onChange={(event) => setTextInput(event.target.value)}
      type="number"
      fullWidth
      InputProps={{
        style: { fontSize: isSmall ? '1.5rem' : '.9rem', borderRadius: '.3rem', height: isSmall ? '60px' : '40px' }
      }}
      InputLabelProps={{
        style: { fontSize: isSmall ? '1.1rem' : '.8rem', color: '#606060' },
      }}
    />
  );

  const initialSteps = [
    {
      header: 'Order Placed',
      title: 'Your order has been placed! sit back and relax while we take care of the rest.',
      date: date_placed ? moment(date_placed).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Approved',
      title: 'Congratulations! Your order has been approved and is now on its way to been sorted.',
      date: date_approved ? moment(date_approved).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Sorted',
      title: 'Your package is sorted based on its destination and other factors such as weight, size, and delivery speed.',
      date: date_sorted ? moment(date_sorted).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Ready For Dispatch',
      title: 'Your package will be on its way soon, its ready for delivery.',
      date: date_ready_for_dispatch ? moment(date_ready_for_dispatch).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Completed',
      title: 'Hi, your order has been confirmed and completed. Thanks for your patronage!',
      date: date_completed ? moment(date_completed).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
  ];

  const getNextChar = (char) => String.fromCharCode(char.charCodeAt(0) + 1);
  const defaultStep = {
    header: 'Order Dispatched',
    title: 'Hi, your package has been delivered to the "recipient\'s address or a designated pickup location".',
    date: (partially_fulfilled === 'true' || partially_fulfilled === 'false') && moment(Object.values(date_dispatched)[0]).add(60, 'minutes').format('D MMMM YYYY hh:mm a'),
  };
  const handlePartialFulfilled = () => {
    if (partially_fulfilled === 'true') {
      let orderIndex = 4;
      let currentChar = 'A';
      for (let i = 0; i < Object.keys(date_dispatched).length; i++) {
        const orderDates = moment(Object.values(date_dispatched)[i]).add(60, 'minutes').format('D MMMM YYYY hh:mm a');
        const char = currentChar;
        const setValue = () => {
          const value = {
            header: `Order Batch (${char}) Dispatched`,
            title: 'Hi, your package has been delivered to the "recipient\'s address or a designated pickup location".',
            date: orderDates || ''
          };
          return value;
        };
        const nextChar = getNextChar(currentChar);
        currentChar = nextChar;
        initialSteps.splice(orderIndex, 0, setValue());
        orderIndex++;
      }
      if (status === 'ORDER_CLOSED') setActiveStep(Number(initialSteps.length) - 1);
      if (status === 'ORDER_DISPATCHED') setActiveStep(Number(initialSteps.length) - 2);
      return setSteps(initialSteps);
    }
    return initialSteps.splice(4, 0, defaultStep);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (data?.singleOrder && !error) {
      setSteps(initialSteps);
      handleActive(status);
      handlePartialFulfilled();
    }
  }, [loading, data]);

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  const handleTabChange = (val) => {
    if (val === 'topSellingProducts') {
      navigate('/new-order/All Categories/0/0/Top Selling Products');
    } else if (val === 'clearanceSale') {
      navigate('/new-order/All Categories/0/0/Clearance Sale');
    } else if (val === 'profile') {
      navigate('/account-info');
    } else {
      navigate('/new-order');
    }
  };

  return (
    <MainContent>
      <ContentBox item container xs>
        <Box style={{ display: 'flex', cursor: 'pointer', margin: '1rem 0' }} onClick={() => navigate('/new-order')}>
          <ArrowBackIcon sx={{ fill: '#235a91', fontSize: '15px', marginTop: '2px' }} />
          <ArrowBackText>Back to Home</ArrowBackText>
        </Box>
        <Grid container spacing={3}>
          <Hidden mdDown>
            <AffiliateMenu closeMenu={closeMenu} handleClose={handleCloseMenu} />
          </Hidden>

          <Grid item xs={12} md={closeMenu ? 10.8 : 9.3} lg={closeMenu ? 10.8 : 9.3}>
            <TrackOrderGridContainer container>
              <OrdersPrimaryTitle type="tertiaryPrimaryTitle">Track Orders </OrdersPrimaryTitle>
              <Grid style={{ borderBottom: '1px solid #E8E8E8', marginTop: '2rem', marginBottom: '2rem' }} />
              <TopPaper item container>
                <TrackGrid item lg={9} xs={12}>
                  <SubHeader>Enter your tracking ID below to start tracking your orders</SubHeader>
                  <Grid style={{
                    borderBottom: '1px solid #E8E8E8', marginTop: '1rem', marginBottom: '1.5rem', width: !isSmall && '91%'
                  }}
                  />
                  <Grid container spacing={1}>
                    <Grid item lg={10} xs={10}>
                      {returnTextField()}
                    </Grid>
                    <Grid item lg={2} xs={2}>
                      <TrackOrderButton onClick={handleTrackOrderButton}>
                        <TrackOrderButtonText>Track</TrackOrderButtonText>
                      </TrackOrderButton>
                    </Grid>
                  </Grid>
                </TrackGrid>
                <Hidden mdDown>
                  <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TrackImg src={trackOrder} alt="Track Order" />
                  </Grid>
                </Hidden>
              </TopPaper>

              <BottomPaper item container>
                {(!loading && !error) ? (
                  <Box width="100%">
                    <SubHeader style={{
                      fontWeight: isSmall && 700,
                    }}
                    >
                      {`Tracking Number: OG${variable}`}
                    </SubHeader>
                    <Grid style={{
                      borderBottom: '1px solid #E8E8E8', marginTop: '1rem', marginBottom: '1.5rem',
                    }}
                    />
                    <TrackOrderStepper
                      activeStep={activeStep}
                      steps={steps}
                    />
                  </Box>
                )
                  : (
                    <EmptyBox>
                      <SearchGif src={searchGif} alt="search" />
                      <Typo>Nothing to display yet!</Typo>
                      <TrackText>
                        Kindly enter your tracking ID to be able to track
                        <br />
                        your order
                      </TrackText>
                    </EmptyBox>
                  )}
              </BottomPaper>
            </TrackOrderGridContainer>
          </Grid>
        </Grid>

        <Hidden mdUp>
          <MobileFooter active="profile" handleTabChange={handleTabChange} />
        </Hidden>
      </ContentBox>
    </MainContent>
  );
}

TrackOrderContainer.propTypes = {
};

TrackOrderContainer.defaultProps = {
};
