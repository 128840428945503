import {
  TableRow, TableCell, Typography, Grid
} from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }

  &:nth-of-type(even) {
    background-color: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
 text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 26px;
  padding: 20px 10px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  font-weight: 400;

  @media(min-width: 992px) {
    font-size: 1rem;

     &:nth-child(1){
      min-width: 5%;
    }

    &:nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7), :nth-child(8) :nth-child(9) {
      min-width: 11.875%;
    }
  }
`;

export const Text = styled(Typography)`
  color: #235A91;
  font-weight: 600;
  cursor: pointer;
`;

export const ColorGrid = styled(Grid)`
  text-transform: capitalize;
  color: #606060;
  font-size: 1rem;
  font-weight: 400;
  color: #606060;
  justify-content: flex-start;
  padding: 12px 0;
  align-items: center;
`;

export const Dot = styled(Grid)`
  background: ${({ background }) => (background)};
  border-radius: 50%;
  text-transform: capitalize;
  width: 9px;
  height: 9px;
  margin-right: 6px;
`;
