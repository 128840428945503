import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const sm = '960px';

export const ProductBox = styled(Box)`
  @media (min-width: 992px) {
     margin-left: ${(props) => (props.categoryPage ? '20px' : '')};
     margin-top: ${(props) => (props.categoryPage ? '2.5rem' : '')};

 }
`;

export const ProductCardsContainer = styled(Grid)`
  // @media (min-width: 992px) {
  //   margin: auto;
  // }
`;

export const ProductCardGrid = styled(Grid)`
  width: ${(props) => (props.categoryPage ? '25%' : '20%')};
  @media (max-width: 991px) {
    margin: auto;
    width: ${(props) => (props.list ? '100%' : '50%')}

  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: center;
  margin: 15px 0px;

  @media(min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const NoResultsText = styled(Typography)`
  font-size: 0.875rem;
  color: #5f6f7b;
  padding: 10px 15px;
  font-weight: 400;

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const NoResultsContainer = styled(Grid)`
  align-items: center;
  margin: 5% auto;
  width: 100%;
  text-align: center;

  @media (max-width: 991px) {
    width: 80%;
  }

`;

export const NoResultsHeader = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #464f5b;
  margin-top: .5rem;

  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const MiddleImage = styled('img')`
  width: 100%;
  max-height: 16.125rem;
  cursor: pointer;
`;

export const SectionHeader = styled('div')`
  background: #F3F7FC;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 20px  25px;
  justify-content: space-between;
  margin: ${(props) => (props.categoryPage ? '2rem 0' : '2rem 0')};
  @media(max-width: 991px) {
    padding: 2rem 30px;
    border-radius: 10px;


}

`;

export const Title = styled(Typography)`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  color: #22262A;

  @media(max-width: 991px) {
  font-size: 2.5rem;
}
`;

export const SeeAll = styled(Typography)`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #235A91;
  cursor: pointer;

   @media(max-width: 991px) {
  font-size: 1.8rem;
}
`;

export const SearchResultHeader = styled(Typography)`
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
  color: #22262A;
  margin: 2rem 0;

`;
