import {
  Typography, Button, Dialog, DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

export const OverdueDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 8px;
    padding: .5rem 1.875rem 1.875rem 1.875rem;
    max-width: 28.125rem;
    min-height: 26.25rem;
  }
`;

export const DialogTitleContainer = styled(DialogTitle)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
`;

export const TextBox = styled(Typography)`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 0;
  fill: #235a91;
  cursor: pointer;
  font-size: 1.3rem;
`;

export const LoadingGif = styled('img')`
  height: 7.25rem;
  width: 7.25rem;
`;

export const TitleText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(48, 48, 48, 1);
  text-align: center;
  margin-top: .7rem;
  margin-bottom: .5rem;
  letter-spacing: 0;
`;

export const SubTitleText = styled(Typography)`
  font-size: 0.875rem;
  color: rgba(96, 96, 96, 1);
  text-align: center;
  line-height: 1.7;
  letter-spacing: 0;
`;

export const PayNowButton = styled(Button)`
  width: 100%;
  height: 2.875rem;
  background-color: rgba(35, 90, 145, 1);
  color: rgba(255, 255, 255, 1);
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 4px;

  &:hover {
    background-color: rgba(35, 90, 145, 1);
    color: rgba(255, 255, 255, 1);
  }
`;
