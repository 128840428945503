import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Grid, useMediaQuery, Box, Hidden
} from '@mui/material';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  CardPaper, ProductImg, ImgBox, DetailsBox, HeaderContainer,
  DetailsContainer, ActionsContainer, PromoPriceText,
  HeaderText, TypoContainer, PackSizeTypo,
  PricingAndSaving, PricingWrapper,
  Pricing, ActionButton, AddButton,
  CircularProgressLoader, ActionTextField, InputButtonGroup,
  AddCartGrid, PromoContainer, PromoText,
  AddCartTypo, ProductVariantText, ProductVContainer, ChangeWrapper, MinusIcon, PlusIcon,
  QuoteImg, CardWrapper, SubscribeButton, ConfirmationButton,
  NotificationTypo, MarkIconImg, Divider, PackSizeTypoText,
  ManufacTypo, PromoItem, MobilePromoContainer, MobilePromoText, ListHeaderText,
  BottonGrid, OldPriceText,
  ImgWrapper
} from './productCard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { UPDATE_ORDER_MUTATION } from '../../../mutations/orders';
import { useStateValue } from '../../../providers/stateProvider';
import { showNotification } from '../../shared/customToast/customToast';
import { parseJson } from '../../affiliates/utils';

const ProductCard = ({
  product, openAlert, cart: cartProduct, similarAction, index, refetchCart, active, displayType
}) => {
  const [{
    cart: { isManualOrder, productCartItems },
    affiliate: { pricePolicyFactor = 1 }
  }, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();
  const productToUse = active === 'clearanceSale' ? product?.product : product;
  const {
    id, promoCondition, marketplaceVersion: {
      brandName, quantityInStock: productQTYINSTock, expiryDate,
      resolvedPriceInUseValue: actualPrice, packSize, image, manufacturer,
      mktPrice, promoValue
    }
  } = productToUse || {};

  const [counter, setCounter] = useState(0);
  const [counterItem, setCounterItem] = useState(false);
  const quantityInStock = product?.quantityInStock || productQTYINSTock;
  const batchExpire = moment(product?.expiryDate || expiryDate).format('MM/YYYY');
  const expiredDate = batchExpire === 'Invalid date' ? 'N/A' : batchExpire;
  const [openDetails, setOpenDetails] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);

  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER_MUTATION);

  const handleActionButtons = (value) => {
    const sum = counter + value;
    if (sum > quantityInStock && quantityInStock !== 0) return toast.error('Requested quantity not available');
    setCounter(sum);
    setCounterItem(true);
  };
  const handleChange = (value) => {
    if (value > quantityInStock && quantityInStock !== 0) return toast.error('Requested quantity not available');
    setCounter(value);
    setCounterItem(true);
  };

  const itemPrice = (product?.price || actualPrice) * pricePolicyFactor;

  const isSmall = useMediaQuery('(max-width: 991px)');
  const qtyCount = !quantityInStock;
  const promoStatus = promoCondition === 'ONGOING' && true;
  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;

  useEffect(() => {
    if (productCartItems) {
      const filteredProducts = productCartItems.find((item) => Number(item?.id) === Number(id));
      setCartItemCount(filteredProducts?.qty);
    }
  }, [id, productCartItems]);

  const handleToastView = () => navigate('/cart');

  const triggerNotification = (pos) => {
    showNotification(
      {
        msg: '1 Quote Requested',
      },
      handleToastView,
      pos
    );
  };

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        productId: +id,
        quantity: counter,
        isManualOrder,
        affiliateId: _selectedAffiliate?.id,
        overwriteQuantity: true,
        ...(active === 'clearanceSale' && { batchId: product?.id }),
        ...(quantityInStock <= 0 && { rfq: true }),
      }
    })
      .then(({ data }) => {
        const { message, totalProductCount, order } = data?.updateOrderProduct || {};
        const { paymentSummary } = order || {};
        const totalValue = parseJson(paymentSummary);
        const totalPaymentSummary = totalValue?.main_total;
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: totalProductCount
        });
        dispatch({
          type: CartActionTypes.UPDATE_CART_ITEMS,
          payload: { id: +id, type: 'add', qty: counter }
        });
        dispatch({
          type: CartActionTypes.CART_VALUE,
          payload: totalPaymentSummary
        });
        if (cartProduct) {
          refetchCart();
        }
        setCounterItem(false);
        setCartItemCount(counter);
        if (quantityInStock <= 0) {
          const position = isSmall ? 'bottom-center' : 'top-center';
          triggerNotification(position);
        } else {
          openAlert(message);
        }
      });
  };

  const handleClick = () => {
    if (active === 'clearanceSale') {
      navigate(`/new-order/${product?.id}/clearance-sales-details`);
    } else {
      navigate(`/new-order/${id}/details`);
    }
  };

  const handleMobileViewDetails = () => {
    if (displayType === 'list') {
      handleClick();
    } else {
      setOpenDetails(!openDetails);
    }
  };

  const handleEdit = () => {
    setCartItemCount(0);
  };


  return (
    <CardPaper
      similarAction={similarAction}
      elevation={2}
      stock={qtyCount}
      spacing={3}
      cart={cartProduct}
      className={`mpFe-uat-new-order-card-${index}`}
      openDetails={openDetails}
      list={displayType === 'list' && isSmall}
    >
      <Grid container>
        <CardWrapper list={displayType === 'list' && isSmall} xs={(displayType === 'list' && isSmall) && 7} lg={12}>
          <ImgBox list={displayType === 'list' && isSmall} onClick={handleClick}>
            <Grid container xs={12}>
              <Grid container item xs={7} md={12}>
                {quantityInStock > 0
                  ? (
                    <ProductVContainer cart={cartProduct} status="available">
                      <ProductVariantText status="available">Available</ProductVariantText>
                    </ProductVContainer>
                  )
                  : (
                    <ProductVContainer cart={cartProduct} status="stockOut">
                      <ProductVariantText status="stockOut">Out of Stock</ProductVariantText>
                    </ProductVContainer>
                  )}
                {((product?.discount || promoStatus) && quantityInStock > 0) && (
                  <Grid item>
                    <PromoContainer>
                      <PromoText>
                        {`${product?.discount || promoValue}% off`}
                      </PromoText>
                    </PromoContainer>
                  </Grid>
                )}
                {quantityInStock <= 0 && (
                  <Grid item>
                    <ProductVContainer cart={cartProduct} status="mutable" style={{ marginLeft: 'auto' }}>
                      <ProductVariantText status="mutable">Mutable Price</ProductVariantText>
                    </ProductVContainer>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {displayType !== 'list' && (
              <ImgWrapper>
                <ProductImg component="img" cart={cartProduct} src={image} alt={brandName} loading="lazy" />
              </ImgWrapper>
            )}
          </ImgBox>
          <DetailsBox {...(isSmall && { onClick: () => handleMobileViewDetails() })}>
            <DetailsContainer list={displayType === 'list' && isSmall}>
              <Box>
                <HeaderContainer list={displayType === 'list' && isSmall}>
                  {displayType === 'list' ? (
                    <ListHeaderText variant="subtitle2">
                      {brandName}
                    </ListHeaderText>
                  ) : (
                    <HeaderText variant="subtitle2">
                      {brandName}
                    </HeaderText>
                  )}
                </HeaderContainer>
                <TypoContainer item container xs={12}>
                  {displayType !== 'list' && (
                    <ChangeWrapper>
                      <ManufacTypo>
                        {manufacturer}
                      </ManufacTypo>
                    </ChangeWrapper>
                  )}
                  <ChangeWrapper list={displayType === 'list' && isSmall} container item xs={12}>
                    <PackSizeTypoText
                      item
                      xs={12}
                      variant="body1"
                      similarAction={similarAction}
                      list={displayType === 'list' && isSmall}
                    >
                      {packSize}
                      &nbsp;
                      <Divider>|</Divider>
                      &nbsp;
                      {Math.round(quantityInStock)}
                      {' '}
                      in Stock
                    </PackSizeTypoText>
                  </ChangeWrapper>
                  {quantityInStock > 0 ? (
                    <ChangeWrapper list={displayType === 'list' && isSmall}>
                      <PackSizeTypo>
                        Exp. Date :
                        &nbsp;
                        {expiredDate}
                      </PackSizeTypo>
                    </ChangeWrapper>
                  ) : ' '}

                  <PricingAndSaving item container justify-content="space-between">
                    <PricingWrapper item xs={displayType === 'list' ? 5 : 6} md={6}>
                      <Pricing list={displayType === 'list'}>
                        {currencyFormatter(Math.round(product?.discountedPrice) || mktPrice)}
                      </Pricing>
                    </PricingWrapper>
                    {displayType === 'list' && isSmall && (
                      <Hidden mdUp>
                        {((product?.discount || promoStatus) && quantityInStock > 0) && (
                          <PromoItem xs={3} list={displayType === 'list'}>
                            <MobilePromoContainer>
                              <MobilePromoText>
                                {`${product?.discount || promoValue}% off`}
                              </MobilePromoText>
                            </MobilePromoContainer>
                          </PromoItem>
                        )}

                      </Hidden>
                    )}
                    {((product?.discount || promoStatus) && quantityInStock > 0) && (
                      <Grid item container xs={4} md={3}>
                        <PromoPriceText>
                          {currencyFormatter(itemPrice)}
                        </PromoPriceText>
                      </Grid>
                    )}

                    {quantityInStock <= 0 && (
                      <Grid item container xs={6}>
                        <OldPriceText
                          item
                          similarAction={similarAction}
                          list={displayType === 'list' && isSmall}
                        >
                          (Old Price)
                        </OldPriceText>
                      </Grid>
                    )}
                  </PricingAndSaving>
                </TypoContainer>
              </Box>
            </DetailsContainer>

          </DetailsBox>
        </CardWrapper>
        <ActionsContainer list={displayType === 'list' && isSmall} container xs={(displayType === 'list' && isSmall) && 5} lg={12}>
          {cartItemCount ? (
            <ConfirmationButton
              startIcon={<MarkIconImg />}
              variant="outlined"
              disableElevation
              counter={counter}
              onClick={handleEdit}
              className={`mpFe-uat-new-order-subscription-${index}`}
            >
              <NotificationTypo>
                { cartItemCount }
                &nbsp;
                Qty Added to Cart
              </NotificationTypo>
            </ConfirmationButton>
          ) : (
            <BottonGrid list={displayType === 'list' && isSmall} item xs={12} lg={12}>
              <InputButtonGroup size="small" aria-label="small outlined button group">
                <ActionButton
                  onClick={() => handleActionButtons(-1)}
                  disabled={counter === 0}
                  className={`mpFe-uat-new-order-minus-${index}`}
                >
                  <MinusIcon />
                </ActionButton>
                <ActionTextField
                  InputProps={{ disableUnderline: true, }}
                  variant="standard"
                  placeholder={0}
                  value={counter || ''}
                  onChange={(e) => handleChange(Number(e.target.value))}
                />
                <ActionButton
                  onClick={() => handleActionButtons(1)}
                  className={`mpFe-uat-new-order-add-${index}`}
                >
                  <PlusIcon />
                </ActionButton>
              </InputButtonGroup>
            </BottonGrid>
          )}

          {counterItem && !cartItemCount ? (
            <Grid container item xs={12} lg={12}>
              {quantityInStock > 0 ? (
                <AddCartGrid list={displayType === 'list' && isSmall} item xs={12}>
                  <AddButton
                    variant="outlined"
                    disableElevation
                    onClick={handleAddToCart}
                    disabled={counter === 0}
                    counter={counter}
                    className={`mpFe-uat-new-order-plus-${index}`}
                  >
                    {loading ? (
                      <CircularProgressLoader
                        disableShrink
                        size={24}
                        thickness={4}
                      />
                    )
                      : (
                        <AddCartTypo type={similarAction && 'similarAction'}>
                          &nbsp;
                          Add To Cart
                        </AddCartTypo>
                      )}
                  </AddButton>
                </AddCartGrid>
              ) : (
                <AddCartGrid list={displayType === 'list' && isSmall} item xs={12}>
                  {cartItemCount ? (
                    <ConfirmationButton
                      startIcon={<MarkIconImg />}
                      variant="outlined"
                      disableElevation
                      counter={counter}
                      onClick={handleEdit}
                      className={`mpFe-uat-new-order-subscription-${index}`}
                    >
                      <NotificationTypo>
                        { cartItemCount }
                        &nbsp;
                        Qty Requested
                      </NotificationTypo>
                    </ConfirmationButton>
                  ) : (
                    <SubscribeButton
                      startIcon={<QuoteImg />}
                      variant="outlined"
                      disableElevation
                      onClick={handleAddToCart}
                      disabled={counter === 0}
                      counter={counter}
                      className={`mpFe-uat-new-order-subscription-${index}`}
                    >
                      {loading ? (
                        <CircularProgressLoader
                          disableShrink
                          size={24}
                          thickness={4}
                        />
                      )
                        : (
                          <AddCartTypo type="notify"> Request a Quote </AddCartTypo>
                        )}
                    </SubscribeButton>
                  )}
                </AddCartGrid>
              )}
            </Grid>
          ) : ''}

        </ActionsContainer>

      </Grid>
    </CardPaper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  openAlert: PropTypes.func.isRequired,
  cart: PropTypes.bool,
  similarAction: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  refetchCart: PropTypes.func,
  active: PropTypes.string,
  displayType: PropTypes.string
};

ProductCard.defaultProps = {
  product: {},
  cart: false,
  refetchCart: () => {},
  active: '',
  displayType: ''
};

export default ProductCard;
