import {
  Dialog, DialogContent, Button, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: .5rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: ${({ therapeuticType }) => (therapeuticType ? '470px' : '450px')};
  min-height: 480px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  padding-top: 2rem !important;
  padding-bottom: 1rem !important;

  @media(max-width: 991px) {
    width: 100%;
  }
`;

export const TitleStyled = styled('span')`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #303030;

  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const DescStyled = styled('p')`
  font-weight: 400;
  font-size: 14px;
  line-height: 164.2%;
  color: #606060;
  padding: .3rem 0 .8rem 0;

  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const ButtonDarkStyled = styled(Button)`
  width: 178px;
  height: 48px;
  border-radius: 8px;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 16px;
  background: #235A91 !important;
  @media(max-width: 991px) {
    width: 48%;
    height: 80px;
    font-size: 27px;
  }
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 178px;
  height: 48px;
  border-radius: 8px;
  color: #235A91 !important;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #235A91;
  @media(max-width: 991px) {
    width: 48%;
    height: 80px;
    font-size: 27px;
  }

`;

export const FooterStyled = styled('div')`
  display: flex;
  justify-content: space-around;
  gap: 2;
`;

export const InfoText = styled(Typography)`
  color: #787885;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.216px;
  padding-top: 10px;
`;
