import { Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentContainer = styled(Typography)`
    padding: 2rem 5rem 4rem 5rem;
    background: #fff;
    @media (max-width: 991px) {
      padding: 6rem 3rem 20rem 3rem;
    }
`;

export const ProductTypo = styled(Typography)`
    font-size: 1.5rem;
    font-weight: 600;
    color: #22262a;
    
    @media (min-width: 991px) {
      margin-top: .5rem;
    }

    @media (max-width: 991px) {
        font-size: 2.5rem;
        font-weight: 650;
        padding-top: 10px;
    }
`;

export const ProductNameTypo = styled(Typography)`
    font-size: 1rem;
    font-weight: 500;
    margin: .5rem 0 1rem 0;
    color: #7a8b96;

    @media (max-width: 991px) {
        font-size: 2.2rem;
        font-weight: 400;
        margin: 1.5rem 0;
    }
`;

export const MenuButton = styled(Button)`
    border: none;
    &.MuiButtonBase-root {
      padding: 0px;
    }
    @media (max-width: 991px) {
      padding-bottom: 20px;
      .MuiButton-iconSizeMedium > *:first-child {
        font-size: 40px;
      }
  }
`;

export const BackTypo = styled(Typography)`
    font-weight: 400;
    color: #000000;
    font-size: .75rem;

    @media (max-width: 991px) {
      font-size: 1.75rem;
      font-weight: 500;
    }
`;
