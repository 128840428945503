import {
  Typography, Box, Button, Dialog, DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

export const RepayDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 8px;
    padding: 1rem 1.875rem 1.875rem;
    max-width: 43.5625rem;
    min-height: 21.9706rem;
  }
`;

export const DialogTitleContainer = styled(DialogTitle)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(227, 231, 234, 1);
  padding: 0 0 1rem;
  position: relative;
`;

export const TextBox = styled(Typography)`
  border: 0.5px solid rgba(227, 231, 234, 1);
  min-height: 14.2206rem;
  width: 100%;
  padding: 1.25rem;
  margin-top: 1.5rem;
  border-radius: 4px;
`;

export const RepayTypo = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
  color: rgba(48, 48, 48, 1);
  margin-top: .5rem;
`;

export const RepayTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: rgba(70, 79, 91, 1);
`;

export const RepaySubTitle = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(96, 96, 96, 1);
  margin-top: .2rem;
  border-bottom: 0.5px solid rgba(227, 231, 234, 1);
  padding-bottom: .7rem;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 0;
  fill: #235a91;
  cursor: pointer;
  font-size: 1.3rem;
`;

export const AccountBox = styled(Box)`
  width: 100%;
  height: 2.9225rem;
  background: rgba(242, 248, 253, 1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const CopyButton = styled(Button)`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 1.6725rem;
  font-weight: 600;
  font-size: 0.6275rem;
  color: rgba(35, 90, 145, 1);
  background: rgba(229, 238, 249, 1);
  width: 4.6481rem;
`;

export const AccountTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: rgba(70, 79, 91, 1);
`;
