import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Hidden from '@mui/material/Hidden';
import {
  Grid, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Slide, TableBody, Box
} from '@mui/material';
import toast from 'react-hot-toast';
import {
  Clear as ClearIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import moment from 'moment';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  CartGridContainer, DialogActionButtonText, BackArrowIconContainer,
  PageTitleContainer, CartGridContainerHeader,
  DialogCancelButton, DialogOkButton, DeliveryAddressCardHeaderTitle,
  ChangeLocationButton, ChangeLocationActions, LocationTextField, ChangeLocationContainer, KeyboardArrowLeft, TableHeader, MainTableRow,
} from '../../../cart/cart.styles';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, SupplierDetailCardHeader, OrderDeliveryNotesText,
  PageTitleText, PageTitleSubText, DeliveryAddressCardHeaderSubTitle,
  SubTotalText, SubTotalPrice, SubTotalBox, TableWrapper, TableComponent, Header, DetailPaper,
  CreditText, TitleDate, SubTitleDate, ArrowBackText
} from './styles';
import { GET_SINGLE_ORDER, ORDER_INVOICE_TO_ERP } from '../../../../queries/orders';
import { UPDATE_ORDER_META_MUTATION } from '../../../../mutations/cart';
import { CANCEL_SUPPLIER_ORDERS, LINK_ORDER_TO_ERP } from '../../../../mutations/orders';
import MainContent from '../../../customComponents/mainContent';
import SupplierOrderDetailProductItem from './product-item';
import { JSONParse } from '../../../../utils/json';
import PaymentSummary from './paymentSummaryCard/PaymentSummary';
import DeliveryInfoCard from './deliveryInfoCard/deliveryInfoCard';

import { Product } from '../../../../providers/reducers/product/classes/Product';
import { parseJson } from '../../../affiliates/utils';
import AffiliateMenu from '../../../navigation/affiliateNavigation/affiliateMenu';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialState = {
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  totalPromo: 0,
  showCancelOrderDialog: false,
  editNote: false,
  note: '',
  orderId: null,
  supplierPaymentTerm: null,
  orderMeta: null,
  showPaymentDetails: false,
  orderStatus: null,
  deliveryId: null,
  linkOrderInvoicesToErp: [],
  business: {}
};
const SupplierOrderDetailIndex = () => {
  const [state, setState] = useState(initialState);

  const params = useParams();
  const navigate = useNavigate();

  const [cancelSupplierOrders] = useMutation(CANCEL_SUPPLIER_ORDERS);
  const [updateOrderMeta] = useMutation(UPDATE_ORDER_META_MUTATION);
  const [linkOrderToErp] = useMutation(LINK_ORDER_TO_ERP);
  const [closeMenu, setCloseMenu] = useState(false);

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  const retrieveProducts = (orderSet) => {
    let products = [];
    let totalPromo = 0;

    products = orderSet?.map((p) => {
      const {
        id,
        name,
        quantity,
        quantityRecieved,
        meta: orderProductMeta,
        product: { meta }
      } = p;

      const {
        supposed_amount: supposedAmount,
        amount_paid: amountPaid,
        supposed_unit_cost: supposedUnitCost,
        unit_cost_paid: unitCostPaid,
        market_rpp: marketRpp,
      } = JSONParse(orderProductMeta?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
      totalPromo += (supposedUnitCost - unitCostPaid) * quantity;

      return {
        id,
        name,
        quantity,
        ...new Product(p.product),
        meta: JSON.parse(meta),
        supposedAmount,
        amountPaid,
        supposedUnitCost,
        unitCostPaid,
        marketRpp,
        quantityRecieved,
      };
    });
    setState((s) => ({
      ...s, cart: products, totalPromo
    }));
  };

  const [orderInvoiceToErp, { data: linkData }] = useLazyQuery(ORDER_INVOICE_TO_ERP);
  useEffect(() => {
    if (linkData && linkData.linkOrderInvoicesToErp) {
      const { linkOrderInvoicesToErp } = linkData;
      setState((s) => ({
        ...s, linkOrderInvoicesToErp: linkOrderInvoicesToErp?.linkOrderInvoicesToErp
      }));
    }
  }, [linkData]);

  const checkLinkOrderToErp = () => {
    orderInvoiceToErp({
      variables: { orderId: params.id },
      fetchPolicy: 'no-cache'
    });
  };

  const { data } = useQuery(GET_SINGLE_ORDER, {
    variables: { id: params.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data && data?.singleOrder) {
      const {
        singleOrder: {
          id: orderId,
          dateCreated,
          status,
          business,
          metaStatus: orderStatus,
          meta,
          supplierorderSet,
          supplier,
          paymentSummary,
          deliveryOutlet
        }
      } = data;
      const orderMeta = JSONParse(meta.replace(/'/g, '"'));
      const dateFormat = 'l h:mm';
      const datePlaced = moment(dateCreated).format(dateFormat);
      const deliveryDate = moment(datePlaced).add(2, 'days').format('DD/MM/YY');
      const prods = [];
      supplierorderSet?.forEach((orderSet) => prods.push(...orderSet.orderproductSet));
      const businessContactMeta = JSONParse(deliveryOutlet?.contacts?.replace(/'/g, '"').replace('None', '"Lagos"'));
      const parseSummary = parseJson(paymentSummary);
      const serviceCharge = parseSummary?.service_charge;
      const {
        note
      } = orderMeta;

      setState((s) => ({
        ...s,
        orderId,
        note,
        businessName: business.name,
        businessContact: businessContactMeta,
        supplier,
        deliveryDate,
        dateCreated: moment(dateCreated).format('DD/MM/YYYY'),
        orderMeta,
        supplierOrderStatus: status,
        orderStatus,
        business,
        serviceCharge
      }));
      retrieveProducts(prods);
    }
    checkLinkOrderToErp();
  }, [data]);

  const openDetailsPage = () => {
    setState((s) => ({ ...s, showPaymentDetails: true }));
  };

  const handleCancelOrder = () => {
    setState((s) => ({ ...s, loading: true, showCancelOrderDialog: false }));

    cancelSupplierOrders({
      variables: { supplierOrderIds: [params.id] }
    }).then((results) => {
      const {
        data: { cancelSupplierOrders: { message } }
      } = results;

      toast.success(message);
      setTimeout(() => navigate('/orders'), 1500);
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, loading: false }));
    });
  };

  const handleEditNoteClicked = () => {
    setState((s) => ({ ...s, editNote: true }));
  };

  const handleEditNote = () => {
    const { note, orderId } = state;
    if (note) {
      const keyValues = [{ key: 'note', value: note }];
      const mutationVariables = {
        keyValues,
        orderId,
        isManualOrder: false
      };

      updateOrderMeta({
        variables: mutationVariables
      }).then((results) => {
        const {
          data: {
            updateOrderMeta: { order: { meta } }
          }
        } = results;

        const orderMeta = JSONParse(meta.replace(/'/g, '"'));
        const { note: orderNote } = orderMeta;

        toast.success('Note updated.');
        setState((s) => ({ ...s, editNote: false, note: orderNote }));
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      toast.error('Note cannot be empty.');
    }
  };

  const sendSupplierOrderToErp = () => {
    linkOrderToErp({
      variables: { orderId: params.id }
    }).then(() => {
      toast.success('Order link to ERP successfully.');
      checkLinkOrderToErp();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const {
    businessContact, deliveryDate, dateCreated, cart, totalPromo, serviceCharge,
    showCancelOrderDialog, loading, editNote, note, supplierPaymentTerm, linkOrderInvoicesToErp, business
  } = state;

  const { creditWallet } = business;
  const parsedWallet = JSONParse(creditWallet);
  const { credit_days: creditDays } = parsedWallet;

  const orderTotal = cart.filter((product) => product.quantityRecieved)
    .reduce((sum, obj) => sum + (Number(obj.quantityRecieved) * Number(obj.supposedUnitCost)), 0);

  const grandTotal = orderTotal + serviceCharge;
  const groupOrderDiscount = cart.reduce((sum, obj) => sum
        + (`${obj.productBusinessId}` === '1' ? (Number(obj.quantityRecieved) * ((Number(obj.marketRrp) || Number(obj.supposedUnitCost)) - Number(obj.supposedUnitCost))) : 0), 0);
  const handleSubtotal = () => {
    let total = 0;
    cart.map((product) => {
      const { supposedUnitCost, quantityRecieved } = product;
      const price = +supposedUnitCost * quantityRecieved || 0;
      total += price;
    });
    return total;
  };

  return (
    <MainContent>
      <CartGridContainer>
        <Box style={{ display: 'flex', cursor: 'pointer', marginBottom: '2rem' }} onClick={() => navigate('/new-order')}>
          <ArrowBackIcon sx={{ fill: '#235a91', fontSize: '15px', marginTop: '2px' }} />
          <ArrowBackText>Back to Home</ArrowBackText>
        </Box>
        <Grid container spacing={3}>
          <Hidden mdDown>
            <AffiliateMenu closeMenu={closeMenu} handleClose={handleCloseMenu} />
          </Hidden>

          <Grid item xs={12} md={closeMenu ? 10.8 : 9.3} lg={closeMenu ? 10.8 : 9.3}>
            <DetailPaper>
              <CartGridContainerHeader container>
                <Grid xs={12}>
                  <BackArrowIconContainer onClick={() => navigate('/orders')}>
                    <KeyboardArrowLeft />
                    <PageTitleSubText>Back</PageTitleSubText>
                  </BackArrowIconContainer>
                </Grid>
                <Grid item container xs={12} md={6} alignItems="center">
                  <PageTitleContainer>
                    <PageTitleText>Order Detail</PageTitleText>
                  </PageTitleContainer>
                </Grid>
              </CartGridContainerHeader>

              <Grid
                container
                justifyContent="space-between"
                spacing={3}
                style={{ marginTop: '0' }}
              >
                <Grid item container lg={4}>
                  <SupplierDetailCardWrapper elevation={0}>
                    <SupplierDetailCard elevation={0}>
                      <CreditText>
                        {creditDays || 0}
                        {' '}
                        Credit Days
                      </CreditText>

                      <Box style={{ display: 'flex', margin: '20px 0 10px 0' }}>
                        <TitleDate>Placed on: &nbsp; &nbsp; </TitleDate>
                        <SubTitleDate>{dateCreated}</SubTitleDate>
                      </Box>
                      <Box style={{ display: 'flex' }}>
                        <TitleDate>Estimated Delivery: &nbsp; &nbsp;</TitleDate>
                        <SubTitleDate>{deliveryDate}</SubTitleDate>
                      </Box>
                      <DeliveryInfoCard
                        businessContact={businessContact}
                        openDetailsPage={openDetailsPage}
                      />
                    </SupplierDetailCard>
                  </SupplierDetailCardWrapper>
                </Grid>

                <Grid item container lg={4}>
                  <SupplierDetailCardWrapper elevation={0}>
                    <SupplierDetailCard elevation={0}>
                      <PaymentSummary
                        cart={cart}
                        orderTotal={orderTotal}
                        groupOrderDiscount={groupOrderDiscount}
                        grandTotal={grandTotal}
                        promo={totalPromo}
                        serviceFee={serviceCharge}
                      />
                    </SupplierDetailCard>
                  </SupplierDetailCardWrapper>
                </Grid>

                <Grid item container lg={4}>
                  <SupplierDetailCardWrapper elevation={0}>
                    <SupplierDetailCard elevation={0}>
                      <SupplierDetailCardHeader>
                        <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>
                        {editNote ? (
                          <ChangeLocationActions>
                            <ChangeLocationButton
                              role="button"
                              onClick={() => setState((s) => ({ ...s, editNote: false }))}
                            >
                              <ClearIcon sx={{ fontSize: '1rem', fill: '#235a91' }} />
                            </ChangeLocationButton>

                            <ChangeLocationButton
                              role="button"
                              onClick={() => handleEditNote()}
                            >
                              <CheckIcon sx={{ fontSize: '1rem', fill: '#235a91' }} />
                            </ChangeLocationButton>
                          </ChangeLocationActions>
                        ) : (
                          <DeliveryAddressCardHeaderSubTitle
                            onClick={() => handleEditNoteClicked()}
                            variant="outlined"
                          >
                            Edit
                          </DeliveryAddressCardHeaderSubTitle>
                        )}
                      </SupplierDetailCardHeader>
                      {editNote ? (
                        <ChangeLocationContainer>
                          <LocationTextField
                            label="Note"
                            value={note}
                            multiline
                            rows={5}
                            onChange={(event) => setState((s) => ({ ...s, note: event.target.value }))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ backgroundColor: '#fff' }}
                          />
                        </ChangeLocationContainer>
                      ) : (
                        <OrderDeliveryNotesText>{note}</OrderDeliveryNotesText>
                      )}

                    </SupplierDetailCard>
                  </SupplierDetailCardWrapper>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                spacing={3}
                style={{ marginTop: '0', marginBottom: '1rem' }}
              >
                <Grid item container lg={12}>
                  <SupplierDetailCardWrapper elevation={2}>
                    <TableWrapper>
                      <TableComponent aria-label="affiliates table">
                        <TableHeader>
                          <MainTableRow>
                            <Header> Product Name</Header>
                            <Header> Qty Ordered</Header>
                            <Header> Qty Received</Header>
                            <Header>Price</Header>
                          </MainTableRow>
                        </TableHeader>
                        <TableBody>
                          {cart.map((productItem) => (
                            <SupplierOrderDetailProductItem
                              key={productItem}
                              product={productItem}
                            />
                          ))}
                        </TableBody>
                      </TableComponent>
                    </TableWrapper>
                    <SubTotalBox>
                      <SubTotalText>
                        Sub Total
                      </SubTotalText>
                      <SubTotalPrice>
                        ₦
                        {` ${currencyFormatter(handleSubtotal())}`}
                      </SubTotalPrice>
                    </SubTotalBox>
                  </SupplierDetailCardWrapper>
                </Grid>
              </Grid>
            </DetailPaper>
          </Grid>
        </Grid>
      </CartGridContainer>

      <Dialog
        open={showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton onClick={() => handleCancelOrder()}>
            <DialogActionButtonText>
              OK
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>
    </MainContent>
  );
};

export default SupplierOrderDetailIndex;
