import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

export const CATEGORY_DETAIL = gql`
query category($id: Int) {
    category(id: $id ){
      id,
      categoryName
      loyaltyWeight
      markup
      vatStatus
      meta
    }
}
`;

export const CATEGORY_PRODUCTS_QUERY = gql`
${PRODUCT_VERSIONS}
  query categoryProducts(
    $categoryId: Int!
    $pageCount: Int
    $pageNumber: Int
    $productName: String
  ){
    categoryProducts(
      categoryId: $categoryId
      pageCount: $pageCount
      pageNumber: $pageNumber
      productName: $productName
    ) {
      id
      brandName
      genericName
      meta
      ...productVersions
    }
    productsTotalNumber
  }
`;

export const FIND_CATEGORY_QUERY = gql`
  query categories(
    $pageCount: Int, $pageNumber: Int, $search: String
  ){
    allCategories(
      pageCount: $pageCount
      pageNumber: $pageNumber
      categoryName: $search
    ){
      id
      categoryName
      loyaltyWeight
      markup
      vatStatus
      meta
      categoryTotalProducts
    }
    productsTotalNumber
  }
`;

export const MARKETPLACE_CATEGORIES = gql`
  query Categories($search: String) {
    categories(search: $search){
      id
      therapeuticType
      therapeuticTypeProductCount
      subTherapeuticType {
        id
        therapeuticType
      }
    }
    categoriesTotalNumber

  }
`;

export const BANNERS_PAGE_CATEGORIES = gql`
  query Categories{
    categories{
      id
      therapeuticType
    }
    categoriesTotalNumber

  }
`;

export const BUSINESS_CATEGORIES = gql`
  query businessCateogory($pageCount: Int, $pageNumber: Int, $search: String) {
    businessCategories(
      pageCount: $pageCount
      pageNumber: $pageNumber
      search: $search
    ) {
      id
      markup
      categoryName
      totalAfflfiatesCount
      meta
      creditWallet
    }
    businessCategoriesTotalNumber
  }
`;

export const BUSINESS_CATEGORIES_ORDERS_PAGE = gql`
  query businessCateogory($search: String) {
    businessCategories(search: $search) {
      categoryName
    }
  }
`;
