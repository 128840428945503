import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Grid } from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import {
  DynamicAction, StyledBottomNavigation, MoreBox, MoreStyledBottomNavigation,
  MoreMenuText, MoreMenuGrid, MoreHeaderText, CloseText
} from './mobileFooter.styles';
import {
  TopSelling, FiiledTopSelling, OrdersLogoIcon, TrackLogoIcon, WalletLogo,
  ProfileLogoIcon
} from '../../../../assets/svgs';
import { useStateValue } from '../../../../providers/stateProvider';

const MobileFooter = ({ active, handleTabChange }) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [moreOpen, setMoreOpen] = useState(false);
  const [
    { user: { role } }
  ] = Object.values(useStateValue());
  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === 3) {
      setMoreOpen(!moreOpen);
    } else {
      setMoreOpen(false);
      if (newValue === 0) handleTabChange('all');
      else if (newValue === 1) handleTabChange('topSellingProducts');
      else if (newValue === 2) handleTabChange('clearanceSale');
    }
  };

  useEffect(() => {
    if (active === 'all') {
      setValue(0);
    } else if (active === 'topSellingProducts') {
      setValue(1);
    } else if (active === 'clearanceSale') {
      setValue(2);
    } else if (active === 'more') {
      setValue(3);
    }
  }, [active]);

  const handleCloseMore = () => {
    setMoreOpen(false);
  };

  return (
    <StyledBottomNavigation
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      showLabels
    >
      <DynamicAction
        label="Buy"
        icon={value === 0 ? <ShoppingBagIcon /> : <ShoppingBagOutlinedIcon />}
        selected={value === 0}
      />
      <DynamicAction
        label="Top Selling"
        icon={value === 1 ? <FiiledTopSelling /> : <TopSelling />}
        selected={value === 1}
      />
      <DynamicAction
        label="Clearance"
        icon={value === 2 ? <AccessTimeFilledIcon /> : <AccessTimeOutlinedIcon />}
        selected={value === 2}
      />
      {role === 'affiliate-admin' && (
        <DynamicAction
          label="More"
          icon={value === 3 ? <MenuIcon sx={{ fill: '#235a91' }} /> : <MenuIcon />}
          selected={value === 3}
        />
      )}

      {moreOpen && (
        <MoreStyledBottomNavigation>
          <MoreBox>
            <MoreHeaderText>
              More Menus
            </MoreHeaderText>
            <CloseText onClick={handleCloseMore}>
              Close
            </CloseText>
          </MoreBox>
          <Grid container>
            <MoreMenuGrid item xs={1} onClick={() => navigate('/orders')}>
              <OrdersLogoIcon style={{ fontSize: '3rem' }} />
            </MoreMenuGrid>
            <MoreMenuGrid item xs={10} onClick={() => navigate('/orders')}>
              <MoreMenuText>Manage Orders</MoreMenuText>
            </MoreMenuGrid>
            <MoreMenuGrid item xs={1} onClick={() => navigate('/orders')}>
              <ArrowForwardIosIcon style={{ fontSize: '2rem', fill: '#235a91' }} />
            </MoreMenuGrid>

            <MoreMenuGrid item xs={1} onClick={() => navigate('/track-order')}>
              <TrackLogoIcon style={{ fontSize: '3rem' }} />
            </MoreMenuGrid>
            <MoreMenuGrid item xs={10} onClick={() => navigate('/track-order')}>
              <MoreMenuText>Track Orders</MoreMenuText>
            </MoreMenuGrid>
            <MoreMenuGrid item xs={1} onClick={() => navigate('/track-order')}>
              <ArrowForwardIosIcon style={{ fontSize: '2rem', fill: '#235a91' }} />
            </MoreMenuGrid>

            <MoreMenuGrid item xs={1}>
              <WalletLogo style={{ fontSize: '3rem' }} />
            </MoreMenuGrid>
            <MoreMenuGrid item xs={10}>
              <MoreMenuText>Credit Wallet</MoreMenuText>
            </MoreMenuGrid>
            <MoreMenuGrid item xs={1}>
              <ArrowForwardIosIcon style={{ fontSize: '2rem', fill: '#235a91' }} />
            </MoreMenuGrid>

            <MoreMenuGrid item xs={1} onClick={() => navigate('/account-info')}>
              <ProfileLogoIcon style={{ fontSize: '3rem' }} />
            </MoreMenuGrid>
            <MoreMenuGrid item xs={10} onClick={() => navigate('/account-info')}>
              <MoreMenuText>Profile Settings</MoreMenuText>
            </MoreMenuGrid>
            <MoreMenuGrid item xs={1} onClick={() => navigate('/account-info')}>
              <ArrowForwardIosIcon style={{ fontSize: '2rem', fill: '#235a91' }} />
            </MoreMenuGrid>
          </Grid>
        </MoreStyledBottomNavigation>
      )}

    </StyledBottomNavigation>
  );
};

MobileFooter.propTypes = {
  active: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default MobileFooter;
