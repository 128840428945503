import { styled } from '@mui/material/styles';
import { Popper, Paper, Box } from '@mui/material';

export const PopperContainer = styled(Popper)`
  z-index: 15000000;
  min-height: 0rem;
  min-width: 0rem;
  margin-left: ${({ marginLeft }) => (marginLeft)};
  margin-top: ${({ marginTop }) => (marginTop)};
  height: ${({ height }) => (height)};
  width: ${({ width }) => (width)};

  @media (max-width: 991px) {
    height: ${({ smallHeight }) => (smallHeight)};
    width: ${({ smallWidth }) => (smallWidth)};
    margin: ${({ cartDesign }) => (cartDesign && '3rem 0 0 -3rem !important')};
  }
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: ${({ creditWallet }) => (creditWallet ? '0.25rem' : '.5rem')};
  padding: ${({ cartDesign, creditWallet }) => (cartDesign ? '5px 0' : creditWallet ? '10px 5px' : '1rem 0')};
  margin-top: ${({ productInfoPopper }) => (productInfoPopper && '6rem')};

  @media(max-width: 991px) {
    border-radius: ${({ cartDesign }) => (cartDesign ? '5px' : '10px')};
  }
`;

export const Arrow = styled(Box)`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  border-bottom: 15px solid white;
  top: -12px;
  right: 18px;
`;
