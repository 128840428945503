import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  Paper, useMediaQuery, Grid, Box
} from '@mui/material';
import {
  DetailGrid, DetailContentGrid, ImgLogo, DetailHeader, DetailTypo,
  ActionButton, ActionTextField, Content, AddButton, AddCartTypo, CircularProgressLoader,
  InputButtonGroup, ProductVariantText, DivBullet, MinusIcon, PlusIcon, BottonGrid
} from './productDetail.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import { Product } from '../../../../providers/reducers/product/classes/Product';
import { UPDATE_ORDER_MUTATION } from '../../../../mutations/orders';
import CartActionTypes from '../../../../providers/reducers/cart/cartTypes';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { JSONParse } from '../../../../utils/json';

export default function ProductDetail({
  product, clearanceSale
}) {
  const isSmall = useMediaQuery('(max-width:991px)');
  const productToUse = clearanceSale ? product[0]?.product : product;
  const {
    id: productId, brandName, marketplaceVersion,
    mktPrice: actualPrice, packSize, quantityInStock: productQtyInStock, savedPromoValue
  } = new Product(productToUse);
  const quantityInStock = clearanceSale ? product[0]?.quantityInStock : productQtyInStock;
  const { description, image, } = marketplaceVersion;
  const [{
    cart: { isManualOrder },
  }, dispatch] = Object.values(useStateValue());
  const [counter, setCounter] = useState(0);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const promoVal = Math.round(product[0]?.price - product[0]?.discountedPrice) || savedPromoValue;

  const handleActionButtons = (value) => {
    const sum = counter + value;
    if (sum > quantityInStock && quantityInStock !== 0) return toast.error('Requested quantity not available');
    setCounter(sum);
  };
  const handleChange = (value) => {
    if (value > quantityInStock && quantityInStock !== 0) return toast.error('Requested quantity not available');
    setCounter(value);
  };

  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER_MUTATION);
  const handleAlertOpen = (msg) => toast(msg);

  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        productId: Number(productId),
        quantity: counter,
        isManualOrder,
        affiliateId: _selectedAffiliate?.id,
        overwriteQuantity: true,
        ...(clearanceSale && { batchId: product[0]?.id }),
        ...(quantityInStock <= 0 && { rfq: true }),
      }
    })
      .then(({ data: response }) => {
        const { message, totalProductCount } = response?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: totalProductCount
        });
        dispatch({
          type: CartActionTypes.UPDATE_CART_ITEMS,
          payload: { id: +productId, type: 'add' }
        });
        handleAlertOpen(message);
      });
  };
  const productMolecule = Object.values(JSONParse(productToUse?.molecule?.replace(/'/g, '"'))).toString().replaceAll(',', ', ');
  const splitMolecules = productMolecule.split(', ');
  const capMolecule = (string) => (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase());
  const splitMoc = splitMolecules.map((itm) => capMolecule(itm));
  let moleculeStr = '';
  splitMoc?.forEach((itm, idx) => {
    if (idx === splitMoc.length - 1) {
      moleculeStr += itm;
      return;
    }
    moleculeStr += `${itm}, `;
  });

  return (
    <DetailGrid component={Paper} container>
      <DetailContentGrid item lg={12} md={12}>
        <ImgLogo src={image} alt={brandName} />
        {!clearanceSale ? (
          quantityInStock ? (
            <ProductVariantText status={24}>
              <DivBullet type="24" />
                &nbsp;Available
            </ProductVariantText>
          ) : (
            <ProductVariantText status={72}>
              <DivBullet type="72" />
              &nbsp;
              Out of Stock
            </ProductVariantText>
          )
        ) : (
          <ProductVariantText status="clearanceSale">
            <DivBullet type="clearanceSale" />
                &nbsp;Clearance Sale
          </ProductVariantText>
        )}

        <Content>
          <Grid container spacing={2} style={{ marginTop: isSmall && '1rem' }}>
            <Grid item xs={6}>
              <Box>
                <DetailHeader> Molecule </DetailHeader>
                <DetailTypo>
                  {moleculeStr || 'N/A'}
                </DetailTypo>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <DetailHeader> Description </DetailHeader>
                <DetailTypo>
                  {description}
                </DetailTypo>
              </Box>
            </Grid>
          </Grid>

          <Grid spacing={2} container style={{ marginTop: isSmall ? '1.5rem' : '.3rem' }}>
            <Grid item xs={6}>
              <Box>
                <DetailHeader> Pack Size </DetailHeader>
                <DetailTypo>
                  {packSize}
                </DetailTypo>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <Grid container>
                  <Grid item xs={6}>
                    <DetailHeader>Price</DetailHeader>
                    <DetailTypo>
                      {`${currencyFormatter(actualPrice || Math.round(product[0]?.discountedPrice))}`}
                    </DetailTypo>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailHeader>Savings</DetailHeader>
                    <DetailTypo>
                      {`${currencyFormatter(promoVal || 0)}`}
                    </DetailTypo>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box style={{ marginTop: isSmall ? '3rem' : '1rem' }}>
                <AddButton
                  fullWidth
                  className="joy-add-to-cart"
                  variant="outlined"
                  disableElevation
                  onClick={handleAddToCart}
                >
                  {loading ? (
                    <CircularProgressLoader
                      disableShrink
                      size={24}
                      thickness={4}
                    />
                  ) : <AddCartTypo> Add to cart </AddCartTypo>}
                </AddButton>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <BottonGrid container style={{ marginTop: isSmall ? '4.2rem' : '2.2rem' }}>
                <InputButtonGroup size="small" aria-label="small outlined button group">
                  <ActionButton
                    onClick={() => handleActionButtons(-1)}
                    disabled={counter === 0}
                  >
                    <MinusIcon style={{ fontSize: isSmall ? '3.5rem' : '2rem' }} />
                  </ActionButton>
                  <ActionTextField
                    InputProps={{ disableUnderline: true, }}
                    variant="standard"
                    placeholder={0}
                    value={counter || ''}
                    onChange={(e) => handleChange(Number(e.target.value))}
                  />
                  <ActionButton
                    onClick={() => handleActionButtons(1)}
                  >
                    <PlusIcon style={{ fontSize: isSmall ? '3.5rem' : '2rem' }} />
                  </ActionButton>
                </InputButtonGroup>
              </BottonGrid>
            </Grid>
          </Grid>
        </Content>
      </DetailContentGrid>
    </DetailGrid>
  );
}

ProductDetail.propTypes = {
  product: PropTypes.instanceOf(Object),
  clearanceSale: PropTypes.string
};

ProductDetail.defaultProps = {
  product: {},
  clearanceSale: ''
};
