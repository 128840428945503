import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Grid, Step, StepLabel,
  useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import IncompleteIcon from '../../assets/images/marketOrder/processedIcon.png';
import CompleteIcon from '../../assets/images/marketOrder/completedIcon.png';
import {
  CustomStepper,
  DateText,
  HeaderText,
  StepperIcon,
  TitleText
} from './trackOrderContainer.styles';

const StepIcon = ({ completed, active }) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  return (
    <div>
      {completed
        ? <StepperIcon src={IncompleteIcon} alt="process" />
        : active ? <StepperIcon src={CompleteIcon} alt="complete" />
          : <RadioButtonUncheckedIcon style={{ fontSize: isSmall ? '30px' : '20px', color: '#008F3D' }} />}
    </div>
  );
};
StepIcon.propTypes = {
  completed: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired
};

const TrackOrderStepper = ({ activeStep, steps }) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  return (
    <Grid item container style={{ width: isSmall ? '93%' : '100%' }}>
      <CustomStepper activeStep={activeStep} orientation="vertical" style={{ width: '100%', padding: '0', marginTop: '1rem' }}>
        {steps.map(({ header, title, date }) => (
          <Step key={header}>
            <StepLabel StepIconComponent={StepIcon}>
              <Grid container>
                <Grid item md={9} style={{ textAlign: 'left' }}>
                  <HeaderText>{header}</HeaderText>
                </Grid>
                <Grid item md={3} style={{ textAlign: 'right' }}>
                  <DateText>{!isSmall && date}</DateText>
                </Grid>
              </Grid>
              <TitleText>{title}</TitleText>
              <DateText>{isSmall && date}</DateText>
            </StepLabel>
          </Step>
        ))}
      </CustomStepper>
    </Grid>
  );
};

TrackOrderStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.instanceOf(Object).isRequired,
};

export default TrackOrderStepper;
