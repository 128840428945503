import {
  Grid, Typography, Box, Button, TextField,
} from '@mui/material';
import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';

export const MainContainer = styled(Grid)`
  background: #FFFFFF;
  padding: 2rem 4rem;
`;

export const OrderGridContainer = styled('div')`
  width: 100%;
  padding: 25px 15px;
  border: 0.8px solid #E3E7EA;
  margin: 20px;
  border-radius: 10px;

  @media(max-width: 991px) {
    padding-top: 40px;
    margin-top: 4rem;
  }
  @media(min-width: 992px) {
    margin: 0;
  }
`;

export const FooterGrid = styled(Grid)`
  margin: 10px 0px;
  justify-content: flex-end;
`;

export const ArrowBackText = styled(Typography)`
  font-weight: 400;
  font-size: .75rem;
  color: #000000;
  margin-left: 5px;
`;

export const TitleBox = styled(Box)`
  height: 3.5625rem;
  border-bottom: 0.5px solid rgba(227, 231, 234, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

export const CreditWalletTypo = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
  color: #383d43;
`;

export const RepayButton = styled(Button)`
  height: 2rem;
  width: 6.6875rem;
  color: #235a91;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #235a91;
  background: #ffffff;
`;

export const NewOfferBox = styled(Grid)`
  height: 4.6875rem;
  width: 100%;
  padding: 10px;
  background: #f2f8fd;
  border-radius: 8px;
  margin-top: 1rem;
`;

export const NewOfferTTitle = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 600;
  color: #464f5b;
`;

export const NewOfferSubTitle = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  color: #606060;
`;

export const CreditBalanceBox = styled(Grid)`
  padding: 1.5rem;
  border-radius: 8px;
  height: 6.1875rem;
  background: #235a91;
  width: 100%;
  box-shadow: 0px 5.42px 43.33px 0px rgba(48, 48, 48, 0.03);
`;

export const TotalBalanceBox = styled(Grid)`
  padding: 1.5rem;
  border-radius: 8px;
  height: 6.1875rem;
  border: 0.45px solid #e3e7ea;
  width: 100%;
  box-shadow: 0px 5.42px 43.33px 0px rgba(48, 48, 48, 0.03);
`;

export const RemainText = styled(Typography)`
  font-size: 0.79rem;
  font-weight: 400;
  color: #f3f7fc;
  margin-bottom: .5rem;
`;

export const BalanceText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
  color: #f3f7fc;
`;

export const TotalText = styled(Typography)`
  font-weight: 400;
  color: #464f5b;
  font-size: 0.79rem;
  margin-bottom: .5rem;
`;

export const TotalBoldText = styled(Typography)`
  font-weight: 600;
  color: #464f5b;
  font-size: 1rem;
`;

export const IconBox = styled(Box)`
  border-radius: 50%;
  height: 2.2006rem;
  width: 2.2006rem;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const TransactionBox = styled(Box)`
  border-bottom: 0.5px solid rgba(227, 231, 234, 1);
  padding-bottom: 1rem;
  margin-left: .5rem;
  margin-top: 1.2rem;
  width: 100%;
`;

export const TransactionText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
  color: rgba(56, 61, 67, 1);
`;

export const FilterGriid = styled(Grid)`
  width: 100%;
  margin-top: .8rem;
  margin-left: 0;
`;

export const SearchField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-notchedOutline {
    border-color: rgba(223, 223, 224, 1);
  }

  & .MuiInputBase-input::placeholder {
    font-size: 0.75rem;
    color: rgba(154, 158, 166, 1);
    font-weight: 400;
    opacity: 1;
  }

  & .MuiOutlinedInput-inputMarginDense {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  
  & .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  
  & .MuiInputBase-root {
    font-size: .8rem;
    height: 2.125rem;
  }
`;

export const SearchImg = styled('img')`
  width: 1rem;
  height: 1rem;
`;

export const FilterButton = styled(Button)`
  width: 100%;
  height: 2.125rem;
  border-radius: 8px;
  border: 1px solid rgba(227, 231, 234, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(166, 179, 186, 1);
  gap: 0.625rem;
`;

export const OrderBox = styled(Box)`
  margin-top: 1rem;
  width: 100%;
  margin-left: .5rem;
`;

export const ListBox = styled(Grid)`
  border: 0.5px solid rgba(227, 231, 234, 1);
  border-radius: 8px;
  padding: 0.625rem 1.25rem;
  height: 5.75rem;
  width: 100%;
  margin-bottom: .8rem;
`;

export const DetailTypo = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(154, 158, 166, 1);
  margin: .4rem 0;
`;

export const BoldDetailTypo = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
  color: rgba(70, 79, 91, 1);
  margin: .4rem 0;
`;

export const ViewDetailButton = styled(Button)`
  height: 1.5rem;
  width: 6.5625rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 600;
  color: rgba(35, 90, 145, 1);
  background: rgba(229, 238, 249, 1);
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 3px;
`;

const isPaid = css`
  background: rgba(214, 255, 231, 1);
  color: rgba(0, 143, 61, 1);
`;

const isPending = css`
  background: rgba(255, 251, 197, 1);
  color: rgba(231, 152, 0, 1);
`;

const isOverdue = css`
  background: rgba(253, 229, 227, 1);
  color: rgba(231, 76, 60, 1);
`;

const getContainerStyles = ({ status }) => {
  switch (status) {
    case 'paid':
      return isPaid;
    case 'pending':
      return isPending;
    case 'overdue':
      return isOverdue;
    default:
      return isPending;
  }
};

export const StatusBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 4px;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  width: fit-content;
  text-transform: capitalize;
  font-size: 0.625rem;
  font-weight: 500;
  height: 1.25rem;
  margin-top: 7px;

  ${getContainerStyles}
`;
