import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputLabel, Select, MenuItem, FormHelperText
} from '@mui/material';
import {
  SelectNameTypo, FieldCheckbox, CheckboxImage, FormField
} from './returnSelectField.styles';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../assets/images/supplier/Checkbox-resting.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ReturnSelectField = ({
  field, value, handleChange, disabled, helperText
}) => {
  const [selected, setSelected] = useState(value);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected(event.target.value);
    handleChange(event);
  };

  return (
    <FormField variant="filled" size="small" required={field.name === 'customerCategory'} ref={selectRef} disabled={disabled}>
      <InputLabel id={field.name}>{field.label}</InputLabel>
      <Select
        labelId="select-filled-label"
        id={field.name}
        value={value}
        renderValue={(val) => val}
        onChange={handleInput}
        data-testid={field.name}
        inputProps={{
          name: field.name,
          required: field.name === 'paymentType'
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {field.options.map(
          (option) => (
            <MenuItem key={option} value={option}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <SelectNameTypo>{option}</SelectNameTypo>
                <FieldCheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected === option}
                />
              </Grid>
            </MenuItem>
          )
        )}
      </Select>
      <FormHelperText style={{ color: '#E74C3C' }}>{helperText}</FormHelperText>
    </FormField>
  );
};

ReturnSelectField.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

ReturnSelectField.defaultProps = {
  field: {},
  value: '',
  disabled: false,
  helperText: ''
};

export default ReturnSelectField;
