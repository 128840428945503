import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMediaQuery, Grid, Box } from '@mui/material';
import {
  ProductTypo, Content, TypoBox, DetailHeader,
  DetailTypo
} from './productInfo.styles';

export default function ProductInfo({
  product, clearanceSale
}) {
  const isSmall = useMediaQuery('(max-width:991px)');
  const productToUse = clearanceSale ? product[0]?.product : product;
  const {
    batches, marketplaceVersion, qpc, promoEndDate
  } = productToUse;
  const { expiryDate } = batches[0] || {};
  const parsedDate = promoEndDate ? moment(promoEndDate).endOf('day') : '';

  const {
    manufacturer, category, packSize, unitOfMeasurement,
    supplier, promoValue,
  } = marketplaceVersion;

  const savingsPercent = promoValue;
  const { categoryName } = category || {};
  const { id } = supplier;

  return (
    <Content>
      <TypoBox>
        <ProductTypo>Product Information</ProductTypo>
      </TypoBox>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box>
            <DetailHeader>SKU</DetailHeader>
            <DetailTypo>
              {id}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>Pack Size</DetailHeader>
            <DetailTypo>
              {packSize || 'N/A'}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>UOM</DetailHeader>
            <DetailTypo>
              {unitOfMeasurement || 'N/A'}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>Expiry Date</DetailHeader>
            <DetailTypo>
              {moment(product[0]?.expiryDate || expiryDate).format('MMM DD, YYYY') || 'N/A'}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>Promo %</DetailHeader>
            <DetailTypo>
              {savingsPercent || 0}
            </DetailTypo>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <DetailHeader>Quantity / Carton</DetailHeader>
            <DetailTypo>
              {qpc || 'N/A'}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>Category</DetailHeader>
            <DetailTypo>
              {categoryName || 'N/A'}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>Manufacturer</DetailHeader>
            <DetailTypo>
              {manufacturer || 'N/A'}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>% Savings</DetailHeader>
            <DetailTypo>
              {savingsPercent || 0}
            </DetailTypo>
          </Box>

          <Box style={{ marginTop: isSmall ? '2.5rem' : '2.3rem' }}>
            <DetailHeader>Promo Time</DetailHeader>
            <DetailTypo>
              {parsedDate || 'N/A'}
            </DetailTypo>
          </Box>
        </Grid>
      </Grid>
    </Content>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.instanceOf(Object),
  clearanceSale: PropTypes.string
};

ProductInfo.defaultProps = {
  product: {},
  clearanceSale: ''
};
