import { gql } from '@apollo/client';

export const REMOVE_LINKED_POS = gql`
  mutation RemoveLinkedAccount($linkedAccountId:Int!){
    unlinkPosAccount(linkedAccountId:$linkedAccountId){
        message
    }
  }
`;

export const UPDATE_AFFILIATE_INVOICE = gql`
  mutation updateInvoicePayment($invoiceId: Int!, $paymentInfo: PaymentInputType!){
      updateInvoicePayment(invoiceId: $invoiceId, paymentInfo: $paymentInfo){
          message
      }
  }
`;
