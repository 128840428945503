import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_AFFILIATE_ORDERS, GET_RFQ_ORDERS, GET_MERGED_ORDERS } from '../../../queries/orders';
import Order from './order';
import { JSONParse } from '../../../utils/json';

export function OrderContainer() {
  const count = sessionStorage.getItem('rowsPerPage') || 10;
  const [pageCount, setPageCount] = useState(count);
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState('');

  const initialState = {
    initialStatus: 'open',
    currentPage: 1,
    currentPageCount: pageCount,
    orderNo: '',
    orderTotalFrom: '',
    orderTotalTo: '',
    productName: '',
    orderStatus: '',
  };

  const [state, setState] = useState(initialState);
  const [active, setActive] = useState('orders');

  const {
    currentPage, currentPageCount, orderNo, affiliate,
    orderTotalFrom, orderTotalTo, productName, orderStatus,
  } = state;

  const [dateFilter, setDateFilter] = useState({
    dateFrom: '',
    dateTo: ''
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    const newDateFrom = moment(dateFrom).format('YYYY-MM-DD');
    const newDateTo = moment(dateTo).format('YYYY-MM-DD');

    setDateFilter({
      dateFrom: newDateFrom,
      dateTo: newDateTo
    });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  const isFiltering = () => {
    if (orderNo || affiliate || orderTotalFrom
    || orderTotalTo || productName || orderStatus) return true;
    return false;
  };

  const handleSearch = (value) => {
    setState({ ...state, orderNo: value });
  };

  const { dateFrom, dateTo } = dateFilter;

  const handleClearFilters = () => setState(initialState);

  const variables = {
    pageCount,
    pageNumber,
    ...(orderNo && orderNo.length >= 3 && { orderNo: Number(orderNo) }),
    ...(dateFrom && { dateFrom }),
    ...(dateTo && { dateTo }),
    ...(orderTotalFrom && orderTotalFrom.length >= 4 && { totalFrom: orderTotalFrom }),
    ...(orderTotalTo && orderTotalTo.length >= 4 && { totalTo: orderTotalTo }),
    ...(productName && productName.length >= 3 && { productName }),
    ...(filter && filter.length >= 4 && { metaStatus: filter }),
  };

  const rfqVariables = {
    pageCount,
    pageNumber,
    forAffiliate: true,
    ...(dateFrom && { dateFrom }),
    ...(dateTo && { dateTo }),
    ...(orderNo && orderNo.length >= 3 && { id: Number(orderNo) }),
  };

  const mergeVariables = {
    pageCount,
    pageNumber,
    ...(dateFrom && { dateFrom }),
    ...(dateTo && { dateTo }),
    ...(orderNo && orderNo.length >= 3 && { id: Number(orderNo) }),
  };

  const {
    data, loading, error, refetch
  } = useQuery(GET_AFFILIATE_ORDERS, {
    fetchPolicy: 'cache-and-network',
    skip: active !== 'orders',
    variables
  });

  const {
    data: rfqData, loading: rfqLoading, error: rfqError, refetch: rfqRefech
  } = useQuery(GET_RFQ_ORDERS, {
    fetchPolicy: 'no-cache',
    skip: active !== 'quoteRequests',
    variables: rfqVariables
  });

  const {
    data: mergeData, loading: mergeLoading, error: mergeError, refetch: mergeRefetch
  } = useQuery(GET_MERGED_ORDERS, {
    fetchPolicy: 'cache-and-network',
    skip: active !== 'all',
    variables: mergeVariables
  });

  if (error || rfqError || mergeError) return `Error! ${error.message}`;

  const businessOrderTotalNumber = data?.businessOrderTotalNumber || 0;
  const affiliateOrders = data?.affiliateOrders || [];
  const rfqOrders = rfqData?.rfqOrders;
  const mergedOrders = mergeData?.mergedOrders || [];
  const rfqOrderTotalNumber = rfqData?.rfqOrdersTotalNumber || 0;
  const mergedTotalNumber = mergeData?.mergedOrdersCount || 0;

  const queryRefetch = active === 'quoteRequests' ? rfqRefech : active === 'all' ? mergeRefetch : refetch;

  const totalCount = active === 'quoteRequests' ? rfqOrderTotalNumber : active === 'all' ? mergedTotalNumber : businessOrderTotalNumber;

  const affiliateOrdersItems = affiliateOrders
    .map(
      (affiliateOrdersItem) => {
        const {
          datePlaced, id, deliveryDate, dateCreated, paymentSummary, metaStatus: status, business, meta
        } = affiliateOrdersItem;
        const { total, savings } = JSONParse(paymentSummary);
        return {
          datePlaced, id, savings, status, deliveryDate, dateCreated, total, business, meta
        };
      }
    );

  const mergedOrdersItems = mergedOrders
    ?.map(
      (item) => {
        const {
          datePlaced, id, deliveryDate, paymentSummary, metaStatus: status, business, meta
        } = item;
        const { total } = JSONParse(paymentSummary);
        return {
          datePlaced, id, status, deliveryDate, total, business, meta
        };
      }
    );

  return (
    <Order
      affiliateOrders={affiliateOrdersItems}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={currentPage}
      currentPageCount={currentPageCount}
      pageCount={pageCount}
      pageNumber={pageNumber}
      setPageCount={setPageCount}
      setPageNumber={setPageNumber}
      totalCount={totalCount}
      state={state}
      handleFilterChange={handleFilterChange}
      isFiltering={isFiltering}
      handleClearFilters={handleClearFilters}
      loading={loading || rfqLoading || mergeLoading}
      handleSearch={handleSearch}
      handleSubmit={handleSubmit}
      setFilter={setFilter}
      refetch={queryRefetch}
      active={active}
      setActive={setActive}
      rfqOrders={rfqOrders}
      setState={setState}
      setDateFilter={setDateFilter}
      mergedOrders={mergedOrdersItems}
    />
  );
}

export default OrderContainer;
