import {
  Box,
  Grid,
  InputAdornment
} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../assets/images/marketOrder/SearchIco.png';
import ActiveIconNew from '../../../assets/images/marketOrder/ActiveIconNew.png';
import FilterIcon from '../../../assets/images/marketOrder/FilterIco.png';
import GrossOrderIconNew from '../../../assets/images/marketOrder/GrossOrderIconNew.png';
import GrossSavingsIconNew from '../../../assets/images/marketOrder/GrossSavingsIconNew.png';
import TotalOrderIconNew from '../../../assets/images/marketOrder/TotalOrderIconNew.png';
import { useStateValue } from '../../../providers/stateProvider';
import currencyFormatter from '../../../utils/currencyFormatter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import MainContent from '../../customComponents/mainContent';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import TablePagination from '../../shared/tablePagination';
import StatisticCard from '../shared/statisticCard';
import TitleGrid from '../shared/titleGrid';
import FilterPopper from './filters/filterPopper';
import {
  FilterDropButton, FilterDropGrid, FilterImg,
  FooterGrid,
  ItemContainer,
  ItemsBox,
  MainContainer,
  OrderGridContainer,
  OrderText,
  SearchBox,
  SearchButton,
  SearchContainer,
  SearchImage,
  SearchTextField,
  StatisticsSection, ArrowBackText
} from './order.styles';
import ReturnRow from './returnRow';
import TabsButtons from './tabButton/tabButtons';
import QuoteReqReturnRow from './quoteReqReturnRow';
import AffiliateMenu from '../../navigation/affiliateNavigation/affiliateMenu';
import MobileFooter from '../marketplaceOrder/mobileFooter/mobileFooter';

const Order = ({
  affiliateOrders,
  totalCount,
  loading,
  handleSearch,
  pageNumber,
  pageCount,
  setPageCount,
  setPageNumber,
  handleSubmit,
  setFilter,
  active,
  setActive,
  rfqOrders,
  setState,
  state,
  setDateFilter,
  mergedOrders
}) => {
  const totalsContainer = useRef(null);
  const [status, setStatus] = useState('');
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();
  const { orderNo } = state;
  const [closeMenu, setCloseMenu] = useState(false);

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalOrderAmount = affiliateOrders.reduce((previous, current) => +previous + +current.total, 0);
  const totalSavingsAmount = affiliateOrders.reduce((previous, current) => +previous + +current.savings || 0, 0);

  const activeOrders = affiliateOrders.filter((order) => order.status === 'ORDER_IN_CART');

  const STATISTICS = [
    {
      id: 1, icon: TotalOrderIconNew, title: 'Total Orders', value: affiliateOrders.length
    },
    {
      id: 2, icon: ActiveIconNew, title: 'Active Orders', value: activeOrders.length
    },
    {
      id: 3, icon: GrossOrderIconNew, title: 'Gross Order Value', value: currencyFormatter(totalOrderAmount)
    },
    {
      id: 4, icon: GrossSavingsIconNew, title: 'Close Order Value', value: currencyFormatter(totalSavingsAmount)
    },
  ];

  const isLarge = useMediaQuery('(min-width:1200px)');

  const role = localStorage.getItem('oga_user_role');
  if (role === 'oga-pharmacy-admin') return navigate('/orders-admin');

  const spacing = () => (isLarge ? 8 : 6);

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const handleStatus = (event, column) => {
    setStatus(column);
    handleFilterButtonClick(event);
    switch (column) {
      case 'Open Orders':
        return setFilter('ORDER_SENT');
      case 'Confirmed Orders':
        return setFilter('ORDER_RECEIVED');
      case 'Dispatched Orders':
        return setFilter('ORDER_DISPATCHED');
      case 'Closed Orders':
        return setFilter('ORDER_CLOSED');
      default:
        break;
    }
    return null;
  };
  // const showSupplierOrders = () => {
  //   if (status !== '') {
  //     return affiliateOrders.filter((o) => o.orderStatus === `${filter}`);
  //   }
  //   return affiliateOrders;
  // };

  // const headers = [
  //   'Date Placed', 'Order No', 'Order Status', 'Amount', 'Savings',
  // ];

  // const FilterFields = [
  //   { name: 'orderNo', label: 'Order No' },
  //   { name: 'dateFrom', label: 'Date From' },
  //   { name: 'dateTo', label: 'Date To' },
  //   { name: 'orderTotalFrom', label: 'Order Total From' },
  //   { name: 'orderTotalTo', label: 'Order Total To' },
  //   { name: 'productName', label: 'Product' },
  //   { name: 'orderStatus', label: 'Order Status', options: ['OPEN', 'DISPATCHED', 'RECEIVED', 'CLOSED'] }
  // ];
  // const returnType = (name) => {
  //   switch (name) {
  //     case 'dateFrom':
  //     case 'dateTo':
  //       return 'date';
  //     case 'orderTotalFrom':
  //     case 'orderTotalTo':
  //       return 'number';
  //     default:
  //       return 'text';
  //   }
  // };
  // const returnTextField = (field) => {
  //   const {
  //     name: fieldName, label
  //   } = field;
  //   const _value = state[fieldName];

  //   return (
  //     (fieldName === 'orderStatus'
  //       ? (
  //         <ReturnSelectField
  //           name={fieldName}
  //           label="Order Status"
  //           field={field}
  //           renderValue={_value}
  //           value={_value}
  //           disabled={false}
  //           width="100%"
  //           handleChange={handleFilterChange}
  //           fullWidth
  //         />
  //       )
  //       : (
  //         <CTextField
  //           variant="filled"
  //           label={label}
  //           value={_value}
  //           size={isLarge ? 'small' : 'medium'}
  //           type={returnType(fieldName)}
  //           name={fieldName}
  //           onChange={handleFilterChange}
  //           InputLabelProps={{ shrink: true }}
  //           fullWidth
  //         />
  //       )
  //     )
  //   );
  // };

  const renderSearchField = () => (
    <SearchContainer item xs={(active === 'orders') ? 8 : 10} lg={(active === 'orders') ? 8.5 : 9.5}>
      <SearchTextField
        variant="outlined"
        label=""
        value={orderNo}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder={(active === 'orders' || active === 'all') ? 'Search for Order' : 'Search Quote Requests'}
        fullWidth
        className="mpFe-uat-orders-search-input"
        InputProps={{
          startAdornment:
            <InputAdornment
              position="start"
              style={{ cursor: 'pointer' }}
            >
              <SearchButton
                aria-label="Search for Order"
                edge="start"
                disabled
              >
                <SearchImage
                  alt="search icon"
                  src={SearchIcon}
                />
              </SearchButton>
            </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const renderFilter = () => (
    <FilterDropGrid item xs={2} lg={1}>
      <FilterDropButton
        variant="outlined"
        onClick={handleFilterButtonClick}
        className="mpFe-uat-orders-create-filter-button"
        style={{ marginLeft: '10px', width: '5rem' }}
      >
        <FilterImg src={FilterIcon} alt="filter" />
        <Hidden mdDown>Filter</Hidden>
      </FilterDropButton>
    </FilterDropGrid>
  );

  const handleStatusValueChange = (tabType) => {
    setActive(tabType);
    setState({ ...state, orderNo: '' });
    setDateFilter({ dateFrom: '', dateTo: '' });
  };

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  const handleTabChange = (val) => {
    if (val === 'topSellingProducts') {
      navigate('/new-order/All Categories/0/0/Top Selling Products');
    } else if (val === 'clearanceSale') {
      navigate('/new-order/All Categories/0/0/Clearance Sale');
    } else if (val === 'profile') {
      navigate('/account-info');
    } else {
      navigate('/new-order');
    }
  };

  return (
    <MainContent>
      <MainContainer item container xs>
        <Box style={{ display: 'flex', cursor: 'pointer', marginBottom: '2rem' }} onClick={() => navigate('/new-order')}>
          <ArrowBackIcon sx={{ fill: '#235a91', fontSize: '15px', marginTop: '2px' }} />
          <ArrowBackText>Back to Home</ArrowBackText>
        </Box>
        <Grid container spacing={3}>
          <Hidden mdDown>
            <AffiliateMenu closeMenu={closeMenu} handleClose={handleCloseMenu} />
          </Hidden>

          <Grid item xs={12} md={closeMenu ? 10.8 : 9.3} lg={closeMenu ? 10.8 : 9.3}>
            <OrderGridContainer container>
              <TitleGrid />
              <Grid style={{ borderBottom: '1px solid #E8E8E8', marginTop: '2rem', marginBottom: '2rem' }} />
              <StatisticsSection>
                <Grid ref={totalsContainer} container spacing={2}>
                  {STATISTICS.slice(0, 4).map((statistic) => (
                    <Grid key={statistic.id} item xs={6} sm={6} md={3}>
                      <StatisticCard
                        statistic={statistic}
                        affiliate
                      />
                    </Grid>
                  ))}
                </Grid>
              </StatisticsSection>

              <OrderText>Orders History</OrderText>
              <Grid style={{ borderBottom: '1px solid #E8E8E8', marginTop: '2rem', marginBottom: '2rem' }} />
              <ItemContainer container item>
                <TabsButtons
                  active={active}
                  handleStatusValueChange={handleStatusValueChange}
                />

                <Hidden mdDown>
                  <SearchBox container>
                    {renderSearchField()}
                    <Grid item xs={(active === 'orders') ? 3 : 2.6} lg={(active === 'orders') ? 2.5 : 2.5}>
                      <CustomDateRanges
                        handleSubmit={handleSubmit}
                        xs={12}
                        styles={{
                          width: '100%', height: '40px', border: '1px solid #E7E8E9', borderRadius: '7px', marginLeft: active === 'orders' ? '5px' : '5px'
                        }}
                        affiliate
                      />
                    </Grid>
                    {(active === 'orders') && renderFilter()}
                  </SearchBox>
                </Hidden>

                <ItemsBox container>
                  <Hidden mdUp>
                    <SearchBox
                      container
                      style={{
                        marginBottom: '3.5rem'
                      }}
                    >
                      {renderSearchField()}
                      <Grid item xs={(active === 'orders') ? 2 : 2} lg={(active === 'orders') ? 2 : 2}>
                        <CustomDateRanges
                          handleSubmit={handleSubmit}
                          xs={12}
                          styles={{
                            width: '110px', height: '70px', border: '1px solid #E7E8E9', borderRadius: '10px', marginLeft: active === 'orders' ? '24px' : '23px'
                          }}
                          affiliate
                        />
                      </Grid>
                      {(active === 'orders') && renderFilter()}
                    </SearchBox>
                  </Hidden>

                  {(active === 'all') && (
                    loading ? <Grid container><ProductsTableLoader /></Grid>
                      : (mergedOrders?.map((orderItem, index) => (
                        <ReturnRow
                          key={orderItem?.id}
                          orderItem={orderItem}
                          index={index}
                        />
                      )))
                  )}

                  {(active === 'orders') && (
                    loading ? <Grid container><ProductsTableLoader /></Grid>
                      : (affiliateOrders?.map((orderItem, index) => (
                        <ReturnRow
                          key={orderItem?.id}
                          orderItem={orderItem}
                          index={index}
                        />
                      )))
                  )}

                  {(active === 'quoteRequests') && (
                    loading ? <Grid container><ProductsTableLoader /></Grid>
                      : (rfqOrders?.map((orderItem, index) => (
                        <QuoteReqReturnRow
                          key={orderItem?.id}
                          orderItem={orderItem}
                          index={index}
                        />
                      )))
                  )}

                  <FooterGrid item container>
                    <TablePagination
                      total={totalCount}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      hideRowsPerPage
                      displayShowResult
                    />
                  </FooterGrid>
                </ItemsBox>
              </ItemContainer>

            </OrderGridContainer>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <MobileFooter active="profile" handleTabChange={handleTabChange} />
        </Hidden>
      </MainContainer>
      <FilterPopper
        filterButtonEl={filterButtonEl}
        status={status}
        handleStatus={handleStatus}
        handleFilterButtonClick={handleFilterButtonClick}
      />
    </MainContent>
  );
};

Order.propTypes = {
  affiliateOrders: PropTypes.instanceOf(Array).isRequired,
  rfqOrders: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setDateFilter: PropTypes.func.isRequired,
  mergedOrders: PropTypes.instanceOf(Array).isRequired,
};

Order.defaultProps = {
  loading: false,
};

export default Order;
