import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  IconButtonWrapper, MenuTypo, GridContainer,
  AccountTypo, AccountBox
} from './affAdminPopper.styles';
import { RenderPopper } from '../../utils/utils';
import {
  OrdersLogoIcon, TrackLogoIcon, WalletLogo, ProfileLogoIcon
} from '../../assets/svgs';

const AffAdminPopper = ({
  affiliatesLogoEl,
  handleAffAdminLogoClick,
}) => {
  const open = Boolean(affiliatesLogoEl);
  const navigate = useNavigate();

  const MENU_ITEMS = [
    [<WalletLogo style={{ fontSize: '1rem' }} key="credit-wallet" />, 'Credit Wallet', '/credit-wallet'],
    [<OrdersLogoIcon style={{ fontSize: '1rem' }} key="manage-orders" />, 'Manage Orders', '/orders'],
    [<TrackLogoIcon style={{ fontSize: '1rem' }} key="track-orders" />, 'Track Orders', '/track-order'],
    [<ProfileLogoIcon style={{ fontSize: '1rem' }} key="profile-settings" />, 'Profile Settings', '/account-info'],
  ];

  const handleAccountNavigate = (item) => {
    handleAffAdminLogoClick();
    navigate(`${item}`);
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={affiliatesLogoEl}
      marginTop="1rem !important"
      onClickAway={() => handleAffAdminLogoClick()}
      popperPlacement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '2.5vw, 1vh',
        }
      }}
    >
      <GridContainer>
        <AccountBox>
          <AccountTypo>
            Account Management
          </AccountTypo>
        </AccountBox>
        {MENU_ITEMS.map((menuItem) => (
          <IconButtonWrapper
            key={menuItem[2]}
            onClick={() => handleAccountNavigate(menuItem[2])}
          >
            {menuItem[0]}
            <MenuTypo variant="subtitle1">
              {menuItem[1]}
            </MenuTypo>
          </IconButtonWrapper>
        ))}
      </GridContainer>
    </RenderPopper>
  );
};

AffAdminPopper.propTypes = {
  affiliatesLogoEl: PropTypes.instanceOf(Object),
  handleAffAdminLogoClick: PropTypes.func.isRequired
};

AffAdminPopper.defaultProps = {
  affiliatesLogoEl: {},
};

export default AffAdminPopper;
