import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  Slide,
  Box,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { DialogContentWrapper, EmptyBagIcon } from './verificationDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const VerificationDialog = ({
  openDialog,
  handleClose,
  type,
  status
}) => {
  const [header, setHeader] = useState('');
  const userStatus = (status && status === 'SUSPENDED') ? 'suspended!' : 'unverified!';

  const setText = () => {
    if (type === 'affiliateAdmin') {
      return (
        <Typography>
          Hi Admin, the affiliate you selected is currently
          <br />
          {userStatus}
        </Typography>
      );
    }
    return (
      <Typography>
        You can’t buy any item until your account has been verified. Please reach out to our customer support
        &nbsp;
        <a style={{ color: '#3498DB' }} href="mailto:support@ogapharmacy.com">support@ogapharmacy.com</a>
        &nbsp;
        or
        &nbsp;
        <a style={{ color: '#3498DB' }} href="tel:+2349055793533">+2349055793533</a>
        &nbsp;
        if your verification delays after 72hrs.

      </Typography>
    );
  };

  useEffect(() => {
    if (type === 'affiliateAdmin') {
      setHeader(`Affiliate is currently ${userStatus}`);
      return;
    }
    setHeader('Account not verified yet!');
  }, []);

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentWrapper>
        <Close
          fontSize="large"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <DialogContentText style={{ marginTop: '1rem' }}>
          <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <EmptyBagIcon />
            </Box>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {header}
            </Typography>
            <Typography variant="subtitle1" style={{ paddingTop: '1rem' }}>
              {setText()}
            </Typography>
          </Box>
        </DialogContentText>
        <DialogActions style={{ marginBottom: '2rem' }}>
          <Button
            fullWidth
            onClick={handleClose}
            variant="contained"
            siuze="large"
            style={{
              background: '#235A91',
              color: '#FFF',
              padding: '1rem 2rem'
            }}
            size="large"
          >
            Ok
          </Button>
        </DialogActions>
      </DialogContentWrapper>
    </Dialog>
  );
};

VerificationDialog.propTypes = {
  type: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default VerificationDialog;
