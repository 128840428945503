import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './actionPopper.styles';

export default function ActionPopper({
  actionPopper, handleAction, handleClose, handleActionView, handleCartList, cart, handleNoCart,
  cartOrder
}) {
  const open = Boolean(actionPopper);

  const handleViewSaveCartWithData = () => {
    handleActionView();
    handleClose();
  };
  const NoCart = () => {
    handleNoCart();
    handleClose();
  };

  const handleViewSaveCart = () => {
    if (!cartOrder?.length) {
      return NoCart();
    }
    return handleViewSaveCartWithData();
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={actionPopper}
      popperPlacement="bottom"
      onClickAway={handleClose}
      marginTop=".5rem !important"
      height="9rem !important"
      width="10rem !important"
      smallWidth="23rem !important"
      smallHeight="10rem !important"
      cartDesign
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh'
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer
          container
          item
          onClick={(e) => {
            handleAction(e);
            handleClose();
          }}
          disabled={!cart?.length}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Save Cart
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={handleViewSaveCart}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              View Saved Carts
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={(e) => {
            handleCartList(e);
            handleClose();
          }}
          disabled={!cart?.length}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Download Cart PDF
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Upload Bulk Order CSV
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  actionPopper: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleActionView: PropTypes.func.isRequired,
  handleCartList: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf.isRequired,
  cartOrder: PropTypes.arrayOf.isRequired,
  handleNoCart: PropTypes.func.isRequired
};
