import {
  Grid, Typography, Paper, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SelectMenuIcon } from '../../../assets/svgs';

export const MenuPaper = styled(Paper)`
  height: ${({ closed }) => (closed ? '30.4375rem' : '22rem')};
  max-width: ${({ closed }) => (closed ? '7.8rem' : '100%')}; 
  background: #ffffff;
  border-radius: 4px;
  padding: 1rem;
  border: 0.5px solid #e3e7ea;
  box-shadow: 0px 6px 48px 0px #30303008;
  transition: max-width 0.5s ease-in-out;
  overflow: hidden;
`;

export const MenuText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
  color: #383d43;
`;

export const MenuBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #e3e7ea;
  padding-bottom: .8rem;
  width: 100%;
`;

export const MenuTitles = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ active }) => (active ? '#235a91' : '#464f5b')};
`;

export const SelectMenu = styled(SelectMenuIcon)`
  fill: #ffffff;
  cursor: pointer;
  font-size: 1.125rem;
`;

export const MenuGrid = styled(Grid)`
  height: ${({ closed }) => (closed ? '4.4375rem' : '2.75rem')};
  justify-content: center;
  align-items: center;
  padding-left: ${({ closed }) => (closed ? '0' : '1rem')};
  margin-bottom: .7rem;
  background-color: ${({ active }) => (active ? '#f3f7fc' : '#ffffff')};
  cursor: pointer;

  &:hover {
   background-color: #f3f7fc;
  }
`;

export const ItemGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
