import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const CardHeaderGrid = styled(Grid)`
  padding: 17px 0;
  border-bottom: 1px solid #E8E8E8;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    padding: 1rem 0;
  }
`;

export const CardHeaderText = styled(Typography)`
  color: #383d43;
  font-size: 1rem;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const CardGridText = styled(Grid)`
  font-size: 0.875rem;
  color: #383d43;
  font-weight: 500;
  margin-bottom: 1rem;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const CardTitleGridText = styled(Grid)`
  font-size: 0.875rem;
  color: #7a8b96;
  font-weight: 400;
  margin-bottom: 1rem;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const GrandTotalGrid = styled(Grid)`
  align-items: center;
  border-top: 1px solid #E8E8E8;
  padding-top: 10px;

  @media (max-width: 991px) {
    padding-top: 35px;
    border-top: 2px solid #ECECEC;
  }
`;
