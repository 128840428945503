import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SupportBox = styled(Box)`
  background: #235a91;
  width: 100%;
  height: 3.124rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000001;
  top: 0;
  left: 0;

  @media (max-width: 991px) {
    height: 5rem;
  }
`;

export const SupportText = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
`;

export const MobileSupportText = styled(Typography)`
  font-weight: 600;
  font-size: 1.7rem;
  color: #ffffff;
`;
