import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useMediaQuery } from '@mui/material';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  FooterGrid,
  NoResultsContainer,
  NoResultsHeader,
  SearchResultHeader,
  ProductBox,
} from './newOrder.styles';
import ProductCard from './productCard';
import { NEW_ORDER_PRODUCTS_PAGE_QUERY, NEW_ORDER_PRODUCTS_LIST_PAGE_QUERY } from '../../../queries/products';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import TablePagination from '../../shared/tablePagination';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';
import { EmptySearch } from '../../../assets/svgs';
import TopSellingOrderProducts from './productsSections/topSellingProduct';
import FrqProducts from './productsSections/frqProduct';
import ClearanceProducts from './productsSections/clearanceProducts';

const NewOrder = ({
  state,
  productId,
  active,
  banner,
  displayType,
  categoryPage,
  handleTabChange
}) => {
  const {
    searchValue, priceMin,
    priceMax, therapeuticTypeIds, availabilityStatus, manufacturer
  } = state;
  const isSmall = useMediaQuery('(max-width: 991px)');

  const handleAlertOpen = (msg) => toast(msg);
  const [pageCount, setPageCount] = useSearchParamsState('pc', '20');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;
  const variables = {
    search: searchValue,
    therapeuticTypeIds,
    manufacturer,
    priceMin: priceMin ? parseFloat(priceMin) : null,
    priceMax: priceMax ? parseFloat(priceMax) : null,
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    availabilityStatus: (availabilityStatus === 'readilyAvailable') ? 'true' : (availabilityStatus === 'onRequest') ? 'false' : '',
    productId,
    topSelling: false,
    affiliateId: selectedAffiliate && _selectedAffiliate?.id,
  };

  const normalProductQuery = displayType === 'list' ? NEW_ORDER_PRODUCTS_LIST_PAGE_QUERY : NEW_ORDER_PRODUCTS_PAGE_QUERY;

  const { data: mainData, refetch: refetchTopSelling, loading } = useQuery(normalProductQuery, {
    fetchPolicy: 'no-cache',
    variables,
    skip: active === 'clearanceSale' || active === 'FRQProducts' || active === 'topSellingProducts',
  });

  const { newOrderPageProducts: mainProductData = [], newOrderPageProductsTotalNumber: mainTotalNumber = 0 } = mainData || {};

  return (
    <>
      { categoryPage || searchValue
        ? (
          <ProductBox categoryPage={categoryPage}>
            { loading ? <NewOrdersLoader row="3" /> : (
              <>
                {searchValue ? (
                  <SearchResultHeader>
                    Search Result for
                    &nbsp;
                    &ldquo;
                    {searchValue}
                    &rdquo;
                  </SearchResultHeader>
                ) : ''}
                <ProductCardsContainer container spacing={3}>
                  {mainProductData?.length
                    ? mainProductData?.map((product, index) => (
                      <ProductCardGrid categoryPage={categoryPage} key={product.id} item>
                        <ProductCard
                          product={product}
                          openAlert={handleAlertOpen}
                          index={index}
                          refetch={refetchTopSelling}
                          active={active}
                          displayType={displayType}
                        />
                      </ProductCardGrid>
                    )) : (
                      <NoResultsContainer>
                        <EmptySearch style={{ fontSize: isSmall ? '6.5625rem' : '4.5625rem' }} />
                        <NoResultsHeader>
                          Product Not Found!
                        </NoResultsHeader>
                        <NoResultsText>
                          No results found for your search.
                          <br />
                          Please check your keyword or try searching with a different one.
                        </NoResultsText>
                      </NoResultsContainer>
                    )}
                </ProductCardsContainer>
              </>
            )}


            {categoryPage || active === 'topSellingProducts' || active === 'clearanceSale' || active === 'FRQProducts' ? (
              <FooterGrid item container className="joy-new-order-pagination">
                <TablePagination
                  total={mainTotalNumber}
                  pageCount={+pageCount}
                  setPageCount={setPageCount}
                  pageNumber={+pageNumber}
                  setPageNumber={setPageNumber}
                  hideRowsPerPage
                  displayShowResult
                />
              </FooterGrid>
            ) : ''}
          </ProductBox>
        ) : ''}
      {((!categoryPage && active === 'all' && !searchValue) || active === 'topSellingProducts') && (
        <TopSellingOrderProducts displayType={displayType} active={active} handleTabChange={handleTabChange} categoryPage={categoryPage} banner={banner} state={state} />
      )}

      {((!categoryPage && active === 'all') || active === 'FRQProducts') ? (
        <FrqProducts displayType={displayType} active={active} handleTabChange={handleTabChange} categoryPage={categoryPage} banner={banner} state={state} />
      ) : ''}

      {((!categoryPage && active === 'all') || active === 'clearanceSale') && (
        <ClearanceProducts displayType={displayType} active={active} handleTabChange={handleTabChange} categoryPage={categoryPage} banner={banner} state={state} />
      )}

    </>
  );
};

NewOrder.propTypes = {
  state: PropTypes.instanceOf(Object),
  productId: PropTypes.number,
  active: PropTypes.string,
  banner: PropTypes.instanceOf(Array),
  displayType: PropTypes.string,
  categoryPage: PropTypes.string,
  handleTabChange: PropTypes.func
};

NewOrder.defaultProps = {
  state: {},
  productId: 0,
  active: '',
  banner: [],
  displayType: '',
  categoryPage: '',
  handleTabChange: () => {}
};

export default NewOrder;
