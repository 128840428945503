import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const CategoriesWrapper = styled('div')`
  height: 140px;
  background-color: #fff;
  width: 100%;
  @media (max-width: 991px) {
    height: 200px;
    padding: 15px 1px 5px 0px;
    box-shadow: none;
    z-index: 1000;
}

`;
export const CategoryGrid = styled('div')`
    display: flex;
    padding: 0 2rem 0 .2rem;
    height: 100%;

    @media (min-width: 992px) {
      gap: .8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 991px) {
      display: flex;
      overflow-x: auto;
      gap: 1.5rem;
      scrollBehavior: smooth;
      scrollbar-width: none;
      &::-webkit-scrollbar: {
        display: none;
        width: 0;
        height: 0;
        background: transparent;
      }
    }
`;

export const CardPaper = styled(Grid)`
  border-radius: 8px;
  cursor: pointer;
  height: 100%;
  background: #FDFEFE;
  border: 1px solid #E5EEF9;
  width: 12.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;

    ${({ active }) => active && `
        border: 1px solid #235A91;
        background: #FFFFFF;

    `}

    &:hover {
    background-color: #FFFFFF;
     border: 1px solid #235A91;
  }


  @media (max-width: 991px) {
    border-radius: 10px;
    min-width: 12rem;
    height: 11rem;
    border: 1px solid #E5EEF9;
    justify-content: space-around;
    gap: 5px;
    ${({ active }) => active && `
            border: 4px solid #235A91;
    `}

  }
`;

export const AllCategoriesPaper = styled(Grid)`
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #E5EEF9;
  width: 12.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;


    ${({ active }) => active && `
        border: 1.5px solid #235A91;
    `}

  @media (min-width: 992px) {
  }

  @media (max-width: 991px) {
    border-radius: 1.5rem;
  }
`;

export const CategoryImg = styled('img')`
    display: block;
    margin: 0 auto;
    @media (max-width: 991px) {
      width: 4rem;
      height: 4rem;
    }
`;

export const CardBox = styled('div')`
    padding: 10px 10px 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    gap: 8px;
    @media(max-width: 991px) {
      width: 100%;
      padding: 5px 20px;
    }
`;

export const CategoryText = styled(Typography)`
    font-size: .875rem;
    text-align: center;
    padding-left: 5px;
    color: #606060;
    font-weight: 500;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    text-align: center;
    font-family: 'Inter';
    @media(max-width: 991px) {
      font-size: 1.3rem;
      font-weight: 400;
      padding-left: 0px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }

`;

export const NextImage = styled('img')`
  width: 7rem;
  position: absolute;
  right: 1px;
  top: 12rem;
  z-index: 5;
  cursor: pointer;
  @media(min-width: 992px) {
    width: 5.5rem;
  }
  @media (max-width: 991px) {
    top: 2rem;
  }
`;

export const StyledIcon = styled('div')`
  width: 4.3rem;
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F3F7FC;
  margin: 0px auto;
  border-radius: 50%;
  cursor: pointer;
  padding: 13px;


  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
   width: 6.5rem;
  height: 6.5rem;
  background: none;
  }
`;
