import React from 'react';
import {
  Grid, Typography, IconButton, AppBar, Drawer,
  TextField, Button, Box, Badge
} from '@mui/material';
import { KeyboardArrowLeft, ArrowDropDown } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import {
  MenuIcon, BellIcon, MPWhiteLogo, AdminLogo, WarehouseLogo, WarehouseW
} from '../../assets/svgs';

const drawerWidth = 400;

const navBarShift = ({ open }) => open && css`
  width: calc(100% - ${drawerWidth}px);
  margin-left: drawerWidth;
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  });
  @media(max-width: 991px) {
    width: 100%;
  }
`;

const hide = ({ open }) => open && css`
  display: none;
`;

export const NavbarContainer = styled(({
  color, theme, open, ...otherProps
}) => (
  <AppBar {...otherProps} />
))`
  transition: ${(props) => props.theme.transitions.create(
    ['margin', 'width'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.leavingScreen
    }
  )};
  background: #F3F9FF;
  box-shadow: 0px 4px 8px rgba(214, 219, 230, 0.25);
  z-index: 10000000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  /* ${navBarShift} */
`;

export const AffNavbarContainer = styled(({
  color, theme, open, ...otherProps
}) => (
  <AppBar {...otherProps} />
))`
  transition: ${(props) => props.theme.transitions.create(
    ['margin', 'width'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.leavingScreen
    }
  )};
  background: #e5eef9;
  box-shadow: 0px 4px 8px rgba(223, 223, 223, 0.15);
  z-index: 10000000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 3.2rem;
  height: 4.75rem;

  @media (max-width: 991px) {
    margin-top: 5rem;
    height: 8rem;
  }
  /* ${navBarShift} */
`;

export const LogoGrid = styled(Grid)`
  align-items: center;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

export const SeparatorGrid = styled(Grid)`
  flex-wrap: nowrap;
  flex-direction: row;
`;

export const NavLeftMenu = styled(Grid)`
  justify-content: flex-start;
  display: contents;
  align-items: center;
  width: 100px;

  @media(min-width: 992px) {
    width: 250px;
  }
`;

// export const NavLeftMenu = styled(({ open, ...otherProps }) => (
//   <Grid {...otherProps} />
// ))`
//   justify-content: flex-start;
//   align-items: center;
//   width: 100px;

//   @media(min-width: 992px) {
//     width: 250px;
//   }
//   ${hide}
// `;

export const NavRightMenu = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
`;

export const AffNavRightMenu = styled(Grid)`
  justify-content: end;
  align-items: center;
  @media(min-width: 992px) {
    padding-right: 4rem;
  }
`;

export const MenuButton = styled(IconButton)`
  color: white;
`;

export const MenuBarIcon = styled(MenuIcon)`
  width: 2rem;
  height: 2rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const HeaderMenuButton = styled(IconButton)`
  padding: 0;
  margin-left: 2.7rem;
`;

export const CloseMenuButton = styled(IconButton)`
  width: 100%;
  padding: 1rem 3rem;
`;

export const NavItem = styled(Grid)`
  justify-content: center;
  align-items: center;
  text-align: right;
  border-left: 1px solid rgba(240, 240, 240, .1);
  height: 72px;
  justify-content: center;
  align-items: center;
  border-radius:0px;
`;

export const DateTimeTypo = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0px;
  text-align: right;
  padding-left: 20px;
  color: #24292E;
`;

export const ViewCartTypo = styled(Typography)`
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  opacity: .5;
  padding-right: 5px;
  color: #606060;
  margin-left: 8px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const AffiliatesLogo = styled(Grid)`
  justify-content: center;
  align-items: center;
  width: 36%;
  height: 135px;
  border-left: 0px;
  border-radius: 0px;
  margin-right: 1rem;

  @media(min-width: 992px) {
    border-left: 1px solid rgba(240, 240, 240, .1);
    width: 37%;
    height: 72px;
    margin-right: 0;
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.platform === 'erp' ? '30%' : '50%')};
  }
`;

export const LogoIcon = styled(IconButton)`
  padding: 3px;
  margin-left: 1.5rem;
  border-radius: 0;

  @media(min-width: 992px) {
    margin-left: 0.5rem;
  }
`;

export const SideLogoIcon = styled(IconButton)`
  padding: 3px;
  margin-left: 5rem;
  border-radius: 0;
  margin-top: 25px;
`;

export const PharmIQImage = styled('img')`
  justify-self: flex-start;
  margin-left: 25px;
  cursor: pointer;
  height: 85px;

  @media(min-width: 992px) {
    height: 50px;
  }
`;

export const HeaderImage = styled('img')`
  height: 62px;

  @media(min-width: 992px) {
    height: 30px;
  }
`;

export const HeaderIcon = styled(IconButton)`
  border-radius: 0;
  height: 100%;
  color: #CCCCCC;
  padding: 0 8px;

  &:hover{
    color: #A3A3A3;
  }

  @media(min-width: 992px) {
    padding: 5px;
  }
`;

export const AffiliateImage = styled('img')`
  width: 100px;
  border-radius: 50%;

  @media(min-width: 992px) {
    width: 35px;
  }
`;

export const SideAffiliateImage = styled('img')`
  width: 200px;
  border-radius: 50%;
`;

export const DrawerContainer = styled(Drawer)`
  /* width: ${drawerWidth}px; */
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: 251px;
    background: #235A91;;
    z-index: 10000001;
    border: 0;
    @media(max-width: 991px) {
      width: 100%;
    }
  }
`;

export const ArrowLeft = styled(KeyboardArrowLeft)`
  width: 3rem;
  height: 3rem;

  @media(min-width: 992px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const SideNavHeader = styled(Grid)`
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #C0C0C033;

  @media(min-width: 992px) {
    padding: 19px 0;
    border-bottom: none;
  }
`;

export const SideNavItems = styled(Grid)`
  margin-top: 10px;
  width: 250px;

  @media(max-width: 991px) {
    width: 100%;
    padding-top: 5rem;
  }
`;

export const BellButton = styled(IconButton)`
  padding: 1px;

  @media(min-width: 992px) {
    padding: 7px;
  }
`;

export const BellBarIcon = styled(BellIcon)`
  font-size: 3rem;
  color: #cccccc;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const FullPharmIQLogo = styled('img')`
  width: 17rem;
  margin-left: 2rem;
  @media(min-width: 992px) {
    width: 7rem;
  }
`;

export const SideNavBackground = styled('img')`
  position: absolute;
  height: 80%;
  width: 400px;
  z-index: 12;
  margin-top: 80px;
`;

export const SideNavImage = styled('img')`
 position: absolute;
  z-index: 15;
  margin-top: 80px;
  margin-left: 100px;
`;
export const SideNavItemImage = styled('img')`
  height: 32px;

  @media(min-width: 992px) {
    height: 30px;
  }
`;
export const ProfileIcon = styled('img')`
  width: 10%;
`;

export const ProfileTypo = styled(Typography)`
  color: #24292E;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: 12px;
    color: #606060;
    margin-left: 5px;
    width: 8rem;
    text-align: start;
  }
`;

export const SideProfileTypo = styled(Typography)`
  color: rgb(88, 92, 96);
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  margin-left: 3rem;
  color: #ffffff;
`;

export const NavMenuText = styled(Typography)`
  margin-left: 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #24292E;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const DropDownArrow = styled(ArrowDropDown)`
  font-size: 1.8rem;
`;

export const MpLogo = styled(MPWhiteLogo)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 90%;
  }

  @media (min-width: 992px) {
    &.MuiSvgIcon-root {
      width: 80%;
      height: 85%;
    }
  }
`;

export const WHLogo = styled(WarehouseW)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 90%;
  }

  @media (min-width: 992px) {
    &.MuiSvgIcon-root {
      width: 80%;
      height: 85%;
    }
  }
`;

export const OgaAdminLogo = styled(AdminLogo)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 85%;
  }

  @media (min-width: 991px) {
    &.MuiSvgIcon-root {
      width: 35%;
      height: 75%;
    }
  }
`;

export const AffAdminLogo = styled(AdminLogo)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 85%;
  }

  @media (min-width: 991px) {
    &.MuiSvgIcon-root {
      width: 50%;
      height: 60%;
      margin-left: 3rem;
    }
  }
`;

export const WarehouseLg = styled(WarehouseLogo)`
&.MuiSvgIcon-root {
  width: 32%;
  height: 72%;
}

@media (min-width: 1450px) {
  &.MuiSvgIcon-root {
    width: 35%;
    height: 75%;
  }
}

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
     display: none;
    }
  }
}
`;

export const SearchTextField = styled(TextField)`
  font-size: .75rem;
  background: #fff;
  border-radius: 4px;
  width: 100%;

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ffffff !important;
  }

  & .MuiInputBase-input::placeholder {
    font-size: 0.75rem;
    color: #9a9ea6;
    font-weight: 400;
    opacity: 1;
  }

  & .MuiOutlinedInput-inputMarginDense {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  & .MuiOutlinedInput-root {
    border-radius: 4px;
  }
  & .MuiInputBase-root {
    font-size: .8rem;
    height: 2.5rem;
  }
  & .MuiFormLabel-root {
    font-size: .75rem;
    font-weight: 500;
    line-height: 1.45rem;
  }

  @media(max-width: 991px) {
    & .MuiFilledInput-input {
      font-size: 2rem;
      height: 4rem;
      padding: 35px 20px 10px;
    }
    & .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(20px, 12px) scale(1.5);
    }

    & .MuiInputBase-root {
    font-size: 2rem;
    }

    & .MuiInputBase-root {
      font-size: 2rem;
      height: 4.6rem;
      border-radius: 20px
    }
  }
`;

export const SearchImg = styled('img')`
  width: 1rem;
  height: 1rem;

  @media(max-width: 991px) {
    width: 30px;
  }
`;

export const PriceImg = styled('img')`
  width: 1.25rem;
  height: 1.25rem;
`;

export const MobileSearchButton = styled(Button)`
  background-color: #235a91;
  border-radius: 4px;
  width: 3.188rem;
  height: 1.5rem;
  text-align: center;

  &:hover {
    background-color: #235a91;
  }
`;

export const MobileSearchText = styled(Typography)`
  font-size: .625rem;
  font-weight: 400;
  color: #ffffff;
`;

export const CartBox = styled(Box)`
  border-radius: 30px;
  height: 2.25rem;
  min-width: 12.625rem;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 0 5px;

  @media (max-width: 991px) {
    min-width: 20rem;
    height: 5.5rem;
    gap: 12px;
    border-radius: 45px;
  }
`;

export const CartText = styled(Typography)`
  font-size: .875rem;
  font-weight: 500;
  color: #235a91;
  margin-top: 2px;
`;

export const CountBox = styled(Box)`
  background: #ec0000;
  min-width: 2.25rem;
  height: 1.625rem;
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;

  @media (max-width: 991px) {
    height: 3.5rem;
    min-width: 4rem;
  }
`;

export const CountText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const CartPriceBox = styled(Box)`
  border-radius: 18px;
  height: 1.875rem;
  min-width: 5.5rem;
  background: #e5eef9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 5px;

  @media (max-width: 991px) {
    height: 3.5rem;
    min-width: 8rem;
  }
`;

export const CartPriceText = styled(Typography)`
  font-weight: 500;
  font-size: .875rem;
  color: #235a91;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const NotificationBadge = styled(Badge)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: '#fff',
  height: '32px',
  borderRadius: '18px',

  [theme.breakpoints.up('md')]: {
    height: '2.25rem',
    width: '2.25rem',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30px'
  },

  '& .MuiBadge-badge': {
    backgroundColor: '#ec0000',
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    position: 'absolute',
    top: '6px',
    right: '6px',
    transform: 'translate(50%, -50%)',
  },
}));

export const NotificationButton = styled(IconButton)`
  position: relative;
`;

export const AffLogoIcon = styled(IconButton)`
  border-radius: 50%;
  padding: 0;
  box-shadow: 0px 4px 4px 0px rgba(156, 156, 156, 0.25);
`;
