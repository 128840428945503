import {
  Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdminLogo, NewMpLogo } from '../../assets/svgs';

export const FooterGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  background: #ECF6FF;
  padding: 10px 1.5rem 10px 4rem;
  display: flex;

  @media (max-width: 991px) {
    justify-content: center;
    padding: 5rem 3rem;


  }
`;

export const Rights = styled(Typography)`
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #0A142F;
@media (max-width: 991px) {
  font-size: 35px;
}
`;
export const RightsContainer = styled(Typography)`
justify-content: center;
padding: 25px 0px;

`;

export const LogoWrapper = styled(Typography)`
  display: flex;
  justify-content: center;
`;

export const TextWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
`;
export const OgaAdminLogo = styled(AdminLogo)`
  &.MuiSvgIcon-root {
    width: 12rem;
    height: 3.875rem;
  }
`;

export const NewAdminLogo = styled(NewMpLogo)`
  &.MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled(Typography)`
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #0A142F;

@media (max-width: 991px) {
  font-size: 35px;
}

`;
