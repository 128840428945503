const cart = {
  cartCounter: 0,
  cartItems: [],
  isManualOrder: false,
  cartStatus: '',
  productCartItems: [],
  cartValue: 0
};

export default cart;
