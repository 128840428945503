import {
  Typography, Box, TableCell, Grid, Button,
  Paper,
  TableRow,
  TableBody
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentBox = styled(Box)`
  padding: 20px 3rem;

  @media (max-width: 991px) {
    padding: 30px;
  }
`;

export const PaymentText = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  color: #303030;
  background: #F3F9FF;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  @media(min-width: 992px) {
   &:nth-child(1){
      min-width: 5%;
    }

    &:nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7), :nth-child(8) :nth-child(9) {
      min-width: 11.875%;
    }
  }
`;

export const TableBox = styled(Box)`
  margin-top: 20px;
`;

export const BackgroundBox = styled(Box)`
  //background: #fff;
`;

export const DetailBox = styled(Paper)`
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 18px 21px 24px;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;

  @media (min-width: 992px) {
    justify-content: flex-end;
    margin-top: 5rem;
  }
`;

export const MobileContainer = styled(Box)`
  width: 100%;
`;

export const FilterBox = styled(Box)`
  margin-bottom: 1.5rem;
  text-align: right;
`;

export const FilterImg = styled('img')`
  height: 5rem;
  width: 5rem;
`;

export const TitleGridContainer = styled(Grid)`
  margin: 2rem 1rem 2rem 0;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: #24292E;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const AddCategory = styled(Button)`
  width: 170px;
  height: 45px;
  background: #235A91;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  font-weight: 550;
`;

export const FilterGrid = styled(Grid)`
  padding: 19px 15px 19px 24px;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  margin-bottom: 20px;
`;

export const TableGrid = styled(Grid)`
  overflow-x: auto;
  padding-bottom: 10px;
`;
export const MainTableHead = styled(TableRow)`
  border: 0;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const SubTitle = styled('div')`
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #606060;
`;
