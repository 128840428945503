import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, OrderButtonText, OrderButton
} from './tabButtons.styles';

const TabsButtons = ({ active, handleStatusValueChange }) => (
  <StatusesGrid container item>
    <OrderButton
      type="myOrder"
      container
      onClick={() => handleStatusValueChange('all')}
      active={active === 'all'}
      width="8%"
      className="mpAdmin-uat-ordersPageTab-all"
    >
      <OrderButtonText active={active === 'all'}>All</OrderButtonText>
    </OrderButton>
    <OrderButton
      type="myOrder"
      container
      onClick={() => handleStatusValueChange('orders')}
      active={active === 'orders'}
      width="8%"
      className="mpAdmin-uat-ordersPageTab-orders"
    >
      <OrderButtonText active={active === 'orders'}>My Orders</OrderButtonText>
    </OrderButton>
    <OrderButton
      type="myOrder"
      container
      onClick={() => handleStatusValueChange('quoteRequests')}
      active={active === 'quoteRequests'}
      width="12%"
      className="mpAdmin-uat-ordersPageTab-quoteRequests"
    >
      <OrderButtonText active={active === 'quoteRequests'}>Quote Request(s)</OrderButtonText>
    </OrderButton>
  </StatusesGrid>
);
TabsButtons.propTypes = {
  active: PropTypes.string,
  handleStatusValueChange: PropTypes.func.isRequired
};

TabsButtons.defaultProps = {
  active: '',
};

export default TabsButtons;
