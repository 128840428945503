import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, Paper
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import SyncIcon from '@mui/icons-material/Sync';
import ReturnSelectField from './returnSelectField';
import InitialImage from './initialImage';
import {
  ButtonText, TitleText, TitleWrapper, CircularProgressLoader, UploadGrid,
  GridWrapper, FieldGrid, SupplierTextField, FormLabel, EditButton,
  MessageBoxTextField, MessageBoxContainer, ReasonGrid, TableContainerWrapper, ButtonGrid, MiddleGrid, CancelButton,
  Reset
} from './individualAffiliate.styles';
import {
  UPDATE_BUSINESS_MUTATION
} from '../../../../mutations/business';
import { validateEmail } from '../../../auth/utils';
import { parseAffiliateFields, parseJson } from '../../../affiliates/utils';

import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import currencyFormatter from '../../../shared/currencyFormatter';
import { useStateValue } from '../../../../providers/stateProvider';
import { BUSINESS_CATEGORIES } from '../../../../queries/categories';

const IndividualAffiliate = ({
  affiliate, refetch, loadingData
}) => {
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());
  const initialState = {
    legalName: '',
    contactName: '',
    businessType: 'affiliate',
    mobile: '',
    email: '',
    secondaryEmailAddress: '',
    tax: '',
    status: '',
    addressLine1: '',
    addressLine2: '',
    city: 'Lagos',
    country: 'Nigeria',
    id: '',
    logo: '',
    reasonForUpdate: '',
    creditDays: '',
    license: [],
    grossOrderAmount: 0,
    orderVolume: 0,
    creditLimit: 0,
    salesRepresentative: '',
    customerCategory: '',
    categoryId: '',
    gracePeriod: '',
    bisCategory: ''
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    reasonError: false,
    categoryError: false
  };

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [licenseFile, setLicenceFile] = useState([]);
  const [finalLicenceImage, setFinalLicenceImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [editing, setEditing] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  // const [{
  //   affiliate: { affiliate, refetch, affiliateDetailRefetch }
  // }, dispatch] = Object.values(useStateValue());
  const role = localStorage.getItem('oga_user_role');
  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';

  const [updateBusiness] = useMutation(UPDATE_BUSINESS_MUTATION);
  const variables = {};

  const {
    loading: categoryLoading, catError, data: categoryData
  } = useQuery(BUSINESS_CATEGORIES, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const {
    businessCategories = [],
  } = categoryData || {};

  const { nameError, reasonError, categoryError } = errorState;
  useLayoutEffect(() => {
    if (affiliate) {
      const {
        id, legalName, logo, city, country, mobile, addressLine1, addressLine2,
        contactName, email, secondaryEmailAddress, tax, status, creditDays, license,
        grossOrderAmount, orderVolume, creditLimit, salesRepresentative, businessCategory, gracePeriod
      } = parseAffiliateFields(affiliate);
      const { credit_days: cDays, credit_limit: cLimit, grace_period: gPeriod } = parseJson(businessCategory?.creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));

      setState((_state) => ({
        ..._state, legalName, contactName, email, secondaryEmailAddress, status,
        mobile, addressLine1, addressLine2, city, country, id, logo, tax, creditDays: creditDays ?? cDays, license, bisCategory: businessCategory,
        grossOrderAmount, orderVolume, creditLimit: creditLimit ?? cLimit, salesRepresentative, customerCategory: businessCategory?.categoryName, categoryId: businessCategory?.id, gracePeriod: gracePeriod ?? gPeriod
      }));
      const filterLicense = license?.map(({ name, url, size }) => ({ name, url, size }));
      if (license?.length) setFinalLicenceImage([...filterLicense]);
    }
  }, [affiliate]);

  const handleEditing = () => {
    setEditing(true);
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const handleLicenseUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          setIsLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        setIsLoading(false);
        return resolve(response.secure_url);
      });
    });
  };

  const handleStatusOptions = () => {
    const { status } = state;
    const options = ['VERIFIED', 'SUSPENDED'];
    const filter = options.filter((val) => val !== status);
    return filter;
  };

  const handleCategoryOptions = () => {
    const filter = businessCategories.map((val) => val.categoryName);
    return filter;
  };

  // const editAffiliate = (logo, license) => {
  const handleFileEdit = () => {
    setEditing(true);
  };
  const handleFile = (filesData) => {
    setEditing(true);
    setFiles(filesData);
  };
  useEffect(() => {
    if (licenseFile?.length) {
      setIsLoading(true);
    }
  }, [licenseFile]);
  const handleLicence = async (filesData) => {
    setEditing(true);
    setLicenceFile(filesData);

    if (filesData?.length) {
      const license = await handleLicenseUpload(filesData[0]?.data);
      setFinalLicenceImage([
        ...finalLicenceImage,
        { name: filesData[0]?.file.name, url: license, size: filesData[0]?.file.size }
      ]);
    }
  };

  const handleUpload = () => {
    setEditing(false);
  };
  const handleCancle = () => {
    setLicenceFile([]);
  };
  const handleDelete = (data) => {
    if (data) {
      const filterFile = finalLicenceImage.filter((file) => file.name !== data);
      return setFinalLicenceImage([...filterFile]);
    }
  };
  const editAffiliate = (logo) => {
    const {
      id, legalName, contactName, mobile, email, secondaryEmailAddress, creditLimit,
      addressLine1, addressLine2, city, country, tax, status, reasonForUpdate, creditDays, salesRepresentative, categoryId, gracePeriod
    } = state;
    updateBusiness({
      variables: {
        id,
        legalName,
        contactName,
        logo,
        mobile,
        email,
        secondaryEmailAddress,
        tax: parseFloat(tax),
        status,
        addressLine1,
        addressLine2,
        city,
        country,
        terms: false,
        reasonForUpdate,
        creditDays,
        license: finalLicenceImage,
        creditLimit: parseFloat(creditLimit),
        salesRepresentative,
        categoryId,
        gracePeriod
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateBusiness || {};
        toast.success(message);
        setFiles(null);
        refetch();
        setEditing(false);
        setState((_state) => ({
          ..._state, reasonForUpdate: ''
        }));
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancleEdit = () => {
    setEditing(false);
  };
  const handleSave = async () => {
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134434/Placeholders/Affliate_Placeholder.png';
    const alreadyUploadedImage = state.logo || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    return editAffiliate(logo);
  };

  const handleSaveButtonClick = () => {
    const { legalName, reasonForUpdate, customerCategory } = state;
    if (!customerCategory || reasonForUpdate.length < 1) {
      return setErrorState({
        ...errorState,
        categoryError: !customerCategory,
        reasonError: reasonForUpdate.length < 1
      });
    }
    if (legalName.length > 1 && reasonForUpdate.length > 1 && customerCategory) {
      return handleSave();
    }
    return setErrorState({
      ...errorState,
      nameError: true,
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'reasonForUpdate':
        return setErrorState({
          ...errorState,
          reasonError: !(value.length > 1)
        });
      case 'customerCategory':
        return setErrorState({
          ...errorState,
          categoryError: !(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'customerCategory') {
      const findName = businessCategories?.filter((val) => val.categoryName === value);
      const catId = findName[0]?.id;
      const { credit_days: cDays, credit_limit: cLimit, grace_period: gPeriod } = parseJson(findName[0]?.creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));

      return setState({
        ...state, [name]: value, categoryId: catId, creditDays: cDays, creditLimit: cLimit, gracePeriod: gPeriod
      });
    }
    return setState({ ...state, [name]: value });
  };

  const handleCategoryReset = () => {
    const { bisCategory } = state;
    const { credit_days: cDays, credit_limit: cLimit, grace_period: gPeriod } = parseJson(bisCategory?.creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
    return setState({
      ...state, creditDays: cDays, creditLimit: cLimit, gracePeriod: gPeriod
    });
  };

  const topFields = [
    {
      name: 'legalName',
      label: 'Affiliate’s Full name',
      helperText: 'Affiliate name required'
    },
    { name: 'email', label: 'Email Address' },
    { name: 'addressLine1', label: 'Address' },
    {
      name: 'customerCategory', label: 'Customer Category', options: handleCategoryOptions(), helperText: 'Customer Category field is required'
    },
    { name: 'creditLimit', label: 'Credit Limit' },
    { name: 'creditDays', label: 'Credit Days' },
    { name: 'gracePeriod', label: 'Grace Period' },

  ];
  const middleFields = [
    { name: 'mobile', label: 'Phone Number' },
    { name: 'secondaryEmailAddress', label: 'Secondary Email Address' },
    { name: 'orderVolume', label: 'Total Order Number' },
    { name: 'grossOrderAmount', label: 'Total Order Value' },
    { name: 'tax', label: 'Tax', endIcon: '%' },
    { name: 'city', label: 'City' },
    { name: 'status', label: 'Status', options: handleStatusOptions() },
  ];

  const reasonField = [
    { name: 'reasonForUpdate', label: 'Reason For Update', helperText: 'This field is required' },
  ];

  const accountHandlerFields = [
    { name: 'salesRepresentative', label: 'Handler’s Full name' },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, endIcon
    } = field;
    const value = fieldName === 'grossOrderAmount' ? currencyFormatter(state[fieldName]) : state[fieldName];
    const disableStatus = (fieldName === 'status' && !affiliate) || !isAdmin || !editing;
    if (fieldName === 'country' || fieldName === 'status' || fieldName === 'customerCategory') {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
          disabled={disableStatus}
          helperText={categoryError && helperText}
        />
      );
    }
    if (fieldName === 'reasonForUpdate') {
      return (
        <Grid>
          <FormLabel>Reason For Update*</FormLabel>
          <MessageBoxContainer>
            <MessageBoxTextField
              name={fieldName}
              value={value}
              multiline
              rows={5}
              error={reasonError}
              helperText={reasonError && helperText}
              required={editing}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ backgroundColor: '#f0f0f0', marginBottom: '4em' }}
            />
          </MessageBoxContainer>
        </Grid>
      );
    }
    if (fieldName === 'creditLimit' && isAdmin) {
      return (
        <>
          <SupplierTextField
            variant="filled"
            size="small"
            label={label}
            value={value}
            type="number"
            name={fieldName}
            onChange={handleChange}
            disabled={!editing}
          />
          {editing && (
            <Reset onClick={handleCategoryReset}>
              <SyncIcon style={{ fontSize: '.9rem' }} />
              Reset to default category limit
            </Reset>
          ) }
        </>
      );
    }
    if (fieldName === 'creaditDays' && isAdmin) {
      return (
        <SupplierTextField
          variant="filled"
          size="small"
          label={label}
          value={value}
          type="number"
          name={fieldName}
          onChange={handleChange}
          disabled={!editing}
        />
      );
    }
    if (fieldName === 'gracePeriod' && isAdmin) {
      return (
        <SupplierTextField
          variant="filled"
          size="small"
          label={label}
          value={value}
          type="number"
          name={fieldName}
          onChange={handleChange}
          disabled={!editing}

        />
      );
    }
    return (
      <SupplierTextField
        variant="filled"
        size="small"
        label={label}
        value={value}
        type={fieldName === 'tax' ? 'number' : 'text'}
        error={fieldName === 'legalName' ? nameError : false}
        helperText={nameError && helperText}
        name={fieldName}
        onChange={handleChange}
        disabled={(fieldName === 'tax' && !isAdmin) || fieldName === 'grossOrderAmount' || !editing}
        InputProps={{
          endAdornment: (<InputAdornment position="end">{endIcon}</InputAdornment>)
        }}
      />
    );
  };

  return (
    <TableContainerWrapper component={Paper}>
      { loadingData ? <ProductsTableLoader /> : (
        <Grid container>
          <UploadGrid container item xs={12} md={6}>
            <InitialImage
              image={state.logo}
              name={state.legalName}
              files={files}
              editing={editing}
              handleFile={handleFile}
              handleLicence={handleLicence}
              handleFinalImage={setFinalImage}
              licenseFile={licenseFile}
              handleUpload={handleUpload}
              isLoading={isloading}
              finalLicenceImage={finalLicenceImage}
              handleCancle={handleCancle}
              handleDelete={handleDelete}
              handleFileEdit={handleFileEdit}
            />
          </UploadGrid>
          <GridWrapper container item xs={12} md={6}>
            <TitleWrapper container>
              <TitleText>
                Account handler
              </TitleText>
            </TitleWrapper>
            <Grid container xs={12} md={12} item>
              <MiddleGrid container item md={6}>
                {accountHandlerFields.map((field) => returnTextField(field))}
              </MiddleGrid>
            </Grid>
            <TitleWrapper container>
              <TitleText>
                Affiliate Information
              </TitleText>
            </TitleWrapper>
            <Grid container item xs={12} md={12} spacing={2}>
              <FieldGrid xs={12} md={6} item>
                {topFields.map((field) => returnTextField(field))}
              </FieldGrid>
              <FieldGrid xs={12} md={6} item>
                {middleFields.map((field) => returnTextField(field))}
              </FieldGrid>
              {editing ? (
                <ReasonGrid container item>
                  {reasonField.map((field) => returnTextField(field))}
                </ReasonGrid>
              ) : ''}
            </Grid>

            <ButtonGrid container item>
              {affiliate && !editing
                ? (
                  <EditButton
                    data-testid="edit"
                    onClick={handleEditing}
                    disabled={!userPermissions?.includes('manage_affiliates_edit')}
                  >
                    <ButtonText>
                      Edit
                    </ButtonText>
                  </EditButton>
                ) : ''}

              {editing
                ? (
                  <>
                    <CancelButton
                      onClick={handleCancleEdit}
                    >
                      <ButtonText>
                        Cancel
                      </ButtonText>
                    </CancelButton>
                    <EditButton
                      onClick={handleSaveButtonClick}
                      disabled={!userPermissions?.includes('manage_affiliates_save')}
                    >
                      {loading ? (
                        <CircularProgressLoader
                          disableShrink
                          size={22}
                          thickness={5}
                          data-testid="save"
                        />
                      ) : (
                        <ButtonText>
                          Save
                        </ButtonText>
                      )}
                    </EditButton>
                  </>
                ) : ''}
            </ButtonGrid>
          </GridWrapper>

        </Grid>
      )}
    </TableContainerWrapper>
  );
};

IndividualAffiliate.propTypes = {
  affiliate: PropTypes.instanceOf(Object),
  refetch: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired,

};

IndividualAffiliate.defaultProps = {
  affiliate: []
};
export default IndividualAffiliate;
