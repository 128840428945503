import React from 'react';
import {
  Box, Paper, Grid, Typography, TextField, Button, Stepper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const ContentBox = styled(Box)`
  padding: 3rem;
  background: #FFFFFF;
`;

export const TrackOrderGridContainer = styled('div')`
  width: 100%;
  padding: 30px 35px;
  border: 0.8px solid #E3E7EA;
  // margin: 20px;
  border-radius: 10px;

  @media(max-width: 991px) {
    padding-top: 40px;
    margin-top: 4rem;
  }
  @media(min-width: 992px) {
    margin-top: 0;
  }
`;

export const TopPaper = styled(Grid)`
  border-radius: 5px;
  border: 0.8px solid #E3E7EA;
  padding: 15px;
`;

export const TrackGrid = styled(Grid)`
  // padding: 2rem;
  padding: 10px;
`;

export const HeaderTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  @media(min-width: 992px) {
    font-size: 1.7rem;
  }
  @media(max-width: 991px) {
    margin-bottom: 2rem;
  }
`;

export const SubHeader = styled(Typography)`
  font-size: .9rem;
  color: #464F5B;
  font-weight: 600;
  margin-bottom: 1rem;

  @media (max-width: 991px) {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

export const TrackImg = styled('img')`
  height: 100%;
`;

export const TrackText = styled(Typography)`
  font-size: .9rem;
  color: #606060;
  font-weight: 300;
  margin-bottom: .5rem;

  @media(max-width: 991px) {
    font-size: 1.7rem;
    font-weight: 400;
  }
`;

export const CTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 2rem;
  }
`;

export const TrackOrderButton = styled(Button)`
  align-items: center;
  padding: 2px 15px;
  background-color: #235A91;
  color: #FFFFFF;
  border-radius: 0.3rem;
  height: 40px;

  &:hover {
    background-color: #235A91;
  }

  &:disabled {
    color: #FFFFFF;
    background-color: #235A91;
  }

  @media(min-width: 992px) {
    height: 40px;
    padding: 0px 15px;
    border-radius: .3rem;
    width: 65px;
  }

  @media(max-width: 991px) {
    width: 100%;
    height: 60px;
  }
`;

export const TrackOrderButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: .9rem;
    font-weight: 500;
  }
`;

export const BottomPaper = styled(Grid)`
  padding: 3rem;
  margin-top: 2rem;
  border-radius: 5px;
  border: 0.8px solid #E3E7EA;
`;

export const EmptyBox = styled(Box)`
  display: block;
  margin: auto;
  text-align: center;
`;

export const SearchGif = styled('img')`
  width: 10rem;
`;

export const Typo = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: .95rem;
  font-weight: 550;
  color: #464F5B;
  // margin-bottom: .5rem;
  margin-left: 13px;

  @media (max-width: 991px) {
    font-size: 1.5rem;
    margin-left: 20px;
  }
`;

export const TitleText = styled(Typography)`
  color: #9A9EA6;
  font-size: .8rem;
  margin-left: 13px;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 1.3rem;
    margin-left: 20px;
    margin-top: 0.8rem;
  }
`;

export const DateText = styled(Typography)`
  color: #464F5B;
  font-size: .8rem;
  font-weight: 600;

  @media (max-width: 991px) {
    margin-left: 20px;
    margin-top: 0.3rem;
    font-size: 1.3rem;
  }
`;

export const DispatchStepper = styled(Stepper)`
  padding: 0rem 0 1rem;
  width: 100%;
  background: none;
  justify-content: space-between;
  &.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    display: none;
}
`;

export const DispatchTypo = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 600;

  @media (min-width: 991px) {
    font-size: 1.2rem;
  }
`;

export const StepperIcon = styled('img')`
  width: 19px;
  // position: relative;
  // z-index: 3;
  @media (max-width: 991px) {
    width: 29px;
  }
`;

export const CustomStepper = styled(Stepper)`
  &.MuiStepper-vertical {
    flex-direction: column-reverse;
  }
  & .MuiStepLabel-labelContainer {
    padding-top: 14px;
  }
  & .MuiStepConnector-root {
    margin-top: -31px;
    margin-bottom: -25px;
  }
  & .MuiStepContent-root {
    border-left: 3px solid #008F3D;
    margin-left: 16px;
  }
  & .MuiStepConnector-line {
    border-color: #008F3D;
    min-height: 60px;
    margin-left: -4px;
    border-left-width: 3px;
    // position: relative;
    // z-index: 1;
  }

  @media (max-width: 991px) {
    & .MuiStepContent-root {
      border-left: 3px solid #008F3D;
      margin-left: 29px;
    }
    & .MuiStepConnector-line {
      border-color: #008F3D;
      min-height: 140px;
      margin-left: 1px;
      border-left-width: 3px;
    }

    & .MuiStepLabel-labelContainer {
      padding-top: 75px;
      height: 190px;
    }
    & .MuiStepConnector-root {
      margin-top: -93px;
      margin-bottom: -88px;
  }
`;

const defaultStyles = css`
  background-color: #235A91;
  color: #fff;

  &:hover {
    background-color: #235A91;
  }
`;

const tertiaryPrimaryTitleStyles = css`
  color: #383D43;
  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

const createButton = ({ type }) => {
  switch (type) {
    case 'tertiaryPrimaryTitle':
      return tertiaryPrimaryTitleStyles;
    default:
      return defaultStyles;
  }
};

export const OrdersPrimaryTitle = styled(({ color, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  color: #303030;
  font-size: 2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 25px;
  }

  ${createButton}
`;

export const ArrowBackText = styled(Typography)`
  font-weight: 400;
  font-size: .75rem;
  color: #000000;
  margin-left: 5px;
`;
