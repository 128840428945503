import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid } from '@mui/material';
import { PRODUCT_DETAILS_QUERY_MP } from '../../../../queries/products';
import {
  ContentContainer, ProductTypo, ProductNameTypo, MenuButton, BackTypo,
} from './detailsContainer.styles';
import MainContent from '../../../customComponents/mainContent';
import ProductDetail from './productDetail';
import ProductInfo from './productInfo';
import SimilarProducts from '../../../cart/similarProducts/similarProduct';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';

const DetailsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;

  const { loading, data } = useQuery(PRODUCT_DETAILS_QUERY_MP, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
      affiliateId: _selectedAffiliate?.id
    },
  });

  if (loading) return <NewOrdersLoader />;

  const { product = {} } = data || {};
  const { brandName, marketplaceVersion: { therapeuticType } } = product;
  const { id: theraId } = therapeuticType || {};

  return (
    <MainContent>
      <ContentContainer>
        <MenuButton
          variant="outlined"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon fontSize="large" />}
        >
          <BackTypo>back</BackTypo>
        </MenuButton>
        <ProductTypo>Product Detail</ProductTypo>
        <ProductNameTypo>
          {brandName}
        </ProductNameTypo>
        <Grid container spacing={2}>
          <Grid item md={7} xs={12}>
            <ProductDetail product={product} />
          </Grid>
          <Grid item md={5} xs={12}>
            <ProductInfo product={product} />
          </Grid>
        </Grid>
        <SimilarProducts searchProductIds={[id]} therapeuticTypes={[theraId]} cart />
      </ContentContainer>
    </MainContent>
  );
};

export default DetailsContainer;
