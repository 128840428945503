import {
  Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  min-width: 230px;
  display: flex;
  flex-direction: column;

  @media(max-width: 991px) {
    padding: 0px 15px;
  }
`;

export const InfoText = styled(Typography)`
  font-size: 2.5rem;
  padding: 15px;
  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const ItemTypoBox = styled(Grid)`
  cursor: pointer;
  width: 160px;
  &:hover {
      background-color: #f3f9ff;
  }
  @media(max-width: 991px) {
    width: 190px;
  }
`;

export const ItemTypo = styled(Typography)`
  // margin-left: 25px;
  font-size: 1rem;
  color: #606060;
  // padding: 25px 15px;
  padding: 5px;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 0.9rem;
    padding: 5px 10px;
  }
`;
