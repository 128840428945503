import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Grid } from '@mui/material';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  NoResultsContainer,
  NoResultsHeader,
  SectionHeader,
  Title,
  SeeAll,
  ProductBox,
  FooterGrid,
  MiddleImage,
} from './styles';
import { NEW_ORDER_PRODUCTS_PAGE_QUERY, NEW_ORDER_PRODUCTS_LIST_PAGE_QUERY } from '../../../../queries/products';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';
import useSearchParamsState from '../../../shared/helpers/ulrSearchParams';
import ProductCard from '../productCard';
import TablePagination from '../../../shared/tablePagination';
import { EmptySearch } from '../../../../assets/svgs';

const FrqProducts = ({
  displayType, active, handleTabChange, categoryPage, banner, state
}) => {
  const handleAlertOpen = (msg) => toast(msg);
  const [pageCount, setPageCount] = useSearchParamsState('pc', active === 'FRQProducts' ? '20' : '5');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const { url: bannerUrl, banner: bannerImage } = banner[0] || {};
  const handleBannerClick = () => bannerUrl && window.open(bannerUrl);
  const {
    searchValue,
  } = state;

  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;
  const variables = {
    search: '',
    manufacturer: [],
    priceMin: null,
    priceMax: null,
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    availabilityStatus: '',
    topSelling: false,
    affiliateId: selectedAffiliate && _selectedAffiliate?.id,
    frequentlyOrderedProducts: true
  };

  const normalProductQuery = displayType === 'list' ? NEW_ORDER_PRODUCTS_LIST_PAGE_QUERY : NEW_ORDER_PRODUCTS_PAGE_QUERY;

  const { data: topSellingData, refetch: refetchTopSelling, loading: dataLoading } = useQuery(normalProductQuery, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const { newOrderPageProducts: topSellingProductData = [], newOrderPageProductsTotalNumber = 0 } = topSellingData || {};

  return (
    dataLoading ? <NewOrdersLoader row="3" />
      : (
        <ProductBox>
          <SectionHeader>
            <Title>Your Frequently Ordered</Title>
            {(active !== 'FRQProducts') && (<SeeAll onClick={() => handleTabChange('FRQProducts')}>See All</SeeAll>)}
          </SectionHeader>
          <ProductCardsContainer container spacing={3}>
            {topSellingProductData?.length
              ? topSellingProductData?.map((product, index) => (
                <ProductCardGrid key={product.id} item>
                  <ProductCard
                    product={product}
                    openAlert={handleAlertOpen}
                    index={index}
                    refetch={refetchTopSelling}
                    displayType={displayType}
                  />
                </ProductCardGrid>
              )) : (
                <NoResultsContainer>
                  <EmptySearch style={{ fontSize: '4.5625rem' }} />
                  <NoResultsHeader>
                    Product Not Found!
                  </NoResultsHeader>
                  <NoResultsText>
                    No results found for your search.
                    <br />
                    Please check your keyword or try searching with a different one.
                  </NoResultsText>
                </NoResultsContainer>
              )}
            {(bannerImage && !categoryPage && active === 'all' && !searchValue) && (
              <Grid item xs={12} container justifyContent="center">
                <MiddleImage onClick={handleBannerClick} src={bannerImage} alt="Banner" />
              </Grid>
            )}
          </ProductCardsContainer>
          {active === 'FRQProducts' ? (
            <FooterGrid item container className="joy-new-order-pagination">
              <TablePagination
                total={newOrderPageProductsTotalNumber}
                pageCount={+pageCount}
                setPageCount={setPageCount}
                pageNumber={+pageNumber}
                setPageNumber={setPageNumber}
                hideRowsPerPage
                displayShowResult
              />
            </FooterGrid>
          ) : ''}
        </ProductBox>
      )
  );
};

FrqProducts.propTypes = {
  displayType: PropTypes.string,
  active: PropTypes.string,
  handleTabChange: PropTypes.func,
  categoryPage: PropTypes.bool,
  banner: PropTypes.instanceOf(Array),
  state: PropTypes.instanceOf(Object)
};

FrqProducts.defaultProps = {
  displayType: '',
  active: '',
  handleTabChange: () => {},
  categoryPage: false,
  banner: [],
  state: {}
};

export default FrqProducts;
