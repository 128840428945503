import React from 'react';
import {
  Grid, Typography, IconButton, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

const GridContainerStyles = css`
  @media(min-width: 992px) {
      width: 190px;
    }
`;

const createButton = ({ type }) => {
  switch (type) {
    case 'affiliate':
      return GridContainerStyles;
    default:
      return null;
  }
};


export const GridContainer = styled(({ color, ...otherProps }) => (
  <Grid {...otherProps} />
))`
    padding: 15px 25px;
    width: 20rem;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f3f9ff;
    }

    @media(min-width: 992px) {
      padding: 0px;
      width: 231px
    }
    ${createButton}
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 2rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 0.9rem;
    padding: 5px 10px;
  }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #C5C5C5;
  margin: 1px;
`;
