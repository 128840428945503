import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  min-width: 4.4375rem;
  display: flex;
  flex-direction: column;
`;

export const ItemTypoBox = styled(Grid)`
  cursor: pointer;
  width: 100%;
  
  &:hover {
    border-radius: 4px;
    background-color: #f3f9ff;
  }
`;

export const ItemTypo = styled(Typography)`
  color: rgba(70, 79, 91, 1);
  margin-left: 0px;
  font-size: 0.75rem;
  padding: 10px;
  font-weight: 400;
  text-transform: capitalize;
`;
