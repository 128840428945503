import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  Grid, Typography, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;

export const FlexDiv = styled('div')`
  display: flex
`;

export const BackArrowIconContainer = styled(IconButton)`
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 21.64px;

  @media(min-width: 992px) {
    margin-right: 5px;
  }
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;

`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
  @media(max-width: 991px) {
    margin-top: 20px;
  }
`;

export const ButtonsGrid = styled(Grid)`
  margin-top: 20px;

  @media(min-width: 992px) {
    justify-content: flex-end;
    margin-top: 0;
  }
`;

export const PrimaryTitle = styled(Typography)`
  color: #424242;
  font-size: 45px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 25px;
    font-weight: 500;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 38px;
  color: #606060;
  font-weight: 450;
  margin-top: 10px;

  @media(min-width: 992px) {
    font-size: 14px;
    margin-top: 0px;
  }
`;

export const OrderButton = styled(({ color, ...otherProps }) => (
  <IconButton {...otherProps} classes={{ disabled: 'disabled', label: 'label' }} />
))`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-radius: 12px;

  &.disabled {
    color: #848483;
    border: 1px solid #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    background: #235A91;
    box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
    border-radius: 5px;
    width: 178px;

    &:hover {
      background: #235A91;
    }
  }
`;

export const PlusIcon = styled(AddIcon)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const SupplierOrderImage = styled('img')`
  width: 2rem;
  margin-right: 0.5rem;

  @media(min-width: 992px) {
    width: .9rem;
  }
`;

export const ManageProductImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: .9rem;
  }
`;



const defaultStyles = css`
  background-color: #235A91;
  color: #fff;

  &:hover {
    background-color: #235A91;
  }
`;

// const OtherStyles = css`
//   background-color: none;
//   color: #424242;
//   border: 1px solid black;
//   margin-right: .9rem;

//   @media(min-width: 1440px) {
//     margin-right: 2rem;
//   }
// `;

const secondaryStyles = css`
  background-color: none;
  font-weight: 700;
  color: #303030;
  border: 1px solid #bababa;

  &.disabled {
    color: #848483;
    border: 1px solid #CCCCCB;
  }

  @media (min-width: 992px) {
    & .label {
      font-weight: 700;
    }
  }
`;

const tertiaryStyles = css`
  background-color: none;
  color: #235A91;
  border: 1px solid #235A91;

  &:hover {
    background-color: none;
  }

  &.disabled {
    color: #235A91;
    background-color: none;
    opacity: 0.5;
  }
`;

const tertiaryOrdersStyles = css`
  background-color: none;
  color: #235A91;
  border: 1px solid #235A91;

  &:hover {
    background-color: none;
  }

  &.disabled {
    color: #235A91;
    background-color: none;
    opacity: 0.5;
  }
  @media(min-width: 992px) {
    height: 32px;
    padding: 0px 25px;
    border-radius: 5px;
  }
`;

const tertiaryOrdersBtnStyles = css`
  color: #235A91;
`;

const tertiaryPrimaryTitleStyles = css`
  color: #383D43;
  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

const createButton = ({ type }) => {
  switch (type) {
    case 'secondary':
      return secondaryStyles;
    case 'tertiary':
      return tertiaryStyles;
    case 'tertiaryOrders':
      return tertiaryOrdersStyles;
    case 'tertiaryOrdersBtn':
      return tertiaryOrdersBtnStyles;
    case 'tertiaryPrimaryTitle':
      return tertiaryPrimaryTitleStyles;
    default:
      return defaultStyles;
  }
};

export const OrderButtonText = styled(({ color, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  font-size: 1.5rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 12px;
    font-weight: 700;
  }

  @media(max-width: 991px) {
    padding: 26px;
  }

  ${createButton}
`;

export const OrderCreateButton = styled(({ color, ...otherProps }) => (
  <IconButton {...otherProps} classes={{ disabled: 'disabled', label: 'label' }} />
))`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-radius: 5px;

  &.disabled {
    color: #848483;
    border: 1px solid #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 5px;
  }

  ${createButton}
`;


export const OrdersPrimaryTitle = styled(({ color, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  color: #303030;
  font-size: 2.2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 25px;
  }

  ${createButton}
`;
