import React from 'react';
import PropTypes from 'prop-types';
import {
  SupplierDetailCardHeader, SupplierDetailCardTitle, OrderDeliveryDetailInfoWrapperRow, OrderDeliveryInfoLabel,
  OrderDeliveryInfoText, DeliveryStatusText, OrderBusinessName,
  StatusColor,
  StatusText
} from './deliveryInfoCard.styles';

const DeliveryInfoCard = ({
  businessContact, businessName, status
}) => {
  const getColorGrid = (bkg, name) => (
    <DeliveryStatusText>
      <StatusColor bkg={bkg} />
      <StatusText>{name}</StatusText>
    </DeliveryStatusText>
  );

  const getStatus = () => {
    switch (status) {
      case 'PENDING_AFFILIATE_APPROVAL':
        return getColorGrid('#A904A3', 'Pending Affiliate Approval');
      case 'PENDING_FINANCE_APPROVAL':
        return getColorGrid('#00A0B5', 'Pending Finance Approval');
      case 'AWAITING_INBOUND':
        return getColorGrid('#0900B5', 'Awaiting Inbound');
      case 'COMPLETED':
        return getColorGrid('#00B588', 'Completed');
      case 'ORDER_CANCELED':
        return getColorGrid('#FF0F0F', 'Cancelled');
      default:
        return getColorGrid('#F29053', 'Pending price confirmation');
    }
  };
  return (
    <>
      <SupplierDetailCardHeader>
        <SupplierDetailCardTitle>Delivery Location</SupplierDetailCardTitle>
        {status ? getStatus() : ''}
      </SupplierDetailCardHeader>
      <OrderDeliveryDetailInfoWrapperRow>
        {businessName && (
          <OrderBusinessName data-testid="business-name">{businessName}</OrderBusinessName>
        )}
        <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
          <OrderDeliveryInfoLabel>Mobile No.:</OrderDeliveryInfoLabel>
          <OrderDeliveryInfoText>{ businessContact && businessContact.mobile_number ? businessContact.mobile_number : 'N/A' }</OrderDeliveryInfoText>
        </div>
        <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
          <OrderDeliveryInfoLabel>Email Address:</OrderDeliveryInfoLabel>
          <OrderDeliveryInfoText>{ businessContact && businessContact.primary_email_address ? businessContact.primary_email_address : 'N/A' }</OrderDeliveryInfoText>
        </div>
      </OrderDeliveryDetailInfoWrapperRow>
      <div style={{ display: 'flex', gap: '10px' }}>
        <OrderDeliveryInfoLabel>Delivery Address:</OrderDeliveryInfoLabel>
        {businessContact && (
          <OrderDeliveryInfoText>
            {`${businessContact.city ?? ''}, ${businessContact.country ?? ''}`}
          </OrderDeliveryInfoText>
        )}
      </div>
    </>
  );
};

DeliveryInfoCard.propTypes = {
  businessContact: PropTypes.instanceOf(Object),
  businessName: PropTypes.instanceOf(String),
  status: PropTypes.string
};

DeliveryInfoCard.defaultProps = {
  businessContact: {},
  businessName: '',
  status: ''
};

export default DeliveryInfoCard;
