import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden } from '@mui/material';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  ProductDetail, ProductDetailBrandNameText,
  ShowMd,
  ProductDetailItemPriceText, ProductDetailNameMainContainer,
  ProductDetailBrandTypo, MainTableRow, BodyCell,
} from '../../../cart/product-item/styles';
import {
  ImageWrapper, ProductDetailImage, ProductCounter, MobileText
} from './styles';

const ProductItem = ({
  product: {
    supposedUnitCost,
    name: brandName,
    meta: {
      image,
    },
    quantity,
    quantityRecieved
  }
}) => (
  <>
    <ShowMd container item>
      <Grid item container xs={4}>
        <ImageWrapper>
          <ProductDetailImage src={image} alt="" />
        </ImageWrapper>
      </Grid>
      <Grid item container xs={8} direction="column">
        <Grid item container>
          <ProductDetailBrandTypo>
            { brandName }
          </ProductDetailBrandTypo>
        </Grid>
        <ProductCounter>
          Quantity Ordered : &nbsp;
          { Number(quantity) }
        </ProductCounter>
        <ProductCounter>
          Quantity Received: &nbsp;
          { Number(quantityRecieved) }
        </ProductCounter>
        <Grid item container alignItems="center">
          <Grid item xs={5}>
            <MobileText>Price</MobileText>
            <ProductCounter style={{ fontWeight: '600', color: '#000' }}>
              ₦&nbsp;
              { currencyFormatter(Number(supposedUnitCost) * Number(quantityRecieved)) }
            </ProductCounter>
          </Grid>
        </Grid>
      </Grid>
    </ShowMd>
    <Hidden mdDown>
      <MainTableRow>
        <ProductDetail container>
          <ImageWrapper><ProductDetailImage src={image} alt="" /></ImageWrapper>

          <ProductDetailNameMainContainer>
            <ProductDetailBrandNameText>
              { brandName }
            </ProductDetailBrandNameText>
          </ProductDetailNameMainContainer>
        </ProductDetail>
        <BodyCell>
          <ProductCounter>
            <p style={{ color: '#464f5b', fontSize: '0.875rem', fontWeight: '500' }}>
              {Number(quantity)}
            </p>
          </ProductCounter>
        </BodyCell>
        <BodyCell>
          <ProductCounter>
            <p style={{ color: '#464f5b', fontSize: '0.875rem', fontWeight: '500' }}>
              {Number(quantityRecieved)}
            </p>
          </ProductCounter>
        </BodyCell>
        <BodyCell>
          <ProductDetailItemPriceText>
            ₦&nbsp;
            {currencyFormatter(Number(supposedUnitCost) * Number(quantityRecieved))}
          </ProductDetailItemPriceText>
        </BodyCell>
      </MainTableRow>
    </Hidden>
  </>
);

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

ProductItem.defaultProps = {
};

export default ProductItem;
