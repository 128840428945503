import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ItemGrid, ItemContent, ItemTypo, ManageOrderButton, OrderButtonText,
  DetailContent, ColorGrid, ItemGridRight, ItemGridLeft, ItemPlaceTypo
} from './returnRow.styles';
import { AFFILIATE_REORDER, CREATE_ORDER_MUTATION } from '../../../mutations/orders';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { useStateValue } from '../../../providers/stateProvider';
import FilterPopper from './filters/filterPopper';

const ReturnRow = ({ orderItem, index }) => {
  const navigate = useNavigate();

  const isLarge = useMediaQuery('(min-width:992px)');

  const {
    id, total, status, datePlaced: date, meta
  } = orderItem;
  const [action, setAction] = useState(null);
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [productReorder] = useMutation(AFFILIATE_REORDER);
  const [, dispatch] = Object.values(useStateValue());

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const datePlaced = moment(date).format('ddd, Do MMM YYYY HH:mm');
  const amount = currencyFormatter(total);
  const deliveryDate = moment(date).add(3, 'days').format('ddd, Do MMM YYYY');
  const getColorGrid = (color, bkg, name) => (
    <ColorGrid name={name} style={{ color, backgroundColor: bkg }} item container className={`mpAdmin-uat-ordersPage-${name}`}>
      {name}
    </ColorGrid>
  );

  const safeParseJSON = (string, fallback = {}) => {
    try {
      const sanitizedString = string
        .replace(/'/g, '"')
        .replace(/None/g, 'null')
        .replace(/\bTrue\b/g, 'true')
        .replace(/\bFalse\b/g, 'false');
      return JSON.parse(sanitizedString);
    } catch (error) {
      console.error(`Error parsing JSON string: ${string}`, error);
      return fallback;
    }
  };

  const getStatus = () => {
    switch (status) {
      case 'ORDER_SENT':
        return getColorGrid('#3498DB', '#E3EFFB', 'Open');
      case 'ORDER_RECEIVED':
        return getColorGrid('#008F3D', '#D6FFE7', 'Confirmed');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#F29053', 'Dispatched');
      case 'ORDER_CLOSED':
        return getColorGrid('#E74C3C', '#FDE5E3', 'Closed');
      case 'ORDER_CANCELED':
        return getColorGrid('#606060', '#FF0F0F', 'Canceled');
      default:
        return getColorGrid('#606060', '#F29053', 'N/A');
    }
  };

  const getRfqStatus = () => {
    switch (status) {
      case 'ORDER_SENT':
        return getColorGrid('#E79800', '#FFFBC5', 'Pending');
      case 'ORDER_RECEIVED':
        return getColorGrid('#008F3D', '#FFFBC5', 'Confirmed');
      default:
        return getColorGrid('#E79800', '#FFFBC5', 'Pending');
    }
  };

  const parseMeta = safeParseJSON(meta);
  const { order_type: orderType } = parseMeta;
  const isRfqOrder = orderType === 'RFQ';
  const priceUpdated = parseMeta?.price_updated || false;
  const rfqAmount = priceUpdated ? currencyFormatter(total) : getRfqStatus();

  const handleViewDetails = (_id) => {
    navigate(`/orders/${_id}/affiliate`);
  };

  const handleTrackOrder = (_id) => {
    navigate('/track-order', {
      state: { orderId: _id }
    });
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const updateCartTotal = (currentOrder) => {
    const { totalProductCount } = currentOrder || {};

    dispatch({
      type: CartActionTypes.CART_COUNT,
      payload: totalProductCount
    });
  };
  const [createOrder] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted(data) {
      updateCartTotal(data?.createOrder);
    }
  });

  const handleReorder = () => {
    productReorder({
      variables: {
        oldOrderId: id,
      }
    }).then(({ data }) => {
      const { message } = data?.productReorder ?? '';
      toast.success(message);
      createOrder();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleAction = (event, type) => {
    if (type === 'View Order Details') {
      handleViewDetails(id);
    } else if (type === 'Re-order') {
      return handleReorder();
    } else {
      handleTrackOrder(id);
    }
    handleOptions(event);
  };

  const handleButtonClick = (event) => {
    if (isRfqOrder) return navigate(`/quote-details/${id}`);
    handleAction(event, 'Re-order');
  };

  return (
    <ItemGrid container item className={`mpFe-uat-orders-cards-${index}`}>
      <ItemContent container item xs={12} md={12}>
        <ItemGridRight onClick={() => handleViewDetails(id)}>
          <DetailContent>
            <Grid container item gap={1} flexDirection="row">
              <ItemTypo>
                #
                {id}
              </ItemTypo>
              {!isRfqOrder && getStatus()}
            </Grid>
            <Grid container item gap={1} flexDirection="row">
              <ItemPlaceTypo>
                {isRfqOrder ? 'Requested on:' : 'Placed on:'}
              </ItemPlaceTypo>
              <ItemTypo>
                {datePlaced}
              </ItemTypo>
            </Grid>
            <Grid container item gap={1} flexDirection="row">
              <ItemPlaceTypo>
                {isRfqOrder ? 'Total Price:' : isLarge ? 'Estimated Delivery :' : 'Estimated Delivery Date:'}
              </ItemPlaceTypo>
              <ItemTypo>
                {isRfqOrder ? (priceUpdated ? `₦ ${rfqAmount}` : rfqAmount) : deliveryDate}
              </ItemTypo>
            </Grid>

          </DetailContent>
        </ItemGridRight>
        <ItemGridLeft>
          <DetailContent>
            {isRfqOrder ? (
              <ItemTypo>
                {priceUpdated && `N ${rfqAmount}`}
              </ItemTypo>
            ) : (
              <ItemTypo>
                {`₦ ${amount}`}
              </ItemTypo>
            )}
            <Grid container item gap={1} marginTop={2.5} flexDirection="row">
              {!isRfqOrder && (
                <ManageOrderButton type="tertiaryOrders" className="mpFe-uat-orders-create-new-order" container onClick={handleFilterButtonClick}>
                  <OrderButtonText type="tertiaryOrdersBtn">Manage Order</OrderButtonText>
                  <KeyboardArrowDownIcon />
                </ManageOrderButton>
              )}
              <button
                type="button"
                onClick={(e) => handleButtonClick(e)}
                onKeyPress={(e) => { if (e.key === 'Enter') handleButtonClick(e); }}
                style={{
                  background: 'none', border: 'none', padding: 0, cursor: 'pointer'
                }}
              >
                {getColorGrid('#235A91', '#E5EEF9', isRfqOrder ? 'View Quote' : 'Re-Order')}
              </button>
            </Grid>
          </DetailContent>
        </ItemGridLeft>
      </ItemContent>
      <FilterPopper
        filterButtonEl={filterButtonEl}
        handleStatus={handleAction}
        handleFilterButtonClick={handleFilterButtonClick}
        rowSelect
      />
    </ItemGrid>
  );
};

ReturnRow.propTypes = {
  orderItem: PropTypes.shape(Object),
  index: PropTypes.number.isRequired
};

ReturnRow.defaultProps = {
  orderItem: {},
};

export default ReturnRow;
