import gql from 'graphql-tag';

export const UPDATE_ORDER_PRODUCTS_MUTATION = gql`
  mutation updateOrderProducts(
    $cart: [ProductQuantityType]!, $deliveryLocationId: Int!, $isManualOrder: Boolean
  ) {
    updateOrderProducts(
      cart: $cart,
      deliveryLocationId: $deliveryLocationId,
      isManualOrder: $isManualOrder
    ) {
      order{
        id
        meta
        supplierorderSet {
          orderproductSet {
            id
            name
            price
            unitCost
            quantity
            savings
            alternativeSupplier{
               id
               name
            }
            supplierOrder {
              supplier {
                name
                settings
              }
            }
            product {
              id
              meta
              price
              business{
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const PLACE_ORDER_PRODUCTS_MUTATION = gql`
  mutation placeOrderProducts($cart: [ProductQuantityType]!, $deliveryLocationId: Int!, $isManualOrder: Boolean,
   $extraActions: [String], $affectedSuppliers: [String], $extraProductsMeta: [PlaceExtraProductsMetaType], $creditScoreBusiness: Int!,
   $idempotentKey: String!) {
    placeOrderProducts(
      cart: $cart,
      deliveryLocationId: $deliveryLocationId,
      isManualOrder: $isManualOrder,
      extraActions: $extraActions,
      affectedSuppliers: $affectedSuppliers,
      extraProductsMeta: $extraProductsMeta,
      creditScoreBusiness: $creditScoreBusiness
      idempotentKey: $idempotentKey
    ) {
      message
      order {
        id
      }
    }
  }
`;

export const PLACE_PO_ORDER_PRODUCTS_MUTATION = gql`
  mutation placeOrderProducts($cart: [ProductQuantityType]!, $deliveryLocationId: Int!, $isManualOrder: Boolean, $isADraft:Boolean, $orderId: Int,
   $extraActions: [String], $affectedSuppliers: [String], $extraProductsMeta: [PlaceExtraProductsMetaType], $creditScoreBusiness: Int!, $idempotentKey: String!, $rfqOrderId: String) {
    placeOrderProducts(
      cart: $cart,
      deliveryLocationId: $deliveryLocationId,
      isManualOrder: $isManualOrder,
      extraActions: $extraActions,
      affectedSuppliers: $affectedSuppliers,
      extraProductsMeta: $extraProductsMeta,
      creditScoreBusiness: $creditScoreBusiness,
      isADraft: $isADraft,
      orderId: $orderId,
      idempotentKey: $idempotentKey,
      rfqOrderId: $rfqOrderId
    ) {
      message
      order {
        id
      }
    }
  }
`;

export const PLACE_AFFILIATE_ORDER_PRODUCTS_MUTATION = gql`
  mutation placeAffiliateOrderProducts($cart: [ProductQuantityType], $businessId: Int!, $deliveryLocationId: Int!, $isManualOrder: Boolean,
    $extraActions: [String], $affectedSuppliers: [String], $extraProductsMeta: [PlaceExtraProductsMetaType], $creditScoreBusiness: Int!,
    $idempotentKey: String!) {
    placeAffiliateOrderProducts(
      cart: $cart
      businessId: $businessId,
      deliveryLocationId: $deliveryLocationId,
      isManualOrder: $isManualOrder,
      extraActions: $extraActions,
      affectedSuppliers: $affectedSuppliers,
      extraProductsMeta: $extraProductsMeta,
      creditScoreBusiness: $creditScoreBusiness,
      idempotentKey: $idempotentKey
    ) {
      message
      order {
        id
      }
    }
  }
`;

export const UPDATE_ORDER_META_MUTATION = gql`
  mutation updateOrderMeta(
    $keyValues: [ModelMetaType]!,
    $orderId: Int!,
    $isManualOrder: Boolean!
  ) {
    updateOrderMeta(
      keyValues: $keyValues,
      orderId: $orderId,
      isManualOrder: $isManualOrder
    ) {
      message
      order {
        id
        meta
      }
    }
  }
`;

export const SEND_MARKET_ORDER_TO_ERP = gql`
  mutation sendMarketOrderToErp(
    $supplierOrderId: Int
  ) {
    sendMarketOrderToErp(
      supplierOrderId: $supplierOrderId
    ) {
      message
      supplierOrder {
        meta
      }
    }
  }
`;

export const DELETE_CART_ORDER = gql`
mutation deleteCartOrder(
    $orderId: Int!
  ){
    deleteCartOrder(orderId: $orderId){
      message
    }
  }
`;

export const SAVE_CART_ORDER = gql`
mutation SaveOrderCart($comment: String!){
  saveOrderCart(comment:$comment){
    message
  }
}
`;

export const ADD_SAVE_CART_TO_CART = gql`
mutation RestoreSaveOrderCart($saveCartId: Int!) {
  restoreSaveOrderCart(saveCartId: $saveCartId) {
      message
  }
}
`;

export const SWITCH_ORDER_PRODUCT_SUPPLIER = gql`
  mutation switchOrderProductSupplier($quantity: Int!, $productId: Int!, $useAlternativeSupplier: Boolean!, $isManualOrder: Boolean) {
    switchOrderProductSupplier(
      quantity: $quantity,
      productId: $productId,
      useAlternativeSupplier: $useAlternativeSupplier,
      isManualOrder: $isManualOrder
    ) {
      order{
        id
        meta
        supplierorderSet {
          orderproductSet {
            id
            name
            price
            supplierOrder {
            supplier {
              name
            }
          }
            alternativeSupplier{
                id
                name
            }
            unitCost
            quantity
            savings
            supplierOrder {
              supplier {
                name
                settings
              }
            }
            product {
              id
              business{
                id
              }
              meta
              price
            }
          }
        }
      }
    }
  }
`;

export const CREATE_SUPPLIER_NOTE = gql`
  mutation createSupplierNote($content: String!, $supplierOrderId: Int!) {
    createSupplierNote(content: $content, supplierOrderId: $supplierOrderId) {
      message
      supplierOrder {
        id
        meta
      }
    }
  }
`;

export const DELETE_CART_PRODUCT = gql`
  mutation deleteCartProduct($orderProductIds: [String]!, $orderId: String!) {
    deleteCartProduct(orderProductIds: $orderProductIds, orderId: $orderId) {
      message
      totalProductCount
      order {
        id
        meta
        paymentSummary
      }
    }
  }
`;
