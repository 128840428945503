import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  GridContainer, ItemTypo, ItemWrapper, GridWrapper,
} from './orderDropdown.styles';
import { RenderPopper } from '../../../utils/utils';
import { useStateValue } from '../../../providers/stateProvider';
import { AUTOMATIC_ORDERING_MUTATION } from '../../../mutations/orders';
import SuppliersModel from './suppliersModel/suppliersModel';

const OrderDropdown = ({
  route,
  isAdmin,
  orderButtonEl,
  setOrderButtonEl,
  navigate,
  actionHandler
}) => {
  const open = Boolean(orderButtonEl);
  const [loading, setLoading] = useState(false);
  const [openSuppliersModal, setOpenSuppliersModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const [{ cart: { isManualOrder } }] = Object.values(useStateValue());

  const handleSuppliersModal = () => {
    setOrderButtonEl(false);
    setOpenSuppliersModal(true);
  };

  const hybrid = () => {
    if (selected.length === 1 && selected.includes('YES')) return 'YES';
    if (selected.length === 1 && selected.includes('NO')) return 'NO';
    if ((selected.length < 1) || (selected.length > 1 && selected.includes('YES') && selected.includes('NO'))) return 'ALL';
  };

  useEffect(() => { hybrid(); }, [selected]);

  const [automaticOrdering] = useMutation(AUTOMATIC_ORDERING_MUTATION);
  const handleAutomaticOrdering = (supplierIds) => {
    setLoading(true);
    let msg;
    let type;
    automaticOrdering({
      variables: {
        isManualOrder,
        supplierIds,
        hybrid: hybrid()
      }
    })
      .then(({ data }) => {
        const { message } = data?.automaticOrdering || {};
        msg = message;
        type = 'success';
      })
      .catch((error) => {
        msg = error;
        type = 'error';
      })
      .finally(() => {
        setLoading(false);
        navigate({
          pathname: '/manual-cart',
          alertMessage: msg,
          alertSeverity: type
        });
      });
  };

  const handleSelected = (e) => {
    const { value, checked } = e.target;
    const exist = selected.find((x) => x === value);
    if (checked && !exist) {
      setSelected([...selected, value]);
    }
    if (!checked && exist) {
      const newVal = selected.filter((x) => x !== value);
      setSelected(newVal);
    }
  };

  return (
    <>
      <RenderPopper
        open={open}
        anchorEl={orderButtonEl}
        popperPlacement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
        style={{ position: 'absolute', top: '0px', right: '0px' }}
        marginLeft="20px"
        marginTop=".5rem !important"
      >
        <GridWrapper container item>
          <GridContainer type="affiliate"  data-testid="create-order-individually" container item onClick={() => actionHandler('add_individually')}>
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Create individually
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
          <GridContainer type="affiliate" data-testid="create-order-by-csv" container item onClick={() => actionHandler('upload_order_csv')}>
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Upload CSV file
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </RenderPopper>
      <SuppliersModel
        open={openSuppliersModal}
        handleClose={() => setOpenSuppliersModal(false)}
        handleAutomaticOrdering={handleAutomaticOrdering}
        handleSelected={handleSelected}
        selected={selected}
      />
    </>
  );
};

OrderDropdown.propTypes = {
  route: PropTypes.instanceOf(String),
  orderButtonEl: PropTypes.instanceOf(Object),
  isAdmin: PropTypes.bool,
  navigate: PropTypes.instanceOf(Object).isRequired,
  setOrderButtonEl: PropTypes.func.isRequired,
  actionHandler: PropTypes.func.isRequired
};

OrderDropdown.defaultProps = {
  route: '',
  isAdmin: false,
  orderButtonEl: {},
};

export default OrderDropdown;
