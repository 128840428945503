import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@mui/material';
import CustomNewTextField from '../../shared/CustomNewTextField';
import CustomButton from '../../customComponents/customButton';
import { FiltersGrid } from './paymentsFilter.styles';
import { useStateValue } from '../../../providers/stateProvider';

const PaymentsFilter = ({
  handleFilterChange, handleSetFilter
}) => {
  // const status = ['PENDING', 'OVERDUE', 'PAID', 'PARTIAL PAYMENT', 'CANCELLED'];
  const [{
    user: { allowedPermissionsMap: { userPermissions } },
  }] = Object.values(useStateValue());

  const FilterFields = [
    { name: 'orderNo', label: 'Order Number' },
    { name: 'invoiceNo', label: 'Invoice Number' },
    { name: 'affiliateName', label: 'Affiliates' }
  ];

  const status = [
    { name: 'ALL', value: '' },
    { name: 'PENDING', value: 'PENDING' },
    { name: 'OVERDUE', value: 'OVERDUE' },
    { name: 'PAID', value: 'PAID' },
    // { name: 'PARTIAL PAYMENT', value: 'PARTIAL_PAYMENT' },
    { name: 'CANCELLED', value: 'CANCELLED' },
  ];

  return (
    <Grid item container xs={12}>
      <FiltersGrid container item justifyContent="flex-end">
        <CustomNewTextField
          field={{ name: 'status', label: 'Payment Status' }}
          select
          handleChange={handleFilterChange}
          style={{ width: '23%' }}
        >
          {status.map(({ name, value }) => (
            <MenuItem key={name} value={value}>
              {name}
            </MenuItem>
          ))}
        </CustomNewTextField>

        {FilterFields.map((field) => (
          <CustomNewTextField
            key={field?.name}
            field={field}
            handleChange={handleFilterChange}
            style={{ width: '23%' }}
          />
        ))}
        <CustomButton
          style={{ width: '8%', height: '2.95rem' }}
          onClick={handleSetFilter}
          disabled={!userPermissions?.includes('payment_set_filter')}
        >
          Set Filter
        </CustomButton>
      </FiltersGrid>
    </Grid>
  );
};

PaymentsFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
};

export default PaymentsFilter;
