import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  MenuPaper, MenuText, MenuBox, SelectMenu,
  MenuTitles, MenuGrid, ItemGrid,
} from './affiliateMenu.styles';
import {
  OrdersLogoIcon, TrackLogoIcon, WalletLogo, ProfileLogoIcon, UploadLogoIcon
} from '../../../assets/svgs';

const AffiliateMenu = ({ closeMenu, handleClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState('');
  const { pathname } = location;

  useEffect(() => {
    switch (pathname) {
      case '/orders':
        setActive('orders');
        break;
      case '/track-order':
        setActive('track');
        break;
      case '/uploads':
        setActive('uploads');
        break;
      case '/account-info':
        setActive('profile');
        break;
      case '/credit-wallet':
        setActive('credit');
        break;
      default:
        setActive('orders');
    }
  }, [pathname]);

  return (
    <Grid
      item
      xs={12}
      md={closeMenu ? 1.2 : 2.7}
      lg={closeMenu ? 1.2 : 2.7}
    >
      <MenuPaper elevation={0} closed={closeMenu}>
        <MenuBox>
          <MenuText>Menus</MenuText>
          <SelectMenu onClick={handleClose} />
        </MenuBox>

        <Box style={{ marginTop: '1rem' }}>
          <MenuGrid
            container
            closed={closeMenu}
            onClick={() => navigate('/orders')}
            active={active === 'orders'}
          >
            <ItemGrid item xs={closeMenu ? 12 : 1.5}>
              <OrdersLogoIcon style={{ fontSize: '1.25rem', marginTop: '3px', marginBottom: closeMenu ? '.7rem' : '0' }} />
              {closeMenu && (
                <MenuTitles>
                  Orders
                </MenuTitles>
              )}
            </ItemGrid>
            {!closeMenu && (
              <Grid item xs={10.5}>
                <MenuTitles active={active === 'orders'}>
                  Manage Orders
                </MenuTitles>
              </Grid>
            )}
          </MenuGrid>

          <MenuGrid
            container
            closed={closeMenu}
            onClick={() => navigate('/track-order')}
            active={active === 'track'}
          >
            <ItemGrid item xs={closeMenu ? 12 : 1.5}>
              <TrackLogoIcon style={{ fontSize: '1.25rem', marginTop: '3px', marginBottom: closeMenu ? '.7rem' : '0' }} />
              {closeMenu && (
                <MenuTitles>
                  Track
                </MenuTitles>
              )}
            </ItemGrid>
            {!closeMenu && (
              <Grid item xs={10.5}>
                <MenuTitles active={active === 'track'}>
                  Track Orders
                </MenuTitles>
              </Grid>
            )}
          </MenuGrid>

          <MenuGrid
            container
            closed={closeMenu}
            onClick={() => navigate('/credit-wallet')}
            active={active === 'credit'}
          >
            <ItemGrid item xs={closeMenu ? 12 : 1.5}>
              <WalletLogo style={{ fontSize: '1.25rem', marginTop: '3px', marginBottom: closeMenu ? '.7rem' : '0' }} />
              {closeMenu && (
                <MenuTitles>
                  C.Wallet
                </MenuTitles>
              )}
            </ItemGrid>
            {!closeMenu && (
              <Grid item xs={10.5}>
                <MenuTitles active={active === 'credit'}>
                  Credit Wallet
                </MenuTitles>
              </Grid>
            )}
          </MenuGrid>

          <MenuGrid
            container
            closed={closeMenu}
            onClick={() => navigate('/uploads')}
            active={active === 'uploads'}
          >
            <ItemGrid item xs={closeMenu ? 12 : 1.5}>
              <UploadLogoIcon style={{ fontSize: '1.25rem', marginTop: '3px', marginBottom: closeMenu ? '.7rem' : '0' }} />
              {closeMenu && (
                <MenuTitles>
                  Uploads
                </MenuTitles>
              )}
            </ItemGrid>
            {!closeMenu && (
              <Grid item xs={10.5}>
                <MenuTitles active={active === 'uploads'}>
                  Uploads
                </MenuTitles>
              </Grid>
            )}
          </MenuGrid>

          <MenuGrid
            container
            closed={closeMenu}
            onClick={() => navigate('/account-info')}
            active={active === 'profile'}
          >
            <ItemGrid item xs={closeMenu ? 12 : 1.5}>
              <ProfileLogoIcon style={{ fontSize: '1.25rem', marginTop: '3px', marginBottom: closeMenu ? '.7rem' : '0' }} />
              {closeMenu && (
                <MenuTitles active>
                  Profile
                </MenuTitles>
              )}
            </ItemGrid>
            {!closeMenu && (
              <Grid item xs={10.5}>
                <MenuTitles active={active === 'profile'}>
                  Profile Settings
                </MenuTitles>
              </Grid>
            )}
          </MenuGrid>
        </Box>
      </MenuPaper>
    </Grid>
  );
};

AffiliateMenu.propTypes = {
  closeMenu: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

AffiliateMenu.defaultProps = {
};
export default AffiliateMenu;
