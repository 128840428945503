import React from 'react';
import {
  Grid, Typography, Button, IconButton
 } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { Logo } from '../../../assets/svgs';


export const ActionItem = styled(Grid)`
  display: flex;
  height: 86%;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;

export const ImgContent = styled(Grid)`
  height: 100%;
  width: 100%;
  background: #F3F9FF;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 991px) {
    height: 90%;
    border-radius: 25px;
  }
`;

export const ItemTyp = styled(Typography)`
  color: #464F5B;
  font-size: 24px;
  font-weight: 700;
  @media(min-width: 992px) {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

export const ItemTypo = styled(Typography)`
  color: #464F5B;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  @media(min-width: 992px) {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

export const ItemPlaceTypo = styled(Typography)`
  color: #9A9EA6;
  margin-bottom: 20px;
  font-size: 24px;
  @media(min-width: 992px) {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
`;

export const ReqTypo = styled(Typography)`
  font-weight: 400;
  font-size: .875rem;
  color: #606060;
`;

export const DetailContainer = styled('div')`
  display: flex;
  margin-bottom: 15px;
  @media(max-width: 991px) {
    margin-bottom: 25px;
  }
`;

export const TypeImg = styled('img')`
  height: 50px;
  filter: hue-rotate(135deg) saturate(2) brightness(1.1);

  @media(min-width: 992px) {
    height: 20px;
  }
`;

export const DetailButton = styled(Button)`
  margin-top: 4rem;
  border: 1px solid #235a91;
  width: 10.313rem;
  height: 2.313rem;
  font-weight: 500;
  font-size: .75rem;
  color: #606060;
`;

export const CardLogo = styled(Logo)`
  width: 100%;
  height: 50%;
`;

export const ColorGrid = styled(Grid)`
  width: auto !important; // Remove default width
  min-width: 0; // Prevent flex overflow

  text-transform: capitalize;
  color: #606060;
  font-size: ${({ name }) => (name === 'View Quote' ? '.9rem' : '.7rem')};
  font-weight: ${({ name }) => (name === 'View Quote' ? 700 : 500)};
  justify-content: flex-start;
  padding: ${({ name }) => (name === 'View Quote' ? '6px' : '4px')};
  align-items: center;
  border-radius: 5px;
  @media(max-width: 991px) {
    font-size: 1.3rem;
    height: 40px;
  }
`;

export const ItemGrid = styled(Grid)`
  cursor: pointer;
  margin-bottom: 15px;
  min-height: 92px;
  border: 0.5px solid #E3E7EA;
  @media(max-width: 991px) {
    margin-bottom: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border: 0.5px solid #E3E7EA;
    border-radius: 20px;
  }
`;

export const ItemContent = styled(Grid)`
  padding: 10px;
  @media(min-width: 992px) {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
  @media(max-width: 991px) {
    padding: 0px 20px;
  }
`;

export const ItemGridRight = styled(Grid)`
  padding: 10px;
  @media(max-width: 991px) {
    padding: 0px 20px;
  }
`;

export const ItemGridLeft = styled(Grid)`
  padding: 10px;
  @media(max-width: 991px) {
    padding: 0px 20px;
  }
`;

export const DetailContent = styled(Grid)`
  text-align: left;
  display: block;
  // font-size: 2.5rem;
  @media(min-width: 992px) {
    text-align: right;
    // font-size: 1.8rem;
  }
`;

const tertiaryOrdersStyles = css`
  background-color: none;
  color: #235A91;
  border: 1px solid #235A91;

  &:hover {
    background-color: none;
  }

  &.disabled {
    color: #235A91;
    background-color: none;
    opacity: 0.5;
  }
  @media(min-width: 992px) {
    height: 32px;
    padding: 0px 25px;
    border-radius: 5px;
  }
  @media(max-width: 991px) {
    height: 39px;
  }
`;

const tertiaryOrdersBtnStyles = css`
  color: #235A91;
  @media(max-width: 991px) {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

const defaultStyles = css`
  background-color: #235A91;
  color: #fff;

  &:hover {
    background-color: #235A91;
  }
`;

const createButton = ({ type }) => {
  switch (type) {
    case 'tertiaryOrders':
      return tertiaryOrdersStyles;
    case 'tertiaryOrdersBtn':
      return tertiaryOrdersBtnStyles;
    default:
      return defaultStyles;
  }
};

export const ManageOrderButton = styled(({ color, ...otherProps }) => (
  <IconButton {...otherProps} classes={{ disabled: 'disabled', label: 'label' }} />
))`
  height: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-radius: 5px;

  &.disabled {
    color: #848483;
    border: 1px solid #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 5px;
  }

  ${createButton}
`;

export const OrderButtonText = styled(({ color, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  font-size: 2rem;
  font-weight: 450;

  @media(min-width: 992px) {
    font-size: 12px;
    font-weight: 700;
  }

  ${createButton}
`;

