export function updateUserSession(userData) {
  let priceFactor = 1;
  const affiliatePromos = userData?.affiliatePromos || [];
  const availablePromos = userData?.availablePromos || [];
  const promos = { affiliatePromos, availablePromos };
  const businessCanUseMastersList = userData?.businessCanUseMastersList;
  const isBusinessVirtualStore = userData?.isBusinessVirtualStore;
  const allPermissionsArray = userData?.permissions || [];
  let userPermissions = [];
  let role = '';
  let business = '';
  let businessId = '';
  let parentId = '';
  let businessUserId = '';
  let userPlatform = '';
  let isSupplyChain = false;
  let onlyBusinessProducts = false;
  let status = '';
  let walletBallance = 0;
  let paymentOverdue = ''
  if (userData?.definedUser) {
    const {
      definedUser: {
        platform,
        onlyBusinessProducts: onlyBusinessProd,
        businessUser: {
          id: busUserId,
          userPermissions: userPermissionsArray,
          business: {
            name: businessName,
            id: busId,
            policy,
            parentId: parId,
            isSupplyChain: _isSupplyChain,
            status: busStatus,
            creditWalletBalance,
            overdueInfo
          },
          role: { name: roleName },
        },
      },
    } = userData || {};
    userPermissions = userPermissionsArray || [];
    isSupplyChain = _isSupplyChain;
    role = roleName;
    onlyBusinessProducts = onlyBusinessProd;
    business = businessName;
    businessId = busId;
    parentId = parId;
    userPlatform = platform;
    businessUserId = busUserId;
    priceFactor = policy.priceFactor;
    localStorage.setItem('ogarx_business_id', businessId);
    status = busStatus;
    walletBallance = creditWalletBalance;
    paymentOverdue = overdueInfo;
  }

  return {
    session: userData?.userInfo || {},
    promos,
    priceFactor,
    isBusinessVirtualStore,
    businessCanUseMastersList,
    allowedPermissionsMap: { userPermissions, allPermissionsArray },
    role,
    parentId,
    businessName: business,
    businessId,
    businessUserId,
    platform: userPlatform,
    onlyBusinessProducts,
    isSupplyChain,
    status,
    walletBallance,
    paymentOverdue,
  };
}

export function userCanUpdateProduct(user) {
  const { parentId, allowedPermissionsMap, role } = user;
  return (role === 'chain-manager' || (parentId && allowedPermissionsMap.get('CAN_UPDATE_PRODUCT')) || !parentId);
}

export function userCanCreateProduct(user) {
  const { parentId, allowedPermissionsMap, role } = user;
  return (role === 'chain-manager' || (parentId && allowedPermissionsMap.get('CAN_CREATE_PRODUCT')) || !parentId);
}

export function userCanCreateProductBatch(user) {
  const { parentId, allowedPermissionsMap, role } = user;
  return (role === 'chain-manager' || (parentId && allowedPermissionsMap.get('CAN_CREATE_PRODUCT_BATCH')) || !parentId);
}

export function userCanUpdateProductBatch(user) {
  const { parentId, allowedPermissionsMap, role } = user;
  return (role === 'chain-manager' || (parentId && allowedPermissionsMap.get('CAN_UPDATE_PRODUCT_BATCH')) || !parentId);
}
