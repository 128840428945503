import {
  Grid, Typography, IconButton, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
    padding: 15px 25px;
    width: 28rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px 5px;
      width: 12.563rem;
    }
`;

export const IconButtonWrapper = styled(IconButton)`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 18px 23px;

  &:hover {
    background: #F3F9FF;
  }
`;

export const MenuTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 2rem;
  letter-spacing: 1px;
  margin-left: 30px;
  margin-right: 150px;
  white-space: nowrap;

  @media(min-width: 992px) {
    margin-left: 15px;
    font-size: .75rem;
    font-weight: 500;
    color: #464f5b;
    line-height: 15px;
  }
`;

export const AccountTypo = styled(Typography)`
  font-size: .75rem;
  font-weight: 600;
  color: #383d43;
  margin-bottom: 5px;
`;

export const AccountBox = styled(Box)`
  height: 1.625rem;
  border-bottom: 0.5px solid rgba(227, 231, 234, 1);
  margin-left: 20px;
`;
