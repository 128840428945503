import {
  Typography, Box, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Content = styled(Paper)`
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    height: 32.5rem;
    border: 0.5px solid rgba(227, 231, 234, 1);
    box-shadow: 0px 6px 48px 0px rgba(48, 48, 48, 0.03);

    @media(max-width: 991px) {
      padding: 30px 30px;
      margin: 40px 0 3rem;
      border-radius: 15px;
      height: 50rem;
    }
`;

export const ProductTypo = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
  color: #383d43;

  @media(max-width: 991px) {
    padding: 0 0 1.5rem 0;
    font-size: 2rem;
    font-weight: 600;
  }
`;

export const TypoBox = styled(Box)`
  height: 40px;
  border-bottom: 0.5px solid rgba(227, 231, 234, 1);

  @media (max-width: 991px) {
    height: 80px;
    margin-bottom: 1.5rem;
  }
`;

export const DetailHeader = styled(Typography)`
  color: #7a8b96;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 15px;

  @media (max-width: 991px) {
    font-size: 26px;
    font-weight: 400;
  }
`;

export const DetailTypo = styled(Typography)`
  color: #383d43;
  font-weight: 500;
  font-size: 1rem;
  margin-top: .4rem;

  @media (max-width: 991px) {
    font-size: 26px;
    font-weight: 500;
  }
`;
