import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../customComponents/customButton';
import {
  CDialog, DialogTitleText, CircularProgressLoader, TextFieldBox, CTextField,
  GridWrapper, CloseIcon, FieldHeader,
  FieldGrid, HelperText
} from './addPaymentDialog.styles';
import SuccessDialog from '../../mpCustomerCategories/indevidualCategory/successDialog';
import ReturnSelectField from './returnSelectField';
import { UPDATE_AFFILIATE_INVOICE } from '../../../mutations/affiliates';
import currencyFormatter from '../../shared/currencyFormatter';
import { AmountWarningIcon } from '../../../assets/svgs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AddPaymentDialog = ({
  open, close, payment, refetch
}) => {
  const initialState = {
    paymentType: '', amount: '', date: '', reference: '',
  };

  const { total, id, amountPaid } = payment;

  const initialErrorState = {
    paymentTypeError: false,
    emailError: false,
    reasonError: false,
    categoryError: false
  };

  const amounrDue = total - amountPaid;

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [amountInput, setAmountInput] = useState('');

  const [successDialog, setSuccessDialog] = useState(false);
  const [updateInvoicePayment] = useMutation(UPDATE_AFFILIATE_INVOICE);

  const {
    paymentType, date, amount, reference
  } = state;
  const { paymentTypeError, dateError, amountError } = errorState;

  const textFieldHeader = [
    {
      name: 'paymentType', type: 'text',
      label: 'Payment Type', placeholder: 'Select payment type', options: ['Payment', 'Rebate', 'Return', 'Tax Offset'], error: paymentTypeError, helperText: 'Payment Type is required'
    },
    {
      name: 'date', type: 'date',
      label: 'Date', placeholder: 'Enter date', error: dateError, helperText: 'Date is required'
    },
    {
      name: 'amount', type: 'number',
      label: 'Amount', placeholder: 'Enter the amount',
      error: amountError, helperText: 'Amount is required',
      infoText: state.amount > amounrDue
        ? 'Invoice will be overpaid'
        : state.amount < amounrDue
          ? `Amount due for this invoice will be ₦${currencyFormatter(Number(amounrDue) - Number(state.amount))}`
          : state.amount === ''
            ? ''
            : 'Invoice will be fully paid',
    },
    {
      name: 'reference', type: 'text',
      label: 'Reference (optional)', placeholder: 'Enter the amount of days'
    },
  ];

  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleCreate = () => {
    setAmountInput(amount);
    setLoading(true);
    const paymentInfo = {
      paymentReference: reference, date, amount, paymentType
    };
    const variables = {
      invoiceId: id,
      paymentInfo,
    };
    updateInvoicePayment({ variables })
      .then(({ data }) => {
        setSuccessDialog(true);
        const { message } = data?.updateInvoicePayment || {};
        toast.success(message);
        setState(initialState);
        refetch();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const paymentHandler = () => {
    if (paymentType === '') {
      toast.error('Payment type is required');
    } else if (!date) {
      toast.error('Date is required');
    } else if (amount === '') {
      toast.error('Valid amount is required');
    } else {
      handleCreate();
    }

    return setErrorState({
      ...errorState,
      paymentTypeError: paymentType === '',
      dateError: !date,
      amountError: amount === '',
    });
  };

  const toggleSuccessModal = () => {
    handleSuccessDialog();
    close();
  };

  const textField = (field) => {
    const {
      name: fieldName, shrink, type, placeholder, helperText, infoText, error
    } = field;
    const _value = state[fieldName];
    if (fieldName === 'paymentType') {
      return (
        <ReturnSelectField
          field={field}
          value={_value}
          handleChange={handleChange}
          helperText={error && helperText}
        />
      );
    }
    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          value={_value}
          size="large"
          name={fieldName}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
          helperText={error && helperText}
          fullWidth
          required
          infoText={infoText}
          className={`mpAdmin-uat-customerCategory-add-category-${fieldName}`}
          InputProps={{
            style: { height: '4rem', fontSize: '1rem', borderRadius: '.6rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '.9rem', textAlign: 'center' },
            shrink
          }}
        />
        {infoText
          ? (
            <HelperText
              style={{
                color:
                infoText === 'Invoice will be fully paid'
                  ? '#008F3D'
                  : infoText === 'Invoice will be overpaid'
                    ? '#E74C3C'
                    : '#E79800',
                display: 'flex',
                gap: '0.4rem',
                marginTop: '0.3rem'
              }}
            >
              <AmountWarningIcon
                style={{
                  fontSize: '1rem',
                }}
              />
              <span>{infoText}</span>
            </HelperText>
          )
          : ''}
      </TextFieldBox>
    );
  };
  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ marginTop: '2rem', padding: '0rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              Add Payment
            </DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {textFieldHeader.map((fields) => (
            <FieldGrid xs={12} key={fields} item>
              <FieldHeader>
                {fields.label}
              </FieldHeader>
              {textField(fields)}
            </FieldGrid>
          ))}
        </Grid>

        <DialogActions>
          <CustomButton
            type="tertiary"
            style={{
              width: '8rem', height: '3.7rem', marginRight: '1.2rem', border: '1px solid #235A91'
            }}
            onClick={handleCloseDialog}
            className="mpAdmin-uat-customerCategory-add-category-cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton
            style={{ width: '8rem', height: '3.7rem' }}
            onClick={paymentHandler}
            className="mpAdmin-uat-customerCategory-add-category-save"
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : (
              'Submit'
            ) }
          </CustomButton>
        </DialogActions>
      </GridWrapper>
      <SuccessDialog
        openConfirmation={successDialog}
        handleClose={toggleSuccessModal}
        title="Payment Successful!"
        discreption={`You have successfully made a payment of ₦ ${amountInput}`}
      />
    </CDialog>
  );
};

AddPaymentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  payment: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func.isRequired
};

export default AddPaymentDialog;
