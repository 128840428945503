import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import {
  GridWrapper, Image, AffiliateName, LogoWrapper, ImageWrapper, PharmacyName, ImageContainer, Camera, CDialog, DialogTitleContainer,
  DialogTitleWrapper, DialogTitleText, CloseIcon, DialogCancelButton, DialogActionButtonText, DialogOkButton, DialogsubText, DeleteIcon, Camera2
} from './initialImage.styles';
import { CircularProgressLoader } from './styles';
import CropImageContainer from './profileImage/cropImageContainer';
import ConfirmDialog from '../shared/confirmDialog';

const InitialImage = ({
  image, name, contactName, loading, handleFile, files, handleFinalImage, openImageDialog,
  handleCloseImageDialog, handleDeleteImage, handleImageSave
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleToggle = () => {
    setOpenDialog(false);
    setOpenModal(true);
  };

  const handleEditing = () => {
    setOpenDialog(true);
  };

  const handleDelete = () => {
    handleDeleteImage();
    setOpenModal(false);
  };

  const handleUpload = () => {
    setOpenDialog(false);
    // This function doesn't return JSX anymore
    // Instead, it triggers the file input programmatically
    const fileInput = document.getElementById('file-input');
    fileInput.click();
  };
  return (

    <GridWrapper container item>
      <LogoWrapper>
        <ImageWrapper container>
          <ImageContainer>
            <Image
              src={image}
              alt="image"
            />
            <Camera onClick={handleEditing} />
          </ImageContainer>
        </ImageWrapper>
      </LogoWrapper>
      <input
        type="file"
        id="file-input"
        onChange={(e) => handleFile(e.target.files)}
        accept="image/*"
        multiple
        hidden
        className="mpAdmin-uat-manageAffiliateDetailsPage-uploadImage"
      />
      <CDialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        filesLimit={1}
      >
        <Grid container>
          <GridWrapper container item xs={12}>
            <DialogTitleContainer id="alert-dialog-slide-title">
              <Grid container>
                <DialogTitleWrapper container xs={9}>
                  <DialogTitleText>
                    Profile Photo
                  </DialogTitleText>
                  <DialogsubText>Please upload a photo of yourself. Then crop and adjust it to perfection.</DialogsubText>
                </DialogTitleWrapper>
                <Grid item container xs={3} justifyContent="flex-end" style={{ marginTop: '.5rem' }}>
                  <CloseIcon onClick={handleCloseDialog} />
                </Grid>
              </Grid>
            </DialogTitleContainer>
            <DialogContent>
              <ImageWrapper style={{ padding: 0 }} container>
                <ImageContainer>
                  <Image
                    src={image}
                    alt="image"
                  />
                </ImageContainer>
              </ImageWrapper>
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleToggle}
              >
                <DialogActionButtonText>
                  <DeleteIcon />
                  Delete Photo
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleUpload}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    <Camera2 />
                    Upload Photo
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>

        </Grid>
      </CDialog>

      <CDialog
        open={openImageDialog}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        filesLimit={1}
      >
        <Grid container>
          <GridWrapper container item xs={12}>
            <DialogTitleContainer id="alert-dialog-slide-title">
              <Grid container>
                <DialogTitleWrapper container xs={10}>
                  <DialogTitleText>
                    Profile Photo
                  </DialogTitleText>
                  <DialogsubText>Crop and adjust it to perfection..</DialogsubText>
                </DialogTitleWrapper>
                <Grid item container xs={2} justifyContent="flex-end" style={{ marginTop: '.5rem' }}>
                  <CloseIcon onClick={handleCloseImageDialog} />
                </Grid>
              </Grid>
            </DialogTitleContainer>
            <DialogContent>
              <CropImageContainer
                files={files}
                handleFile={handleFile}
                handleFinalImage={handleFinalImage}
                handleSave={handleImageSave}
                loading={loading}
              />
            </DialogContent>
          </GridWrapper>

        </Grid>
      </CDialog>

      <ConfirmDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        title="Delete Photo?"
        desc="Hi, are you sure you want to delete this photo?"
        options={['Cancel', 'Yes, Delete Photo']}
        buttonAction={handleDelete}
      />
    </GridWrapper>
  );
};
InitialImage.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  handleFile: PropTypes.func.isRequired,
  files: PropTypes.string.isRequired,
  handleFinalImage: PropTypes.func.isRequired,
  openImageDialog: PropTypes.bool.isRequired,
  handleCloseImageDialog: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleImageSave: PropTypes.func.isRequired,

};
export default InitialImage;
