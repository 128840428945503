import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useMediaQuery } from '@mui/material';
import { RenderPopper } from '../../../../utils/utils';
import {
  GridWrapper, InfoText, ItemTypo, ItemTypoBox
} from './filterPopper.styles';

const filterPopper = ({
  filterButtonEl, handleFilterButtonClick, status, handleStatus, rowSelect
}) => {
  const open = Boolean(filterButtonEl);
  const isLarge = useMediaQuery('(min-width:1200px)');

  const columns = rowSelect
    ? ['View Order Details', 'Track Order']
    : ['Open Orders', 'Dispatched Orders', 'Closed Orders'];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <RenderPopper
      open={open}
      anchorEl={filterButtonEl}
      onClickAway={handleFilterButtonClick}
      popperPlacement="bottom-end"
      marginTop=".5rem !important"
      width={isLarge ? '160px !important' : '190px !important'}
    >
      <GridWrapper container>
        {/* <InfoText>Filter by Order Status</InfoText> */}
        {columns?.map((column) => (
          <ItemTypoBox
            key={column}
            item
            alignItems="center"
            onClick={(e) => handleStatus(e, column)}
          >
            {/* <Grid item xs={1}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ color: '#FCED50' }}
                checked={column === status}
              />
            </Grid> */}
            <ItemTypo item xs={12}>
              {column}
            </ItemTypo>
          </ItemTypoBox>
        ))}
      </GridWrapper>
    </RenderPopper>
  );
};

filterPopper.propTypes = {
  filterButtonEl: PropTypes.shape(Object),
  handleFilterButtonClick: PropTypes.func.isRequired,
  rowSelect: PropTypes.bool
};

filterPopper.defaultProps = {
  filterButtonEl: {},
  rowSelect: false
};

export default filterPopper;
