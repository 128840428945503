import {
  BottomNavigation, BottomNavigationAction, Typography, Grid,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: -40px 40px 80px -8px #0000003D;
  height: 12rem;
  display: flex;
  justify-content: space-around;
  z-index: 10;
`;

export const DynamicAction = styled(BottomNavigationAction)`
  color: ${({ selected }) => (selected ? '#235A91' : '#606060')};
  font-weight:${({ selected }) => (selected ? '600' : '400')};
  & .MuiBottomNavigationAction-label {
    font-size: 2.3rem;
    white-space: nowrap;
    padding-top: 5px;
  }

  .MuiSvgIcon-root {
    color: ${({ selected }) => (selected ? '#235A91' : '#606060')};
    fill: ${({ selected }) => (selected ? '#235A91' : '#606060')};
    font-size: 4rem;
    font-weight: 400;


  }
`;

export const MoreStyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: -40px 40px 80px -8px #0000003D;
  height: 37.5rem ;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 0 1rem;
  padding-bottom: 2rem;
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
`;

export const MoreMenuGrid = styled(Grid)`
  padding: 1.5rem;
`;

export const MoreMenuText = styled(Typography)`
  font-size: 1.75rem;
  font-weight: 500;
  color: #464f5b;
`;

export const MoreHeaderText = styled(Typography)`
  font-size: 2rem;
  font-weight: 500;
  color: #383d43;
`;

export const CloseText = styled(Typography)`
  font-size: 1.75rem;
  font-weight: 500;
  color: #235a91;
`;

export const MoreBox = styled(Box)`
  border-bottom: 1px solid #e3e7ea;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 1.5rem;
  margin-bottom: 2rem;
`;
