import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const StatusesGrid = styled(Grid)`
  padding: .5rem 0;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.6rem;
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ active }) => (active ? '4px solid #235A91' : '4px solid #EEEEEE')};
  width: ${({ width }) => (width)};
  margin-top: ${({ marginTop }) => (marginTop)}
`;

export const Typo = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  color: ${({ active }) => (active ? '#303030' : '#C0C0C1')};
  display: flex;
  align-items: center;
`;

const myOrderStyles = css`
  background-color: none;

  &:hover {
    background-color: none;
  }

  &.disabled {
    color: #E3E7EA;
    background-color: none;
    opacity: 0.5;
    border: 1px solid #E3E7EA;
  }
`;

const createButton = ({ type }) => {
  switch (type) {
    case 'myOrder':
      return myOrderStyles;
    default:
      return null;
  }
};

export const OrderButtonText = styled(({ color, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  font-size: 1.5rem;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 12px;
    font-weight: 700;
  }

  ${createButton}
`;


export const OrderButton = styled(({ color, ...otherProps }) => (
  <IconButton {...otherProps} classes={{ disabled: 'disabled', label: 'label' }} />
))`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border-radius: 10px;
  color: ${({ active }) => (active ? '#235A91' : '#A6B3BA')};
  border: ${({ active }) => (active ? '1px solid #235A91;' : '1px solid #E3E7EA;')};

  &.disabled {
    color: #848483;
    border: 1px solid #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 40px;
    border-radius: 5px;
  }
  

  ${createButton}
`;

