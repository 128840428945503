import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../utils/utils';
import {
  GridWrapper, ItemTypo, ItemTypoBox
} from './filterPopper.styles';

const FilterPopper = ({
  filterButtonEl, handleFilterButtonClick, handleStatus
}) => {
  const open = Boolean(filterButtonEl);

  const columns = ['paid', 'pending', 'overdue'];

  return (
    <RenderPopper
      open={open}
      anchorEl={filterButtonEl}
      onClickAway={handleFilterButtonClick}
      creditWallet
      popperPlacement="bottom-end"
      width="5rem !important"
      marginTop=".5rem !important"
    >
      <GridWrapper container>
        {columns?.map((column) => (
          <ItemTypoBox
            key={column}
            item
            alignItems="center"
            onClick={() => handleStatus(column)}
          >
            <ItemTypo item xs={12}>
              {column}
            </ItemTypo>
          </ItemTypoBox>
        ))}
      </GridWrapper>
    </RenderPopper>
  );
};

FilterPopper.propTypes = {
  filterButtonEl: PropTypes.shape(Object),
  handleFilterButtonClick: PropTypes.func.isRequired,
  handleStatus: PropTypes.func.isRequired,
};

FilterPopper.defaultProps = {
  filterButtonEl: {},
};

export default FilterPopper;
