import React from 'react';
import PropTypes from 'prop-types';
import HomeIcon from '@mui/icons-material/Home';
import UpArrow from '../../../assets/images/dashboard/UpArrow.png';
import DownArrow from '../../../assets/images/dashboard/DownArrow.png';
import {
  StatisticWrapper, StatisticGrid, StatisticHeader, DiffImage,
  StatisticContent, IconImage, StatisticPaper, DiffGrid
} from './statisticCard.styles';

export default function StatisticCard({
  statistic, admin, affiliate
}) {
  const {
    icon, title, value, diff
  } = statistic;

  return (
    <StatisticPaper elevation={2}>
      <StatisticWrapper admin={admin} diff={diff} affiliate={affiliate} container item>
        {!affiliate && (
          <IconImage src={icon} />
        )}
        <StatisticGrid item>
          <StatisticHeader item affiliate={affiliate}>
            {title}
          </StatisticHeader>
          <StatisticContent item affiliate={affiliate}>
            {value}
          </StatisticContent>
          {diff ? (
            <DiffGrid item diff={diff}>
              {diff * 1 < 0
                ? <DiffImage src={DownArrow} />
                : <DiffImage src={UpArrow} />}
              {`${diff} %`}
            </DiffGrid>
          ) : ''}
        </StatisticGrid>
        {affiliate && (
          <IconImage src={icon} />
        )}
      </StatisticWrapper>
    </StatisticPaper>
  );
}

StatisticCard.propTypes = {
  statistic: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  value: PropTypes.number,
  admin: PropTypes.bool,
  affiliate: PropTypes.bool
};
StatisticCard.defaultProps = {
  statistic: {},
  icon: HomeIcon,
  value: 0,
  admin: false,
  affiliate: false
};
