import { JSONParse } from '../../utils/json';

const totals = (item) => item.reduce((previous, current) => previous + current, 0);

export const parseCustomerFields = (obj) => {
  const {
    id: customerId,
    name,
    dateCreated,
    meta,
    contacts,
    loyaltyPoint,
    storeCredit,
    createdBy,
    business: { id: businessId }
  } = obj || {};
  const { username } = createdBy || {};
  const metaObj = JSONParse(meta);
  const contactsObj = JSONParse(contacts);
  const {
    address_line_one: addressLine1,
    address_line_two: addressLine2,
    mobile_number: mobile,
    email
  } = contactsObj || {};
  const {
    region, city, country, photo, notes
  } = metaObj || {};
  const address = [addressLine1, addressLine2, region, city, country];
  return {
    customerId,
    name,
    dateCreated,
    address,
    mobile,
    email,
    businessId,
    photo,
    notes,
    loyaltyPoint,
    storeCredit,
    username
  };
};

export const parseCategoryFields = (obj) => {
  const {
    id: categoryId, categoryName, loyaltyWeighting, meta
  } = obj || {};
  const metaObj = JSONParse(meta);
  const {
    pricing_value: pricingValue,
    pricing_type: pricingType,
    reorder_point: reorderPoint,
    reorder_unit: reorderUnit,
    reorder_max: reorderMax
  } = metaObj || {};
  return {
    categoryId,
    categoryName,
    pricingValue,
    loyaltyWeighting,
    pricingType,
    reorderPoint,
    reorderUnit,
    reorderMax
  };
};

export const parseSupplierFields = (obj) => {
  const {
    id: supplierId,
    name,
    logo,
    dateCreated,
    meta,
    contacts,
    settings,
    supplierorderSet,
    business: { id: businessId }
  } = obj || {};
  const bizId = localStorage.getItem('ogarx_business_id');
  const role = localStorage.getItem('oga_user_role');
  const isOgaAdmin = role === 'oga-pharmacy-admin';
  const metaObj = JSONParse(meta);
  const contactsObj = JSONParse(contacts);
  const settingsObj = JSONParse(settings);
  const {
    address_line_one: addressLine1,
    address_line_two: addressLine2,
    mobile_number: mobile,
    primary_email: email
  } = contactsObj || {};
  const {
    region,
    city,
    country,
    provider_promo: providerPromo
  } = metaObj || {};
  const address = [addressLine1, addressLine2, region, city, country];
  const { payment_term: terms, markup = 1 } = settingsObj || {};
  const businessOrders = supplierorderSet.filter(({ order }) => {
    if (isOgaAdmin) return order?.businessUser?.role?.name === role;
    return order?.business?.id === bizId;
  });
  const orderVolume = totals(
    businessOrders.map(({ productCount }) => productCount)
  );
  const orderValue = totals(businessOrders.map(({ total }) => total));

  return {
    supplierId,
    name,
    logo,
    dateCreated,
    address,
    terms,
    orderVolume,
    orderValue,
    mobile,
    email,
    businessId,
    providerPromo,
    markup
  };
};

export const cherryPickOrder = (data) => {
  const ordersWithIncompletePayment = [];
  const ordersWithoutPayment = [];
  data.supplier.supplierorderSet.forEach((supplierOrder) => {
    const { paymentSet, orderproductSet } = supplierOrder;
    if (paymentSet.length) {
      const orderAmount = orderproductSet.reduce(
        (orderProductA, orderProductB) => orderProductA
          + orderProductB.unitCost * (orderProductB.quantityRecieved || 0),
        0
      );
      const amountPaid = paymentSet.reduce(
        (paymentA, paymentB) => paymentA + paymentB.amount,
        0
      );
      if (amountPaid < orderAmount) {
        ordersWithIncompletePayment.push({
          ...supplierOrder,
          paymentOutstanding: orderAmount - amountPaid
        });
      }
    } else {
      ordersWithoutPayment.push(supplierOrder);
    }
  });

  return { ordersWithIncompletePayment, ordersWithoutPayment };
};

export const goToModule = (navigate, switchRole, allowedPermissionsMap, controlAlert, identifier) => {
  let role = localStorage.getItem('oga_user_role');
  const returnRoute = (name) => {
    switch (name) {
      case 'oga-pharmacy-admin':
        return '/dashboard';
      case 'affiliate-admin':
        return '/new-order';
      case 'supplier-admin':
        return '/orders-supplier';
      case 'store-manager':
        return '/sc-dashboard';
      default:
        return null;
    }
  };
  const identifiedRoute = (identifier_) => {
    if (
      identifier_ === 'oga'
      && ['affiliate-admin', 'oga-pharmacy-admin', 'supplier-admin'].indexOf(
        role
      ) !== -1
    ) {
      return returnRoute(role);
    }
    if (identifier_ === 'erp' && (role === 'store-manager' || role === 'chain-manager')) {
      return '/sc-dashboard';
    }
    if (identifier_ === 'setup') {
      return '/settings/business';
    }
    if (identifier_ === 'help-desk') {
      return '/help-desk';
    }
    if (identifier_ === 'account-info') {
      return '/account-info';
    }
    return null;
  };
  const { userPermissions } = allowedPermissionsMap;
  const knownRoute = identifiedRoute(identifier);
  if (knownRoute) {
    navigate(knownRoute);
  } else if (userPermissions?.includes('CAN_SWITCH_ROLE')) {
    switchRole({
      variables: { oldRole: role }
    })
      .then(
        ({
          data: {
            switchRole: { message, newRole }
          }
        }) => {
          let autoSelectModule = identifier;
          if (newRole) {
            localStorage.setItem('oga_user_role', newRole);
            autoSelectModule = newRole === 'affiliate-admin' ? 'oga' : 'erp';
            role = newRole;
          }
          controlAlert({
            open: true,
            message,
            alertSeverity: 'success'
          });
          navigate(identifiedRoute(autoSelectModule));
        }
      )
      .catch((err) => {
        controlAlert({
          open: true,
          message: `${err?.message}`,
          alertSeverity: 'error'
        });
      });
  } else {
    controlAlert({
      alertSeverity: 'error',
      open: true,
      message: 'Module not available, coming soon...'
    });
  }
};

export const numberFormater = (num) => {
  const formatter = new Intl.NumberFormat('en');
  return formatter.format(num);
};
