import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomButton from '../../../../customComponents/customButton';
import currencyFormatter from '../../../../shared/currencyFormatter';
import {
  CardHeaderGrid, CardHeaderText, GrandTotalGrid, CardGridText,
  CardTitleGridText
} from './paymentSummary.styles';

const PaymentSummary = ({
  orderTotal, serviceFee, deliveryFee, groupOrderDiscount, grandTotal, promo, paymentHandler, loading,
}) => {
  const getTotalPromoPrice = () => 0;
  const totalPromo = promo ?? getTotalPromoPrice();
  return (
    <>
      <CardHeaderGrid item container>
        <CardHeaderText>Payment Summary</CardHeaderText>
      </CardHeaderGrid>
      <Grid item container>
        <CardTitleGridText item xs={8}>Order Total</CardTitleGridText>
        <CardGridText item xs={4}>
          {`₦ ${currencyFormatter(orderTotal)}`}
        </CardGridText>
        <CardTitleGridText item xs={8}>Delivery Fee</CardTitleGridText>
        <CardGridText item xs={4}>
          {`₦ ${currencyFormatter(deliveryFee)}`}
        </CardGridText>
        <CardTitleGridText item xs={8}>Service Charge</CardTitleGridText>
        <CardGridText item xs={4}>
          {`₦ ${currencyFormatter(serviceFee)}`}
        </CardGridText>
        <CardTitleGridText item xs={8}>Total Savings</CardTitleGridText>
        <CardGridText item xs={4}>
          {`₦ ${currencyFormatter(groupOrderDiscount < 0 ? 0 : groupOrderDiscount)}`}
        </CardGridText>
      </Grid>
      <GrandTotalGrid item container>
        <CardGridText item xs={8}>
          <b>Grand Total</b>
        </CardGridText>
        <CardGridText item xs={4}>
          <b>{`₦ ${currencyFormatter(grandTotal - totalPromo)}`}</b>
        </CardGridText>
      </GrandTotalGrid>
      <CustomButton
        header
        // disabled={!(reconciledOrdersPrices > 0) || (localStoragePrices > 0)}
        style={{ width: '100%' }}
        onClick={paymentHandler}
      >
        {loading ? 'Loading...' : 'Pay Now'}
      </CustomButton>
    </>
  );
};

PaymentSummary.propTypes = {
  orderTotal: PropTypes.number,
  serviceFee: PropTypes.number,
  deliveryFee: PropTypes.number,
  groupOrderDiscount: PropTypes.number,
  grandTotal: PropTypes.number,
  paymentHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  promo: PropTypes.number
};

PaymentSummary.defaultProps = {
  orderTotal: 0,
  serviceFee: 0,
  deliveryFee: 0,
  groupOrderDiscount: 0,
  grandTotal: 0,
  loading: false,
  promo: 0
};

export default PaymentSummary;
