import React from 'react';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  RepayDialog, DialogTitleContainer, TextBox, CopyButton,
  RepayTypo, StyledCloseIcon, RepayTitle, RepaySubTitle,
  AccountBox, AccountTypo,
} from './repayDialog.styles';
import { OgaHome, CopyIcon } from '../../assets/svgs';

const PaymentRepayDialog = ({ open, handleClose }) => {
  const handleCopyAccount = (id) => {
    const accountType = id === 1 ? '1016914552 | Banyi Lifestores Limited - Zenith Bank' : '0091333945 | Banyi Lifestores Limited - Access Bank';
    navigator.clipboard.writeText(accountType)
      .then(() => {
        toast.success('Account details copied to clipboard!');
      })
      .catch(() => {
        toast.error('Failed to account details!');
      });
  };

  return (
    <RepayDialog
      open={open}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      filesLimit={1}
    >
      <DialogTitleContainer id="alert-dialog-slide-title">
        <StyledCloseIcon onClick={handleClose} />
        <RepayTypo>Repay Credit</RepayTypo>
      </DialogTitleContainer>

      <TextBox>
        <Grid container>
          <Grid item xs={1}>
            <OgaHome style={{ fontSize: '2.375rem' }} />
          </Grid>
          <Grid item xs={11} style={{ }}>
            <RepayTitle>OGApharmacy Bank Accounts</RepayTitle>
            <RepaySubTitle>
              Complete your payment and reachout to your account handler for payment confirmation and  order processing.
            </RepaySubTitle>

            <AccountBox>
              <AccountTypo>
                1016914552 | Banyi Lifestores Limited - Zenith Bank
              </AccountTypo>
              <CopyButton onClick={() => handleCopyAccount(1)}>
                <CopyIcon style={{ fontSize: '0.8275rem' }} />
                Copy
              </CopyButton>
            </AccountBox>

            <AccountBox>
              <AccountTypo>
                0091333945 | Banyi Lifestores Limited - Access Bank
              </AccountTypo>
              <CopyButton onClick={() => handleCopyAccount(2)}>
                <CopyIcon style={{ fontSize: '0.8275rem' }} />
                Copy
              </CopyButton>
            </AccountBox>
          </Grid>
        </Grid>
      </TextBox>
    </RepayDialog>
  );
};

PaymentRepayDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

PaymentRepayDialog.defaultProps = {
};

export default PaymentRepayDialog;
