import React from 'react';
import PropTypes, { array } from 'prop-types';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  ResultBox, ContentGrid, CPagination, RowBox, SelectBox
} from './tablePagination.styles';

const TablePagination = ({
  pageCount, setPageCount, pageNumber, setPageNumber, total,
  hideRowsPerPage, displayShowResult, options, handleUpdateProducts
}) => {
  const number = pageNumber === 1 ? pageNumber : ((pageNumber - 1) * pageCount) + 1;
  const count = pageNumber * pageCount > total ? total : pageCount * pageNumber;

  const handleChange = (event, page) => {
    setPageNumber(page);
    if (handleUpdateProducts) {
      handleUpdateProducts();
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setPageCount(parseInt(event.target.value));
  };

  return (
    <ContentGrid item container>
      {!hideRowsPerPage
        ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RowBox>Rows Per Page </RowBox>
            <SelectBox
              value={pageCount}
              onChange={handleChangeRowsPerPage}
            >
              {options?.length ? (
                options.map((option) => (
                  <option key={option}>{option}</option>
                ))
              ) : (
                <>
                  <option>5</option>
                  <option>10</option>
                  <option>20</option>
                </>
              )}
            </SelectBox>
          </div>
        ) : <div />}
      {displayShowResult && (
        <ResultBox>
          {`Showing Results for ${number}-${count} of ${total?.toLocaleString()}`}
        </ResultBox>
      )}
      <CPagination
        onChange={handleChange}
        count={Math.ceil(total / pageCount)}
        variant="text"
        color="primary"
        shape="rounded"
        page={pageNumber}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
            {...item}
          />
        )}
      />
    </ContentGrid>
  );
};

TablePagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  hideRowsPerPage: PropTypes.bool,
  displayShowResult: PropTypes.bool,
  options: PropTypes.string,
  handleUpdateProducts: PropTypes.func
};

TablePagination.defaultProps = {
  hideRowsPerPage: false,
  displayShowResult: false,
  options: '',
  handleUpdateProducts: () => null
};

export default TablePagination;
