import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide,
  Table, TableBody, TableContainer, TableHead, TableRow, useMediaQuery
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import _lodash from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  CLOSE_ORDER, CREATE_NOTE, DISPATCH_MORE_ORDER, DISPATCH_ORDER_PRODUCTS_MUTATION, UPDATE_ORDER_STATUS,
  XERO_SYNC_ORDER
} from '../../../mutations/orders';
import { JSONParse } from '../../../utils/json';
import {
  CircularProgressLoader
} from '../../auth/login.styles';
import MainContent from '../../customComponents/mainContent';
import ReturnRow from './returnRow';
import Stepper from './stepper';
import SupplierItems from './supplierItems';
import {
  BackArrowIconContainer, CartGridContainerHeader, ChangeOutletRow, DeliveryAddressCardHeaderSubTitle,
  DeliveryAddressCardHeaderTitle, DialogActionButtonText, DialogCancelButton, DialogOkButton,
  KeyboardArrowLeft, OrderButton, OrderButtonText, OutletContactLine, OutletHeader, OutletInfoContainer,
  PageTitleContainer, PageTitleSubText, PageTitleText, SelectOutletText,
} from '../../cart/cart.styles';
import {
  CDialog, CardWrapper, CartGridContainer, CloseStyled, DeliveryAddressCard, DeliveryAddressCardHeader,
  DialogContentStyled, DispatchButton, HeaderCell, HeaderSubText, HeaderText, NoteButton, NoteCardHeader,
  NoteContainer, NoteDialog, NoteTextField, OverviewFooter, SaveNoteButton, SortButton, SupplierDetailCardWrapper,
  ViewAllNotes, Sync, SyncBox
} from './dispatch.styles';
import {
  DeliveryAddressCardSection, SaveChangesText,
} from './dispatchContainer.styles';
import LabelPrintDialog from './labelPrint/labelPrintDialog';
import OrderOverview from './orderOverview';

import { DropDown } from '../../../assets/svgs';
import { OgaToken } from '../../../graphql/token';
import { handleDownloadUtil } from '../../../utils/utils';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { useStateValue } from '../../../providers/stateProvider';
import SuccessDialog from '../../rolesAndPermissions/users/successDialog';
import OrderInfoCard from '../../shared/orderInfoCard/orderInfoCard';
import ConfirmDialog from '../shared/confirmDialog';
import AllNoteRow from './AllNotesRow';
import ActionPopper from './actionPopper';
import NoteRow from './notes';
import { CancelButton, OverviewFooterText } from './orderOverview.styles';
import ReturnSelectField from './returnSelectField';
import SharedConfirmDialog from '../../shared/confirmDialog';
import SharedSuccessDialog from '../../shared/successDialog';
import InvoiceLogs from './invoiceLogs/invoiceLogs';

const INITIAL_STATE = {
  loading: false,
  businessId: null,
  addNewDeliveryLocation: true,
  selectedDeliveryLocation: '',
  name: '', // businessName
  logo: '', // businessLogo
  businessDateCreated: null,
  businessDateDelivered: null,
  deliveryLocations: [],
  cart: [],
  placeLoading: false,
  showPlaceOrderDialog: false,
  showErrorDialog: false,
  showPrintOrderDialog: false,
  showCancelOrderDialog: false,
  dateCreated: null,
  serviceFee: 0,
  deliveryFee: 0,
  isOrderDispatched: false,
  done: false,
  sortStatuses: {},
  orderStatus: '',
  freshlyClosed: false,
  logisticsId: '',
  dispatchErrors: [],
  noteText: '',
  notes: [],
  cancellationReason: '',
  returnStatus: false,
  business: {},
  cancelOrderLoading: false,
  saveChangesLoading: false,
  sortLoading: false,
  orderType: ''
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
export default function Dispatch({
  businessDeliveryLocations, orderData, refetch, ordId, orderProducts, orderProductsTotalNumber, pageCount, pLoading,
  pageNumber, setPageCount, setPageNumber, searchText, setSearchText
}) {
  const navigate = useNavigate();
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [state, setState] = useState(INITIAL_STATE);
  const [activeStep, setActiveStep] = useState(0);
  const [quantityRecieve, setQuantityRecieve] = useState([]);
  const [noteState, setNoteState] = useState(false);
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [dispatchMoreLoading, setDispatchMoreLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [cancelOrderType, setCancelOrderType] = useState('');
  const [qtyConfirmed, setQtyConfirmed] = useState([]);
  const [viewProduct, setViewProduct] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [nextPage, setNextPage] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [dialogText, setDialogText] = useState({});
  const [nextCount, setNextCount] = useState('');
  const [isQuantityIssue, setIsQuantityIssue] = useState(false);

  const handleNextCount = (countNum) => {
    if (quantityRecieve.length > 0) {
      setOpenConfirmDialog(true);
      setNextCount(countNum);
      return;
    }
    setPageCount(countNum);
  };

  const handleNextPage = (pageValue) => {
    const backButton = pageValue === 'back';
    if (quantityRecieve.length > 0) {
      setOpenConfirmDialog(true);
      setNextPage(backButton ? 'back' : pageValue);
      return;
    }

    if (backButton) {
      navigate('/orders-admin');
    } else {
      setPageNumber(pageValue);
    }
  };

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const [dispatchOrder] = useMutation(DISPATCH_ORDER_PRODUCTS_MUTATION);
  const [closeOrder] = useMutation(CLOSE_ORDER);
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);
  const [dispatchMoreOrder] = useMutation(DISPATCH_MORE_ORDER);
  const [createNote] = useMutation(CREATE_NOTE);
  const [syncOrder] = useMutation(XERO_SYNC_ORDER);

  const reformatJson = (str) => JSONParse(str.replace(/\"*([\w&.+×'÷=/-<>\[\]()])+\"/g, (m, _) => m.replaceAll("'", '`')));

  //   utility function to build up the page
  const handleChangeDeliveryLocation = (event) => {
    const { value } = event.target;
    setState({ ...state, selectedDeliveryLocation: value });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setLoading = (loading) => {
    setState({
      ...state,
      loading
    });
  };

  const handleMetaStatusUpdate = (id) => {
    setState((_state) => {
      const { sortStatuses } = _state;
      const sort = sortStatuses[id];
      sortStatuses[id] = !sort;
      return ({ ..._state, sortStatuses });
    });
  };

  // update the condition dropdown
  const handleChangeCondition = (id, value, key) => {
    const {
      cart
    } = state;
    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved && key !== 'recieved') return;

    setState((prevState) => ({
      ...prevState,
      cart: prevState.cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, [key]: value, quantityRecieved: value ? cartItem.quantity : cartItem.quantityRecieved }
        : cartItem))
    }));
    state.cart.forEach((cartItem) => {
      if (cartItem.id === id) {
        if (quantityRecieve.some((item) => item.productId === id)) {
          const items = quantityRecieve.map(({ quantityReceived, productId }) => {
            if (productId === id && value) return ({ productId, quantityReceived: cartItem.quantity });
            if (productId === id) return ({ productId, quantityReceived: 0 });
            return ({ productId, quantityReceived });
          });
          setQuantityRecieve(items);
        } else {
          setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: !cartItem.quantityRecieved ? cartItem.quantity : 0 }]);
        }
      }
    });
  };

  const handleQuantitySentUpdate = (id) => {
    state.cart.forEach((cartItem) => {
      if (cartItem.id === id) {
        if (quantityRecieve.some((item) => item.productId === id)) {
          const items = quantityRecieve.map(({ productId, quantityReceived, batches }) => {
            if (productId === id) return ({ productId, quantityReceived: cartItem.quantityRecieved, ...(batches && { batches }) });
            return ({ productId, quantityReceived, ...(batches && { batches }) });
          });
          setQuantityRecieve(items);
        } else {
          setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: cartItem.quantityRecieved }]);
        }
      }
    });
  };

  // update the received checkbox
  const handleChangeProductQuantityInputValue = (id, value) => {
    const { cart } = state;
    let newValue = 0;

    if (Number(value)) newValue = Number(value);

    const existingCartItem = cart.find((item) => item.id === id);
    if (newValue > (existingCartItem?.quantityConfirmed || existingCartItem?.initialQuantity)) return toast.error('Quantity Sent cannot be more than Quantity Confirmed');
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        item.quantityRecieved = newValue;
        return item;
      }
      return item;
    });
    setState({ ...state, cart: newCart });

    handleQuantitySentUpdate(id);
  };

  const handleIncrementProductQuantityInCart = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved) return;
    // eslint-disable-next-line consistent-return
    if (existingCartItem?.quantityRecieved >= existingCartItem?.quantityConfirmed) return toast.error('Quantity Sent cannot be more than Quantity Confirmed');
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        item.quantityRecieved += 1;
        return item;
      }
      return item;
    });

    handleQuantitySentUpdate(id);

    setState({ ...state, cart: newCart });
  };

  const handleDecrementProductQuantityInCart = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === id
    );
    if (!existingCartItem.recieved) return;

    if (existingCartItem.quantity > 1) {
      const newCart = state.cart.map((item) => {
        if (item.id === id) {
          if (item?.quantityRecieved > 0) { item.quantityRecieved -= 1; }
          return item;
        }
        return item;
      });
      setState({ ...state, cart: newCart });
      handleQuantitySentUpdate(id);
    }
  };

  const handleBatchSelection = (id, value) => {
    if (!value.length) return;
    const {
      cart
    } = state;
    let qtyReceived = 0;
    if (value?.length) {
      qtyReceived = value?.reduce((acc, b) => acc + b.qtyToSell, 0);
    }
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === id
        ? { ...cartItem, quantity: qtyReceived }
        : cartItem))
    });
    if (quantityRecieve.some((item) => item.productId === id)) {
      const items = quantityRecieve.map(({ quantityReceived, productId, batches }) => {
        if (productId === id) return ({ productId, quantityReceived: qtyReceived, batches: value });
        return ({ productId, quantityReceived, batches });
      });
      setQuantityRecieve(items);
    } else {
      setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: qtyReceived, batches: value }]);
    }
  };

  const handleQuantityConfirmedUpdate = (id) => {
    state.cart.forEach((cartItem) => {
      if (cartItem.id === id) {
        if (qtyConfirmed.some((item) => item.productId === id)) {
          const items = qtyConfirmed.map(({ productId, quantityConfirmed }) => {
            if (productId === id) return ({ productId, quantityConfirmed: cartItem.quantityConfirmed });
            return ({ productId, quantityConfirmed });
          });
          setQtyConfirmed(items);
        } else {
          setQtyConfirmed([...qtyConfirmed, { productId: id, quantityConfirmed: cartItem.quantityConfirmed }]);
        }
      }
    });
  };

  const handleConfirmedQtyInputValue = (id, value) => {
    let newValue = 0;
    const {
      cart
    } = state;

    if (Number(value)) {
      newValue = Number(value);
    }

    const existingCartItem = cart.find((item) => item.id === id);

    if (newValue > existingCartItem?.initialQuantity) return toast.error('Quantity Confirmed Cannot be more than Quantity Ordered');
    // eslint-disable-next-line consistent-return
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        item.quantityConfirmed = newValue;
        item.quantityRecieved = newValue;
        return item;
      }
      return item;
    });
    setState({ ...state, cart: newCart });
    handleQuantityConfirmedUpdate(id);
    handleQuantitySentUpdate(id);
  };

  const handleQtyConfirmedIncrement = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    // eslint-disable-next-line consistent-return
    if (existingCartItem?.quantityConfirmed >= existingCartItem?.initialQuantity) return toast.error('Quantity Confirmed cannot be more than Quantity Ordered');
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        if (item.quantityConfirmed === item.quantityRecieved) {
          item.quantityConfirmed += 1;
          item.quantityRecieved = item.quantityConfirmed;
        }

        if (item.quantityConfirmed > item.quantityRecieved) {
          item.quantityConfirmed += 1;
        }
      }
      return item;
    });
    setState({ ...state, cart: newCart });
    handleQuantityConfirmedUpdate(id);
    handleQuantitySentUpdate(id);
  };

  const handleQtyConfirmedDecrement = (id) => {
    const {
      cart
    } = state;
    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved) return;
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        if (item?.quantityConfirmed > 0) {
          if (item.quantityConfirmed === item.quantityRecieved) {
            item.quantityConfirmed -= 1;
            item.quantityRecieved = item.quantityConfirmed;
          }
          if (item.quantityConfirmed > item.quantityRecieved) {
            item.quantityConfirmed -= 1;
          }
        }
        return item;
      }
      return item;
    });
    setState({ ...state, cart: newCart });
    handleQuantityConfirmedUpdate(id);
    handleQuantitySentUpdate(id);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const toggleViewProduct = () => {
    setViewProduct(!viewProduct);
  };
  // utility function to retrieve the products
  const retrieveProducts = (orderSet) => {
    let products = [];
    const filterOrder = orderSet?.filter((orderProductObj) => {
      const { status } = reformatJson(orderProductObj?.meta) || '';
      if (status !== 'DISPATCHED') return orderProductObj;
      return null;
    });
    const orderproductSet = filterOrder?.map(
      (orderproduct) => {
        const orderProduct = { ...orderproduct };
        const updatedOrderProduct = {
          ...orderProduct,
          orderSupplierSetId: orderSet?.id
        };
        return updatedOrderProduct;
      }
    );
    products.push(...orderproductSet);

    products = products.map((p) => {
      const {
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        quantityConfirmed,
        orderSupplierSetId,
        meta: orderproductSetMeta,
        availableQtyForSorting,
        supplierOrder: {
          status: supplierOrderStatus,
          supplier: {
            name: supplierName,
          }
        },
        product: {
          meta,
        }
      } = p;

      return {
        ...new Product(p.product), // We might need some real product information, but it was placed above so that the product Id doesnt conflict with the intended orderProductId
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        orderSupplierSetId,
        supplier: supplierName,
        meta: reformatJson(meta),
        recieved: quantityRecieved > 0,
        condition: 'Good',
        initialQuantity: quantity,
        supplierOrderStatus,
        quantityConfirmed,
        availableQtyForSorting,
        orderproductSetMeta: reformatJson(orderproductSetMeta?.replace(/False/g, "'false'").replace(/True/g, "'true'"))
      };
    });
    setState((prev) => ({
      ...prev,
      cart: products,
    }));
  };

  const supplierOrderSetSort = (supplierOrders) => {
    const { sortStatuses } = state;
    supplierOrders.forEach((supplierOrder) => {
      const { id, meta } = supplierOrder;
      let { sorted } = reformatJson(meta);
      sorted = sorted ? sorted.localeCompare('true') : false;
      if (sorted === -1) sorted = false;
      else if (sorted === 0) sorted = true;
      sortStatuses[id] = sorted;
      setState((prev) => ({
        ...prev,
        sortStatuses,
      }));
    });
  };

  const routeStatuses = (metaStatus) => {
    switch (metaStatus) {
      case 'ORDER_RECEIVED':
      case 'APPROVING':
      case 'ORDER_APPROVED':
      case 'ORDER_SENT':
        return null;
      case 'ORDER_SORTED':
      case 'PREPARING_FOR_DISPATCH':
        return handleNext();
      case 'RETURNING':
      case 'ORDER_DISPATCHED':
      case 'ORDER_QUEUED_FOR_DISPATCH':
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        return setState({
          ...state,
          isOrderDispatched: true,
        });
      case 'ORDER_CLOSED':
        setActiveStep((prevActiveStep) => prevActiveStep + 3);
        return setState({
          ...state,
          isOrderDispatched: true,
          done: true
        });
      case 'ORDER_CANCELED':
        setActiveStep((prevActiveStep) => prevActiveStep);
        return setState({
          ...state,
          isOrderDispatched: true,
          done: true
        });
      default:
        toast.error('Order has not been recieved yet');
        return setTimeout(() => {
          navigate('/orders-admin');
        }, 1500);
    }
  };

  // initialize the state with props
  useEffect(() => {
    if (orderData?.business) {
      const {
        business, dateCreated, id, meta,
        metaStatus, paymentSummary, logisticsId: trackingId, returned
      } = orderData || {};
      setQuantityRecieve([]);

      const { id: businessId, name, logo } = business || {};

      const {
        delivery_location_id: deliveryLocationId, notes, cancellation_reason: cancellationReason,
        order_type: ordType
      } = reformatJson(orderData.meta);
      if (ordType !== 'RFQ') routeStatuses(metaStatus);
      const defaultDeliveryLocation = businessDeliveryLocations.find((bo) => String(bo.id) === String(deliveryLocationId));
      const { returned_status: returnStatus, xero_status: xeroStatus } = reformatJson(meta);
      const returnStat = (returnStatus && returnStatus === 'COMPLETED' && returned === true) && true;

      const {
        service_fee: serviceFee, delivery_fee: deliveryFee
      } = reformatJson(paymentSummary);

      setState((prev) => ({
        ...prev,
        id,
        businessId,
        deliveryLocations: businessDeliveryLocations || [],
        selectedDeliveryLocation: defaultDeliveryLocation?.name,
        name,
        logo,
        businessDateCreated: moment(dateCreated).format('DD/MM/YYYY'),
        businessDateDelivered: moment(dateCreated).add(2, 'days').format('DD/MM/YYYY'),
        dateCreated,
        serviceFee,
        deliveryFee,
        orderStatus: metaStatus,
        trackingId,
        notes,
        cancellationReason,
        returnStatus: returnStat,
        business,
        xeroStatus,
        orderType: ordType
      }));

      retrieveProducts(orderProducts);
    }
  }, [orderData, businessDeliveryLocations]);

  //   utility function to build up the page
  const getDeliveryLocationOption = () => {
    const optionsBox = { name: 'deliverTo', label: 'Deliver To', options: [] };
    const { deliveryLocations } = state;
    deliveryLocations.forEach((deliveryLocation) => {
      optionsBox.options.push(deliveryLocation.name);
    });
    return optionsBox;
  };

  //   utility function to build up the page
  const getSelectedDeliveryLocationInfo = () => {
    const { selectedDeliveryLocation, deliveryLocations } = state;
    return deliveryLocations.find((deliveryLocation) => deliveryLocation.name === selectedDeliveryLocation);
  };

  //   utility function to build up the page
  const renderSelectDeliveryLocationInfo = () => {
    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    if (selectedDeliveryLocationInfo) {
      let { contacts } = selectedDeliveryLocationInfo;
      try {
        contacts = reformatJson(contacts);
      } catch (e) {
        return null;
      }
      return (
        <OutletInfoContainer style={{ background: 'none' }}>
          <OutletHeader>Delivery Address</OutletHeader>
          <br />
          <OutletContactLine>{ contacts.address_line_1 }</OutletContactLine>
          <OutletContactLine>
            { contacts.city }
            ,
            { contacts.country }
          </OutletContactLine>
        </OutletInfoContainer>
      );
    }
    return null;
  };

  const handleOrderStatus = (status) => {
    const { id } = state;
    setState((prev) => ({
      ...prev, sortLoading: true,
    }));
    const combinedArray = quantityRecieve.map((receivedItem) => {
      const confirmedItem = qtyConfirmed.find((confirmed) => confirmed.productId === receivedItem.productId);
      const combinedItem = {
        productId: receivedItem.productId,
        quantityReceived: receivedItem.quantityReceived,
        ...(confirmedItem && { quantityConfirmed: confirmedItem.quantityConfirmed })
      };
      if (receivedItem.batches) {
        combinedItem.batches = receivedItem.batches;
      }
      return combinedItem;
    });

    updateOrderStatus({
      variables: {
        orderId: id,
        status,
        changes: combinedArray,
        idempotentKey: String(uuid())
      }
    }).then(({ data }) => {
      const { message } = data?.updateOrderStatus ?? '';
      toast.success(message);
      setState((prev) => ({
        ...prev, orderStatus: status, sortLoading: false,
      }));
      handleNext();
    }).catch((err) => {
      setState((prev) => ({
        ...prev, sortLoading: false,
      }));
      toast.error(err?.message);
    });
  };

  const handleSaveOrdersChanges = () => {
    const { id } = state;

    const combinedArray = quantityRecieve.map((receivedItem) => {
      const confirmedItem = qtyConfirmed.find((confirmed) => confirmed.productId === receivedItem.productId);
      const combinedItem = {
        productId: receivedItem.productId,
        quantityReceived: receivedItem.quantityReceived,
        ...(confirmedItem && { quantityConfirmed: confirmedItem.quantityConfirmed })
      };
      if (receivedItem.batches) {
        combinedItem.batches = receivedItem.batches;
      }
      return combinedItem;
    });

    if (!combinedArray?.length) return;
    setState((prev) => ({
      ...prev, saveChangesLoading: true,
    }));

    updateOrderStatus({
      variables: {
        orderId: id,
        status: 'ORDER_RECEIVED',
        changes: combinedArray,
        idempotentKey: String(uuid())
      }
    }).then(({ data }) => {
      const { message } = data?.updateOrderStatus ?? '';
      toast.success(message);
      setState((prev) => ({
        ...prev, saveChangesLoading: false,
      }));
      setQuantityRecieve([]);
    }).catch((err) => {
      toast.error(err?.message);
      setState((prev) => ({
        ...prev, saveChangesLoading: false,
      }));
    });
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };
  // on cancel order
  const handleCancelOrder = () => {
    const { id, business } = state;
    setState((prev) => ({
      ...prev, cancelOrderLoading: true,
    }));
    updateOrderStatus({
      variables: {
        orderId: id,
        status: cancelOrderType,
        changes: [],
        idempotentKey: String(uuid())
      }
    }).then(() => {
      toggleSuccessModal();
      setOpenModal(false);
      toast.success('Order successfully cancel');
      setState((prev) => ({
        ...prev, orderStatus: 'ORDER_CANCELED', cancelOrderLoading: false
      }));
      if (cancelOrderType === 'ORDER_CANCELED_REPLACE_ORDER') {
        localStorage.setItem('oga_ordering_for_affiliate', JSON.stringify(business));
      }
      setTimeout(() => {
        navigate('/orders-admin');
      }, 1500);
    }).catch((err) => {
      toast.error(err?.message);
      setState((prev) => ({
        ...prev, cancelOrderLoading: false,
      }));
    });
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleAction = (event, type) => {
    if (type === 'replace') {
      setOpenModal(true);
      setCancelOrderType('ORDER_CANCELED_REPLACE_ORDER');
    } else if (type === 'changed mind') {
      setCancelOrderType('ORDER_CANCELED_AFFILIATE_CHANGED_MIND');
      setOpenModal(true);
    }
    handleOptions(event);
  };

  const handleCloseModal = () => {
    setAction(false);
  };
  // on submit
  /**
   * use this interface to mark an order as ready to be dispatched.
  */
  const handleDispatchOrder = async () => {
    try {
      const { id } = state;
      setLoading(true);

      const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();

      if (!selectedDeliveryLocationInfo?.id) {
        setLoading(false);
        return toast.error('Delivery location Id is not set');
      }

      const mutationVariables = {
        orderId: id,
        deliveryLocationId: +selectedDeliveryLocationInfo.id,
        idempotentKey: String(uuid())
      };

      dispatchOrder({
        variables: mutationVariables
      }).then(({ data: { dispatchOrder: dispOrder } }) => {
        if (dispOrder && dispOrder.failed && dispOrder.failed.length) {
          const dispatchErrs = dispOrder?.failed.map((failed) => JSONParse(failed));
          setState({
            ...state,
            dispatchErrors: dispatchErrs,
            showPlaceOrderDialog: false,
            showErrorDialog: true,
          });
          throw Error(dispOrder?.message);
        }
        toast.success('Order Ready For Dispatch');
        setState({
          ...state,
          orderStatus: 'ORDER_QUEUED_FOR_DISPATCH',
          isOrderDispatched: true,
          showPlaceOrderDialog: false
        });
        handleNext();
      }).catch((err) => {
        toast.error(err?.message);
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message);
    }
  };

  const handleReverseDispatch = () => {
    setState((prev) => ({
      ...prev,
      orderStatus: 'ORDER_RECEIVED',
      showErrorDialog: false
    }));
    setActiveStep(0);
  };

  const handleCloseOrder = () => {
    const { id } = state;
    closeOrder({
      variables: {
        orderId: id
      }
    }).then(() => {
      toast.success('Order successfully closed');
      setState({
        ...state,
        orderStatus: 'ORDER_CLOSED',
        freshlyClosed: true,
        isOrderDispatched: true,
        done: true
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleOpenPlaceOrderDialog = () => {
    const {
      isOrderDispatched, done, freshlyClosed
    } = state;
    if (isOrderDispatched && !done) {
      return handleCloseOrder();
    }
    if (isOrderDispatched && done && freshlyClosed) {
      return navigate('/orders-admin');
    }
    if (isOrderDispatched && done) {
      setActiveStep(0);
      return setState((_state) => ({
        ..._state,
        freshlyClosed: false,
        isOrderDispatched: false,
        done: false
      }));
    }
    setViewProduct(false);
    return setState({
      ...state,
      showPlaceOrderDialog: true
    });
  };

  const token = localStorage.getItem('oga_rest_token');

  const handleOpenPrintOrderDialog = async () => {
    try {
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download_invoice', token, `${ordId}.pdf`);
    } catch (err) {
      toast.error('Invoice download failed');
      console.error('Invoice download failed:', err);
    }
  };



  const handleCancelOrderDialog = (e) => {
    handleOptions(e);
  };

  const handleReturnOrder = async () => {
    let products = [];
    const filterOrder = orderProducts.filter((orderProductObj) => {
      if (orderProductObj?.quantityRecieved) return orderProductObj;
      return null;
    });
    const orderproductSet = filterOrder?.map(
      (orderproduct) => {
        const orderProduct = { ...orderproduct };
        orderProduct.orderSupplierSetId = orderProducts?.supplierOrder?.id ?? '';
        return orderProduct;
      }
    );
    products.push(...orderproductSet);

    products = products.map((p) => {
      const {
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        orderSupplierSetId,
        meta: orderproductSetMeta,
        supplierOrder: {
          status: supplierOrderStatus,
          supplier: {
            name: supplierName,
          }
        },
        product: {
          meta,
        }
      } = p;

      return {
        ...new Product(p.product), // We might need some real product information, but it was placed above so that the product Id doesnt conflict with the intended orderProductId
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        orderSupplierSetId,
        supplier: supplierName,
        meta: reformatJson(meta),
        recieved: quantityRecieved > 0,
        condition: 'Good',
        initialQuantity: quantity,
        supplierOrderStatus,
        orderproductSetMeta: reformatJson(orderproductSetMeta?.replace(/False/g, "'false'").replace(/True/g, "'true'"))
      };
    });
    navigate('/return-order', {
      state: { ...state, cart: products }
    });
  };

  const handleDispatchMoreItems = async () => {
    const { id: orderId } = state;
    setDispatchMoreLoading(true);

    try {
      const { data } = await dispatchMoreOrder({
        variables: { orderId }
      });

      let checkForOrders;
      const { supplierorderSet, message } = data?.dispatchMoreOrder ?? '';
      supplierorderSet.forEach((result) => {
        checkForOrders = result?.orderproductSet.some((order) => {
          const { status } = reformatJson(order?.meta) || '';
          return status !== 'DISPATCHED';
        });
      });

      if (!checkForOrders) {
        setDispatchMoreLoading(false);
        return toast.error('All items have been dispatched');
      }

      setState((prev) => ({ ...prev, isOrderDispatched: false }));
      toast.success(message);
      setActiveStep(0);
      setDispatchMoreLoading(false);
    } catch (err) {
      toast.error(err?.message);
      setDispatchMoreLoading(false);
    }
  };

  const handleProformaInvoice = async () => {
    try {
      const { id: orderId } = state;
      await handleDownloadUtil(OgaToken.SERVER_LINK, 'download_proforma_with_product_qty_xlsx', token, orderId);
    } catch (err) {
      toast.error('Proforma download failed');
      console.error('Invoice download failed:', err);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setState({ ...state, noteText: value });
  };

  const handleSaveNote = () => {
    const { id: orderId, noteText } = state;
    const created = moment();
    createNote({
      variables: {
        orderId,
        content: noteText,
        created: created.toString()
      }
    }).then(({ data }) => {
      const { message } = data?.createNote ?? '';
      toast.success(message);
      setNoteState(false);
      setState({ ...state, noteText: '' });
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };
  const handleAddNote = () => {
    setNoteState(!noteState);
  };
  const handleToggleNotes = () => {
    setOpen(!open);
  };
  const handleNote = () => {
    handleToggleNotes();
    handleAddNote();
  };

  const renderActiveComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <SupplierItems
            state={state}
            handleChangeCondition={handleChangeCondition}
            handleIncrement={handleIncrementProductQuantityInCart}
            handleDecrement={handleDecrementProductQuantityInCart}
            handleChangeProductQuantity={handleChangeProductQuantityInputValue}
            handleMetaStatusUpdate={handleMetaStatusUpdate}
            quantityRecieve={quantityRecieve}
            handleSearch={handleSearch}
            handleCancelOrderDialog={handleCancelOrderDialog}
            handleBatchSelection={handleBatchSelection}
            handleConfirmedQtyInputValue={handleConfirmedQtyInputValue}
            handleQtyConfirmedIncrement={handleQtyConfirmedIncrement}
            handleQtyConfirmedDecrement={handleQtyConfirmedDecrement}
            qtyConfirmed={qtyConfirmed}
            setPageNumber={setPageNumber}
            setPageCount={setPageCount}
            pageCount={pageCount}
            pageNumber={pageNumber}
            orderProductsTotalNumber={orderProductsTotalNumber}
            pLoading={pLoading}
            searchText={searchText}
            handleNextPage={handleNextPage}
            handleNextCount={handleNextCount}
          />
        );
      case 1:
      case 2:
        return (
          <OrderOverview
            state={state}
            handleOpenPrintOrderDialog={handleOpenPrintOrderDialog}
            handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
            handleCancelOrderDialog={handleCancelOrderDialog}
            handleReturnOrder={handleReturnOrder}
            handleBack={handleBack}
            activeStep={activeStep}
            handleDispatchMoreItems={handleDispatchMoreItems}
            dispatchMoreLoading={dispatchMoreLoading}
            toggleViewProduct={toggleViewProduct}
            viewProduct={viewProduct}
            orderProductsTotalNumber={orderProductsTotalNumber}

          />
        );
      default:
        return (
          <OrderOverview
            state={state}
            handleOpenPrintOrderDialog={handleOpenPrintOrderDialog}
            handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
            handleCancelOrderDialog={handleCancelOrderDialog}
            handleReturnOrder={handleReturnOrder}
            handleBack={handleBack}
            activeStep={activeStep}
            handleDispatchMoreItems={handleDispatchMoreItems}
            dispatchMoreLoading={dispatchMoreLoading}
            toggleViewProduct={toggleViewProduct}
            viewProduct={viewProduct}
            orderProductsTotalNumber={orderProductsTotalNumber}
          />
        );
    }
  };

  const handleNavigation = () => {
    if (nextCount !== '') {
      setPageCount(nextCount);
    }

    if (nextPage !== '') {
      if (nextPage === 'back') {
        navigate('/orders-admin');
      } else {
        setPageNumber(nextPage);
      }
    }
    setNextPage('');
    setNextCount('');
    setOpenSuccessDialog(false);
  };

  const handleDialogActions = () => {
    setOpenConfirmDialog(false);
    setOpenSuccessDialog(true);
    setTimeout(() => handleNavigation(), 3000);
  };

  const handleNextWithSave = () => {
    handleSaveOrdersChanges();
    setDialogText({
      title: 'Changes Have Been Saved!',
      discreption: 'Hi Pharm, unsaved changes have been saved'
    });
    handleDialogActions();
  };

  const handleNextWithoutSave = () => {
    setDialogText({
      title: 'Changes Will Not Be Saved!',
      discreption: 'Hi Pharm, the changes you just made will not be saved'
    });
    handleDialogActions();
  };

  const headers = [
    'PID', 'Product Name', 'Qty Requested', 'Qty in Stock',
  ];

  const noteIndex = noteState ? 1 : 3;
  const { xeroStatus } = state;

  const handleSyncOrder = () => {
    const { id } = state;
    syncOrder({
      variables: {
        orderId: +id,
        idempotentKey: String(uuid())
      }
    }).then(({ data }) => {
      const { message } = data?.syncOrderToXero ?? '';
      toast.success(message);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleCartQuantity = () => {
    const { cart } = state;
    const copyCart = cart.map((item) => {
      item.supplierName = item.supplier ? item.supplier.name : '';
      return item;
    });
    const supplierCategories = _lodash.groupBy(copyCart, 'supplierName');
    const hasQuantityIssue = Object.entries(supplierCategories).some(([key, value]) => {
      const checkQuantity = () => (
        value?.filter((p) => p.quantity > 0).some((prod) => {
          if (prod.recieved && +prod.availableQtyForSorting < +prod.quantityRecieved) {
            toast.error('Quantity Sent cannot be more than Quantity in Stock');
            return true;
          }
          return false;
        })
      );
      return checkQuantity();
    });
    return hasQuantityIssue;
  };

  useEffect(() => {
    setIsQuantityIssue(handleCartQuantity());
  }, [state.cart]);

  // main return of the component
  return (
    <MainContent>
      <CartGridContainer style={{ paddingBottom: '10rem' }}>
        <BackArrowIconContainer onClick={() => handleNextPage('back')}>
          <KeyboardArrowLeft />
          back
        </BackArrowIconContainer>
        {state?.orderStatus === 'ORDER_CANCELED'
          ? (
            <CartGridContainerHeader>
              <PageTitleText>{state.cancellationReason === 'replace_order' ? 'Cancelled Order - Replace Order' : 'Cancelled Order - Affiliate Changed Mind'}</PageTitleText>
              <PageTitleSubText>View summary</PageTitleSubText>
            </CartGridContainerHeader>
          ) : (
            <>
              <Stepper activeStep={activeStep} />
              <CartGridContainerHeader container md={12}>
                <PageTitleContainer>
                  <PageTitleText>Order Detail</PageTitleText>
                  <PageTitleSubText>View Order summary</PageTitleSubText>
                </PageTitleContainer>
                {(!xeroStatus && isSmall) && (
                  <SyncBox onClick={handleSyncOrder}>
                    <Sync fontSize="small" />
                  </SyncBox>
                )}
                <Grid item container md={7} style={{ justifyContent: 'space-between' }}>
                  <Grid item container md={!xeroStatus ? 11 : 12} style={{ justifyContent: 'space-between' }}>
                    {(activeStep === 0) && (
                      <>
                        <OrderButton
                          onClick={() => handleProformaInvoice()}
                          disabled={
                            state.orderStatus === 'ORDER_SENT' || state.orderStatus === 'APPROVING'
                            || state.orderStatus === 'ORDER_APPROVED' || state.orderType === 'RFQ'
                          }
                        >
                          <SaveChangesText>Generate Proforma</SaveChangesText>
                        </OrderButton>
                        <OrderButton
                          onClick={() => handleSaveOrdersChanges()}
                          disabled={
                            state.orderStatus === 'ORDER_SENT' || state.saveChangesLoading
                            || state.orderStatus === 'APPROVING' || state.orderStatus === 'ORDER_APPROVED'
                            || state.orderType === 'RFQ'
                          }
                        >
                          <SaveChangesText>{state.saveChangesLoading ? 'Loading...' : 'Save Changes'}</SaveChangesText>
                        </OrderButton>
                        <OrderButton
                          onClick={() => handleOrderStatus('ORDER_SORTED')}
                          disabled={
                            !userPermissions?.includes('manage_orders_complete_sorting')
                            || state.orderStatus === 'ORDER_SENT' || state.orderStatus === 'APPROVING'
                            || state.orderStatus === 'ORDER_APPROVED' || state.sortLoading
                            || state.orderType === 'RFQ' || isQuantityIssue
                          }
                        >
                          <OrderButtonText>{state.sortLoading ? 'Loading...' : 'Complete Sorting'}</OrderButtonText>
                        </OrderButton>
                      </>
                    )}
                  </Grid>
                  {(!xeroStatus && !isSmall) && (
                    <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <SyncBox onClick={handleSyncOrder}>
                        <Sync fontSize="small" />
                      </SyncBox>
                    </Grid>
                  )}
                </Grid>
              </CartGridContainerHeader>
            </>
          )}
        <CardWrapper container style={{ marginTop: '.5rem' }}>
          <SupplierDetailCardWrapper item container>
            <OrderInfoCard
              id={String(state.id).padStart(6, 0)}
              business={state}
              dateCreated={state.businessDateCreated}
              businessDateDelivered={state.businessDateDelivered}
              displayId="hide"
              affiliateInfoCard="order"
            />
          </SupplierDetailCardWrapper>
          <DeliveryAddressCard item container>
            <DeliveryAddressCardSection>
              <DeliveryAddressCardHeader>
                <DeliveryAddressCardHeaderTitle>Delivery Location</DeliveryAddressCardHeaderTitle>
                <DeliveryAddressCardHeaderSubTitle
                  onClick={() => setState({ ...state, addNewDeliveryLocation: true })}
                  type="button"
                >
                  Add Outlet
                </DeliveryAddressCardHeaderSubTitle>
              </DeliveryAddressCardHeader>
              <Grid style={{ marginBottom: '20px' }}>
                <SelectOutletText>
                  Select the outlet you want your order to be delivered to
                </SelectOutletText>
                <ChangeOutletRow>
                  <ReturnSelectField
                    field={getDeliveryLocationOption()}
                    value={state.selectedDeliveryLocation}
                    showCheckBox={false}
                    label=""
                    handleCreditDaysOpen={() => ({})}
                    fullWidth
                    handleChange={handleChangeDeliveryLocation}
                    showLabel={false}
                  />
                </ChangeOutletRow>
              </Grid>
              {renderSelectDeliveryLocationInfo()}
            </DeliveryAddressCardSection>
          </DeliveryAddressCard>
          <SupplierDetailCardWrapper item container>
            <DeliveryAddressCardSection>
              <NoteCardHeader>
                <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>
                <NoteButton
                  onClick={handleAddNote}
                  type="button"
                >
                  Add Note
                </NoteButton>
              </NoteCardHeader>
              {state?.notes && state?.notes?.slice(0, noteIndex).map((note) => (
                <NoteRow
                  note={note}
                  handleAddNote={handleAddNote}
                />
              ))}
              {!state.notes?.length || noteState ? (
                <Grid>
                  <NoteContainer>
                    <NoteTextField
                      value={state?.noteText}
                      multiline
                      rows={5}
                      placeholder="Enter note here"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ backgroundColor: '#f0f0f0', marginBottom: '4em' }}
                    />
                  </NoteContainer>
                  <SaveNoteButton
                    variant="contained"
                    fullWidth
                    onClick={handleSaveNote}
                  >
                    Save Note
                  </SaveNoteButton>
                </Grid>
              ) : '' }
              {state.notes?.length && !noteState ? <ViewAllNotes onClick={handleToggleNotes}>View All</ViewAllNotes> : '' }
            </DeliveryAddressCardSection>
          </SupplierDetailCardWrapper>
        </CardWrapper>
        {renderActiveComponent()}
        {viewProduct && (
          <>
            <SupplierItems
              state={state}
              handleChangeCondition={handleChangeCondition}
              handleIncrement={handleIncrementProductQuantityInCart}
              handleDecrement={handleDecrementProductQuantityInCart}
              handleChangeProductQuantity={handleChangeProductQuantityInputValue}
              handleMetaStatusUpdate={handleMetaStatusUpdate}
              quantityRecieve={quantityRecieve}
              handleSearch={handleSearch}
              handleCancelOrderDialog={handleCancelOrderDialog}
              handleBatchSelection={handleBatchSelection}
              handleConfirmedQtyInputValue={handleConfirmedQtyInputValue}
              handleQtyConfirmedIncrement={handleQtyConfirmedIncrement}
              handleQtyConfirmedDecrement={handleQtyConfirmedDecrement}
              qtyConfirmed={qtyConfirmed}
              viewProduct={viewProduct}
              setPageNumber={setPageNumber}
              setPageCount={setPageCount}
              pageCount={pageCount}
              pageNumber={pageNumber}
              orderProductsTotalNumber={orderProductsTotalNumber}
              pLoading={pLoading}
              searchText={searchText}
              handleNextPage={handleNextPage}
              handleNextCount={handleNextCount}
            />
            <Grid container>
              <OverviewFooter container sm={12} md={12} style={{ justifyContent: 'space-between' }}>
                <CancelButton
                  onClick={(e) => handleCancelOrderDialog(e)}
                  disabled={state.orderStatus === 'ORDER_DISPATCHED' || state.orderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || state.done}
                  md={3}
                >
                  <OverviewFooterText>
                    Cancel Order
                  </OverviewFooterText>
                  <DropDown style={{ fontSize: '.9rem' }} />
                </CancelButton>
                <DispatchButton
                  onClick={() => handleOpenPlaceOrderDialog()}
                  disabled={state.orderStatus !== 'ORDER_SORTED'
                    || !userPermissions?.includes('manage_orders_ready_for_dispatch')}
                  style={{ marginLeft: '1.5rem' }}
                >
                  <OrderButtonText>Ready for Dispatch</OrderButtonText>
                </DispatchButton>
              </OverviewFooter>
            </Grid>
          </>
        )}
        <ActionPopper
          action={action}
          handleAction={handleAction}
          handleClose={handleCloseModal}
        />
        <ConfirmDialog
          openDialog={openModal}
          setOpenDialog={setOpenModal}
          title="Cancel Order"
          desc="Are you sure you want to cancel this order?"
          options={['Cancel', 'Yes, Cancel Order']}
          confirmHanlder={handleCancelOrder}
          loading={state.cancelOrderLoading}
        />
        <InvoiceLogs orderId={ordId} />
      </CartGridContainer>

      <CDialog
        open={state.showPlaceOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState({ ...state, showPlaceOrderDialog: false })}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Place Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Mark this order ready for Dispatch?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <DialogCancelButton
            onClick={() => setState({ ...state, showPlaceOrderDialog: false })}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton
            onClick={() => !state.loading && handleDispatchOrder()}
            disabled={!userPermissions?.includes('manage_orders_dispatch')}
          >
            <DialogActionButtonText>
              {
                state.loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={16}
                    thickness={3}
                  />
                ) : 'OK'
              }
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </CDialog>

      <CDialog
        open={state.showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState({ ...state, showCancelOrderDialog: false })}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Cancel this order?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <DialogCancelButton
            onClick={() => setState({ ...state, showCancelOrderDialog: false })}
          >
            <DialogActionButtonText>
              No
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton
            onClick={() => handleCancelOrder()}
            disabled={state.cancelOrderLoading}
          >
            <DialogActionButtonText>
              {state.cancelOrderLoading ? 'Loading...' : 'Yes, Cancel Order'}
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </CDialog>

      <CDialog
        open={state.showErrorDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleReverseDispatch()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <HeaderText>Issues Encountered</HeaderText>
          <HeaderSubText>Requested quantities below are higher than stock available</HeaderSubText>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TableContainer>
              <Table aria-label="orders table">
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <HeaderCell>{header}</HeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state?.dispatchErrors.map((dispErr) => (
                    <ReturnRow
                      key={dispErr?.id}
                      dispErr={dispErr}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <SortButton
            onClick={() => !state.loading && handleReverseDispatch()}
          >
            <DialogActionButtonText>
              {
                state.loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={16}
                    thickness={3}
                  />
                ) : 'Sort'
              }
            </DialogActionButtonText>
          </SortButton>
        </DialogActions>
      </CDialog>

      <NoteDialog
        open={open}
        onClose={handleToggleNotes}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogContentStyled>
          <CloseStyled>
            <Close
              fontSize="small"
              onClick={handleToggleNotes}
              style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }}
            />
          </CloseStyled>
          <DeliveryAddressCardHeader>
            <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>
            <NoteButton
              onClick={handleNote}
              type="button"
            >
              Add Note
            </NoteButton>
          </DeliveryAddressCardHeader>
          {state?.notes && state?.notes?.map((note) => (
            <AllNoteRow
              note={note}
            />
          ))}
        </DialogContentStyled>
      </NoteDialog>
      <LabelPrintDialog
        dialogOpen={state.showPrintOrderDialog}
        closeDialog={() => setState({ ...state, showPrintOrderDialog: false })}
        state={state}
      />
      <SuccessDialog
        openConfirmation={successModal}
        handleClose={toggleSuccessModal}
        title="Order cancelled Succcesfully!"
        desc="Hi Pharm, Order has been successfully Cancelled!"
      />

      <SharedConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="You Have Unsaved Changes"
        desc="Any unsaved changes will be lost!"
        options={["Don't Save", 'Save Changes']}
        buttonAction={handleNextWithSave}
        handleDelete={handleNextWithoutSave}
      />

      <SharedSuccessDialog
        openDialog={openSuccessDialog}
        setOpenDialog={setOpenSuccessDialog}
        title={dialogText.title}
        desc={dialogText.discreption}
        option="Ok"
        therapeuticType
      />
    </MainContent>
  );
}

Dispatch.propTypes = {
  businessDeliveryLocations: PropTypes.instanceOf(Array).isRequired,
  orderData: PropTypes.instanceOf(Array).isRequired,
  ordId: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired
};
