import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Close } from '@mui/icons-material';
import {
  FilterPaper, CTablePagination, FilterByBox, FilterByText, ClearAllText,
  ListGrid, FilterContainer, FilterHandle, FilterNameTypo, PriceList, ClearText,
  ListItems, HeaderGrid, FilterTypo, ItemContentGrid, PriceItem, FooterGrid,
  TextGrid, SubCategoriesList, PriceHeader, FilterClose, AvailabilityGrid, BrandListGrid,
  BrandContentGrid, CustomCheckbox, ScrollBox, SubScrollBox, IconGrid, SubIconGrid,
} from './orderFilter.styles';
import { MANUFACTURER_QUERY } from '../../../../queries/businesses';
import TableLoader from '../../../shared/tableLoader';
import useSearchParamsState from '../../../shared/helpers/ulrSearchParams';
import { ArrowDown } from '../../../../assets/svgs';

const icon2 = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon2 = <CheckBoxIcon fontSize="medium" />;

const ArrowDownIcon = <ArrowDown fontSize="medium" />;

export default function OrderFilter(
  {
    subcategories, priceRange, icon, checkedIcon, handlePriceFilter, open, handleClose, handleBrandFilter,
    containerState, handleClearFilter, handleSubProducts, displaySubCategory, handleProductsAvailability
  }
) {
  const {
    priceMin, priceMax, therapeuticTypeIds, availabilityStatus, manufacturer
  } = containerState;

  const initialState = {
    suppliersDrop: true,
    priceDrop: true,
    subcategoriesDrop: true,
    productAvailability: true,
    brandDrop: true
  };

  const [pageCount] = useSearchParamsState('manufPc', '20');
  const [pageNumber, setPageNumber] = useSearchParamsState('manufPn', 1);
  const [state, setState] = useState(initialState);

  const productsAvailability = {
    readilyAvailable: 'Readily Available',
    onRequest: 'Out of Stock'
  };

  const handleDrop = (type) => setState((_state) => ({
    ..._state,
    [type]: !_state[type]
  }));

  const variables = { pageCount, pageNumber };
  const { loading, data } = useQuery(MANUFACTURER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const handleChangePage = (_, newPage) => {
    setPageNumber(+newPage + 1);
  };

  const { scManufacturers = [], scManufacturersTotalNumber = 0 } = data || {};

  const renderSubCategories = () => subcategories.map((category) => (
    <Grid key={category.id} xs={12}>
      <FilterHandle
        container
        justifyContent="space-between"
        onClick={() => handleSubProducts(category.id)}
      >
        <Grid item xs={1}>
          <CustomCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ color: '#3682C1' }}
            checked={therapeuticTypeIds === category.id}
          />
        </Grid>
        <Grid item container xs={11} style={{ paddingLeft: '20px', alignContent: 'center' }}>
          <FilterNameTypo>{category.therapeuticType}</FilterNameTypo>
        </Grid>
      </FilterHandle>
    </Grid>
  ));

  const renderPriceFilter = () => priceRange.map((price) => (
    <Grid key={price.displayPrice} xs={12}>
      <FilterHandle container justifyContent="space-between" onClick={() => handlePriceFilter(price)}>
        <Grid item xs={1}>
          <CustomCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ color: '#3682C1' }}
            checked={price.priceMin === priceMin && price.priceMax === priceMax}
          />
        </Grid>
        <Grid item container xs={11} style={{ paddingLeft: '20px', alignContent: 'center' }}>
          <FilterNameTypo>{price.displayPrice}</FilterNameTypo>
        </Grid>
      </FilterHandle>
    </Grid>
  ));

  const renderAvailabilityFilter = () => Object.entries(productsAvailability)?.map(([key, value]) => (
    <Grid key={key} xs={12}>
      <FilterHandle data-testid={`availability-option-${key}`} container justifyContent="space-between" onClick={() => handleProductsAvailability(key)}>
        <Grid item xs={1}>
          <CustomCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ color: '#3682C1' }}
            checked={availabilityStatus === key}
          />
        </Grid>
        <Grid item container xs={11} style={{ paddingLeft: '20px', alignContent: 'center' }}>
          <FilterNameTypo data-testid={`availability-option-${key}`}>{value}</FilterNameTypo>
        </Grid>
      </FilterHandle>
    </Grid>
  ));

  const renderBrandFilter = () => {
    const sorting = [...scManufacturers]?.sort((a, b) => a.name.localeCompare(b.name));
    return sorting.map((brand) => (
      <Grid key={brand.name} xs={12}>
        <FilterHandle container justifyContent="space-between" onClick={() => handleBrandFilter(Number(brand.id))}>
          <Grid item xs={1}>
            <CustomCheckbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ color: '#3682C1' }}
              checked={manufacturer.includes(Number(brand.id))}
            />
          </Grid>
          <Grid item container xs={11} style={{ paddingLeft: '20px', alignContent: 'center' }}>
            <FilterNameTypo>{brand.name}</FilterNameTypo>
          </Grid>
        </FilterHandle>
      </Grid>
    ));
  };

  const {
    priceDrop, subcategoriesDrop, productAvailability, brandDrop
  } = state;

  return (
    <FilterContainer>
      {open && (
        <FilterClose>
          <Close
            fontSize="large"
            style={{
              position: 'absolute', cursor: 'pointer', marginBottom: '20px',
              fontSize: '3.5rem'
            }}
            onClick={() => handleClose('close')}
          />
        </FilterClose>
      )}
      <FilterPaper>
        <FilterByBox container>
          <Grid item xs={8}>
            <FilterByText>Filter By</FilterByText>
          </Grid>
          <Grid item xs={4}>
            <ClearAllText onClick={() => handleClearFilter('clearFilters')}>Clear All</ClearAllText>
          </Grid>
        </FilterByBox>
        {displaySubCategory
          && (
            <BrandListGrid>
              {/* <FilterGrid container>
                <FilterBox item xs={8}>
                  <FilterHeader>
                    Sub Categories
                  </FilterHeader>
                </FilterBox>
                <FilterBox item xs={4}>
                  <FilterText onClick={() => handleClearFilter('therapeuticType')}>
                    Clear all
                  </FilterText>
                </FilterBox>
              </FilterGrid> */}
              <SubCategoriesList>
                <ListGrid>
                  <ListItems container item>
                    <HeaderGrid container item onClick={() => handleDrop('subcategoriesDrop')}>
                      <TextGrid item>
                        <FilterTypo>Sub Category</FilterTypo>
                      </TextGrid>
                      <SubIconGrid item>
                        {subcategoriesDrop
                          ? <ExpandMoreIcon fontSize="small" />
                          : <ExpandLessIcon fontSize="small" />}
                      </SubIconGrid>
                    </HeaderGrid>
                    {subcategoriesDrop
                    && (
                      <SubScrollBox>
                        <ItemContentGrid container item>
                          {subcategoriesDrop && renderSubCategories()}
                        </ItemContentGrid>
                      </SubScrollBox>
                    )}
                  </ListItems>
                </ListGrid>
              </SubCategoriesList>
            </BrandListGrid>
          )}
        <AvailabilityGrid category={displaySubCategory}>
          <ListGrid>
            <ListItems container item>
              <PriceHeader container item onClick={() => handleDrop('productAvailability')}>
                <TextGrid item>
                  <FilterTypo>Product Availability</FilterTypo>
                </TextGrid>
                <Grid item>
                  {productAvailability
                    ? <ExpandMoreIcon fontSize="small" />
                    : <ExpandLessIcon fontSize="small" />}
                </Grid>
              </PriceHeader>
              {productAvailability
              && (
                <PriceItem container item>
                  {renderAvailabilityFilter()}
                </PriceItem>
              )}
            </ListItems>
          </ListGrid>
        </AvailabilityGrid>
        <PriceList category={displaySubCategory}>
          <ListGrid>
            <ListItems container item>
              <PriceHeader container item onClick={() => handleDrop('priceDrop')}>
                <TextGrid item>
                  <FilterTypo>Product Price</FilterTypo>
                </TextGrid>
                <Grid item>
                  {priceDrop
                    ? <ExpandMoreIcon fontSize="small" />
                    : <ExpandLessIcon fontSize="small" />}
                </Grid>
              </PriceHeader>
              {priceDrop
              && (
                <PriceItem container item>
                  {priceDrop && renderPriceFilter()}
                </PriceItem>
              )}
            </ListItems>
          </ListGrid>
        </PriceList>

        <BrandListGrid category={displaySubCategory}>
          <ListGrid>
            <ListItems container item>
              <PriceHeader container item>
                <TextGrid item container xs={10}>
                  <FilterTypo xs={6}>Product Brands</FilterTypo>
                  {/* <ClearText xs={6} onClick={() => handleClearFilter('manufacturer')}>Clear</ClearText> */}
                </TextGrid>
                <IconGrid item onClick={() => handleDrop('brandDrop')}>
                  {brandDrop
                    ? <ExpandMoreIcon fontSize="small" />
                    : <ExpandLessIcon fontSize="small" />}
                </IconGrid>
              </PriceHeader>
              { loading ? (
                <Box style={{ width: '100%' }}>
                  <TableLoader />
                </Box>
              )
                : (
                  brandDrop && (
                    <ScrollBox>
                      <BrandContentGrid container item>
                        {brandDrop && renderBrandFilter()}

                        <FooterGrid>
                          <CTablePagination
                            rowsPerPageOptions={[20]}
                            component="div"
                            count={+scManufacturersTotalNumber}
                            page={pageNumber - 1}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageCount}
                          />
                        </FooterGrid>
                      </BrandContentGrid>
                    </ScrollBox>
                  )
                )}
            </ListItems>
          </ListGrid>
        </BrandListGrid>
      </FilterPaper>
    </FilterContainer>
  );
}

OrderFilter.propTypes = {
  containerState: PropTypes.instanceOf(Object),
  subcategories: PropTypes.instanceOf(Array),
  handleClearFilter: PropTypes.func.isRequired,
  handleSubProducts: PropTypes.func.isRequired,
  priceRange: PropTypes.instanceOf(Object),
  handlePriceFilter: PropTypes.func.isRequired,
  checkedIcon: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  displaySubCategory: PropTypes.bool.isRequired,
  handleProductsAvailability: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBrandFilter: PropTypes.func.isRequired
};

OrderFilter.defaultProps = {
  containerState: [],
  subcategories: [],
  priceRange: [],
  checkedIcon: checkedIcon2,
  icon: icon2,
};
