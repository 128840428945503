import { InputBase, Typography, CircularProgress } from '@mui/material';

import { styled } from '@mui/material/styles';

export const CInputBase = styled(InputBase)`
  border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #d8d8d8')};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : '')};
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #606060;
  line-height: 3.2;

  & .MuiFilledInput-root: {
    background-color: transparent;
  }
  & .MuiInputBase-input {
    background-color: transparent;

  }

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }



  & .MuiInputBase-input {
    padding: ${({ cSize }) => (cSize === 'lg' ? '18px' : '12px')};
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 12px;
    margin-left: 8px;
  }

  &:active, &:focus, &:focus-within {
    border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #303030')};
  }

  @media(max-width: 991px) {
    & .MuiInputBase-input {
      height: 3rem;
      font-size: 1.5rem;
    }
  }
`;

export const Label = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ error }) => (error ? '#ff0000' : '#464f5b')};
  margin-bottom: .2rem;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  color: #ff0000;
  opacity: 0.8;
  margin-left: 10px;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #606060;
  margin-top: 4px;
`;
