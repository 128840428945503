export const addItemToCart = (cartItems, payload) => {
  const { product, counter } = payload;
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === product.id
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) => (cartItem.id === existingCartItem.id
      ? { ...cartItem, quantity: cartItem.quantity + counter }
      : cartItem));
  }

  return [...cartItems, { ...product, quantity: counter }];
};

export const updateCartCounter = (cartTot) => cartTot;

export const updateCartCount = (total) => total;

export const updateCartItems = (cartItems, payload) => {
  const { id, type, qty } = payload;
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === id
  );
  let updatedCartItems;
  if (type === 'add') {
    if (existingCartItem) {
      updatedCartItems = cartItems.map((item) => (item.id === id ? { ...item, qty } : item));
    } else {
      updatedCartItems = [...cartItems, { id, qty }];
    }
  } else if (type === 'remove') {
    updatedCartItems = cartItems.filter((item) => item.id !== id);
  } else if (type === 'clear') {
    updatedCartItems = [];
  }
  return updatedCartItems;
};
