import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import {
  FooterGrid, LogoWrapper, NewAdminLogo, OgaAdminLogo, Rights, RightsContainer, Text, TextWrapper
} from './footer.styles';

export const FooterComponents = ({ nonAuthPage }) => {
  const isSmallScreen = useMediaQuery('(max-width: 991px)');

  return (
    <FooterGrid item container xs={12}>
      {!isSmallScreen ? (
        <>
          <Grid xs={4}><Rights>© 2023 OGApharmacy. All rights reserved.</Rights></Grid>
          <LogoWrapper xs={4}>
            {nonAuthPage ? <NewAdminLogo /> : <OgaAdminLogo /> }
          </LogoWrapper>
          <Grid xs={3} justifyContent="flex-end">
            <TextWrapper container>
              <Text>Terms of Service</Text>
              <Text>Privacy Policy</Text>
            </TextWrapper>
          </Grid>
        </>
      ) : (
        <>
          <LogoWrapper container>
            {nonAuthPage ? <NewAdminLogo /> : <OgaAdminLogo /> }
          </LogoWrapper>
          <RightsContainer container><Rights>© 2023 OGApharmacy. All rights reserved.</Rights></RightsContainer>
          <Grid container justifyContent="center">
            <TextWrapper container>
              <Text>Terms of Service</Text>
              <Text>Privacy Policy</Text>
            </TextWrapper>
          </Grid>
        </>
      )}

    </FooterGrid>
  );
};

FooterComponents.propTypes = {
  nonAuthPage: PropTypes.string
};

FooterComponents.defaultProps = {
  nonAuthPage: ''
};

export default FooterComponents;
