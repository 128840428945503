import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery, Box
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import allImg from '../../../../assets/images/marketOrder/all.png';
import {
  CategoryGrid, CardPaper, CategoryText, CardBox, CategoriesWrapper,
  StyledIcon
} from './categoriesContainer.styles';
import {
  Medicine, MedicalPill, Tissue, Injection, Sanitiser, Health, MedicalBottle, MedicalKit
} from '../../../../assets/svgs';
import { MARKETPLACE_CATEGORIES } from '../../../../queries/categories';

export default function CategoriesContainer({
  isSelected, productPage, handleTabChange
}) {
  const categoryImg = [
    Tissue, Injection, MedicalPill, Sanitiser, Medicine, MedicalBottle, Health, MedicalKit
  ];
  const carouselRef = useRef();
  const navigate = useNavigate();
  const {
    loading, data: categoriesList
  } = useQuery(MARKETPLACE_CATEGORIES, {
    fetchPolicy: 'cache-first',
  });
  const handleAllSelect = () => {
    handleTabChange('all');
    navigate('/new-order');
  };

  const handleSelect = (id, name) => {
    const category = name;
    const categoryId = id;
    navigate(`/new-order/${category}/${categoryId}`);
  };

  const categories = categoriesList?.categories || [];
  const smallScreen = useMediaQuery('(max-width: 991px)');

  const renderCategories = () => categories.map((category, index) => {
    const SelectedIcon = categoryImg[index] || null;

    return (
      <CardPaper
        active={isSelected === category.therapeuticType}
        key={category.id}
        onClick={() => handleSelect(category.id, category.therapeuticType)}
      >
        <CardBox>
          {SelectedIcon && (
            <StyledIcon>
              <SelectedIcon />
            </StyledIcon>
          )}
          <CategoryText>{category.therapeuticType}</CategoryText>
        </CardBox>
      </CardPaper>
    );
  });

  return (
    <Box>
      {!smallScreen ? (
        <CategoriesWrapper>
          <CategoryGrid>
            {renderCategories()}
            <CardPaper active={isSelected === 'All Categories'} onClick={() => handleAllSelect()}>
              <CardBox>
                <StyledIcon>
                  <MedicalKit />
                </StyledIcon>
                <CategoryText>
                  Others
                </CategoryText>
              </CardBox>
            </CardPaper>
          </CategoryGrid>
        </CategoriesWrapper>
      )
        : (
          <CategoriesWrapper productPage={productPage}>
            <CategoryGrid container ref={carouselRef}>
              {renderCategories()}
              <CardPaper active={isSelected === 'All Categories'} onClick={() => handleAllSelect()}>
                <CardBox>
                  <StyledIcon>
                    <MedicalKit />
                  </StyledIcon>
                  <CategoryText>
                    Others
                  </CategoryText>
                </CardBox>
              </CardPaper>
            </CategoryGrid>
          </CategoriesWrapper>
        )}
    </Box>
  );
}

CategoriesContainer.propTypes = {
  // handleSelect: PropTypes.func.isRequired,
  // handleCategories: PropTypes.func.isRequired,
  isSelected: PropTypes.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  productPage: PropTypes.bool.isRequired,
};
