import { gql } from '@apollo/client';

export const CREATE_BUSINESS_MUTATION = gql`
  mutation createBusiness(
    $legalName: String!
    $contactName: String!
    $businessType: String
    $logo: String
    $mobile: String!
    $needsLoan: Boolean
    $email: String!
    $secondaryEmailAddress: String
    $tax: Float
    $addressLine1: String!
    $addressLine2: String
    $createdBy: String
    $city: String!
    $country: String!
    $terms: Boolean!
    $creditDays: Int
    $salesRepresentative: String
    $landmark: String
    $state: String
  ) {
    createBusiness(
      legalName: $legalName
      businessType: $businessType
      primaryContactName: $contactName
      logo: $logo
      mobileNumber: $mobile
      needsLoan: $needsLoan
      primaryEmailAddress: $email
      secondaryEmailAddress: $secondaryEmailAddress
      tax: $tax
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      country: $country
      terms: $terms
      createdBy: $createdBy
      creditDays: $creditDays
      salesRepresentative: $salesRepresentative
      landmark: $landmark
      state: $state
    ){
      message
      business {
        id
        status
      }
      user {
        id
        businessuserSet {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_BUSINESS_MUTATION = gql`
  mutation updateBusiness(
    $id: Int!
    $legalName: String
    $contactName: String
    $logo: String
    $mobile: String
    $email: String
    $businessType: String
    $secondaryEmailAddress: String
    $tax: Float
    $status: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $country: String
    $terms: Boolean
    $reasonForUpdate: String!
    $creditDays: Int
    $license: [LicenseType]
    $salesRepresentative: String
    $landmark: String
    $state: String
    $categoryId: Int!
    $creditLimit: Float
    $gracePeriod: Int
  ) {
    updateBusiness(
      id: $id
      legalName: $legalName
      primaryContactName: $contactName
      logo: $logo
      mobileNumber: $mobile
      primaryEmailAddress: $email
      businessType: $businessType
      secondaryEmailAddress: $secondaryEmailAddress
      tax: $tax
      status: $status
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      country: $country
      terms: $terms
      reasonForUpdate: $reasonForUpdate
      creditDays: $creditDays
      license: $license
      salesRepresentative: $salesRepresentative
      landmark: $landmark
      state: $state
      categoryId: $categoryId
      creditLimit: $creditLimit
      gracePeriod: $gracePeriod
    ){
      message
      business {
        id
        status
      }
    }
  }
`;
export const UPDATE_BUSINESS_META_MUTATION = gql`
  mutation updateBusinessMeta(
    $keyValues: [ModelMetaType]!, $jsonKey: String!
  ) {
    updateBusinessMeta(
      keyValues: $keyValues
      jsonKey: $jsonKey
    ){
      message
    }
  }
`;
export const DELETE_SUB_BUSINESS_PRODUCT_VERSIONS = gql`
  mutation deleteSubBusinessProductVersions {
    deleteSubBusinessProductVersions{
      message
    }
  }
`;

export const OFFLINE_SYNC = gql`
  mutation sync {
    sync{
      message
    }
  }
`;

export const CREATE_SUB_BUSINESS_MUTATION = gql`
mutation createSubBusiness(
    $name: String!, $dateLaunched: String!, $businessType: String!, $primaryContactName: String!,
    $mobileNumber: String!, $primaryEmailAddress: String!, $addressLine1: String!,
    $city: String!, $country: String!
  ){
    createSubBusiness(
      name: $name, primaryContactName: $primaryContactName, businessType: $businessType,
      dateLaunched: $dateLaunched, mobileNumber: $mobileNumber, primaryEmailAddress: $primaryEmailAddress,
      addressLine1: $addressLine1, city: $city, country: $country
    ){
      subBusiness{
        name
        meta
      }
      message
    }
  }
`;

export const UPDATE_BUSINESS_ROLE_PERMISSIONS = gql`
  mutation updateBusinessRolePermissions(
    $businessRoleId: Int!, $permissions: [Int]!, $reasonForUpdate: String!
  ) {
    updateBusinessRolePermissions(
      businessRoleId: $businessRoleId,  permissions: $permissions, reasonForUpdate: $reasonForUpdate
    ){
      message
    }
  }
`;

export const CREATE_BUSINESS_ROLE = gql`
  mutation createSystemRole(
    $permissionNames: [String]!, $name: String!
  ) {
    createSystemRole(
      permissionNames: $permissionNames, name: $name
    ){
      message
    }
  }
`;

export const DELETE_BUSINESS_ROLE = gql`
  mutation deleteSystemRole(
    $roleId: Int!
  ) {
    deleteSystemRole(
      roleId: $roleId,
    ){
      message
    }
  }
`;

export const UPDATE_BUSINESS_ROLE = gql`
  mutation editSystemRole(
    $roleId: Int!, $updatedName: String!, $permissionNames: [String]
  ) {
    editSystemRole(
      roleId: $roleId, updatedName: $updatedName, permissionNames: $permissionNames
    ){
      message
    }
  }
`;
