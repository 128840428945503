import {
  Typography, Grid, ButtonGroup, Button, TextField, Box,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { MinusFrame, AddFrame } from '../../../../assets/svgs';

export const DetailGrid = styled(Grid)`
  height: 32.5rem;
  margin-top: 20px;
  border-radius: 4px;
  border: 0.5px solid rgba(227, 231, 234, 1);
  box-shadow: 0px 6px 48px 0px rgba(48, 48, 48, 0.03);

  @media (max-width: 991px) {
    height: 46rem;
    border-radius: 4px;
    margin-top: 40px;
    padding: 0px;
  }
`;

export const DetailContentGrid = styled(Grid)`
  height: 16rem;
  position: relative;
  padding: 1rem 0;
  @media (max-width: 991px) {
    height: 17rem;
    padding-bottom: 10px;
  }
`;

export const ImgLogo = styled('img')`
    width: 50%;
    display: block;
    margin: auto;
    height: 100%;

`;

export const DetailHeader = styled(Typography)`
    color: #7a8b96;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 15px;

    @media (max-width: 991px) {
      font-size: 26px;
      font-weight: 400;
    }
`;

export const DetailTypo = styled(Typography)`
    color: #383d43;
    font-weight: 500;
    font-size: 1rem;
    margin-top: .4rem;

    @media (max-width: 991px) {
      font-size: 26px;
      font-weight: 500;
    }
`;

export const InputButtonGroup = styled(ButtonGroup)`
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.MuiButtonGroup-root {
    height: 46px;
    width: 100%;
    padding: 3px 0px;
  }

  @media (max-width: 991px) {
    &.MuiButtonGroup-root {
      padding: 3px 1rem;

    }
  }
`;

export const ActionButton = styled(Button)`
  border: none;

  &:disabled {
    border: none;
  }

  &:hover {
    border: none;
    background: none;
  }

  @media (min-width: 992px) {
    &.MuiButtonGroup-grouped {
      min-width: 0;
    }

    &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
      margin-left: -15px;
    }
  }
`;

export const ActionTextField = styled(TextField)`
  & .MuiInputBase-input {
    text-align: center;
    font-weight: 600;
    font-size: 1.313rem;
    color: #212121;
  }
  & ::placeholder {
    text-align: center;
    color: #212121;
    font-weight: 600;
    font-size: 1.313rem;
  }

  & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
    font-size: 1.3em;
    width: 1.2rem;
    text-align: center;
    color: #212121;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 3rem;
      width: 10rem;
    }
    & .MuiInputBase-root {
      font-size: 2.7rem;
      padding: 5px 15px 5px;
    }
    & .MuiInputBase-input {
      text-align: center;
      font-weight: 600;
      font-size: 2.6rem;
      color: #212121;
    }
    & ::placeholder {
      text-align: center;
      color: #212121;
      font-weight: 600;
      font-size: 2.6rem;
    }
  }

  @media (min-width: 992px) {
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 1.313rem;
    }
  }
`;

export const ActionImg = styled('img')`
    width: 20px;

    @media (max-width: 991px) {
        width: 100px;
    }
`;

export const Content = styled(Box)`
  padding: 20px;
  position: relative;

  @media (max-width: 991px) {
    padding: 20px 40px;
  }
`;

export const AddButton = styled(Button)`
  border-radius: 8px;
  background-color: #245A91;
  margin-top: 20px;
  color: #fff;
  height: 46px;

  &:hover {
      background-color: #245A91;
      color: #fff;
  }


  &.MuiButton-contained.Mui-disabled {
      color: #fff;
      opacity: 0.5;
  }

  @media(min-width: 992px) {
      border-radius: 4px;
  }

  @media (max-width: 991px) {
    height: 70px;
    border-radius: 4px;
  }
`;

export const AddCartTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 27px;
    font-weight: 600;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
    color: #C5C5C5;
`;

const isAvailable = css`
  background-color: #7A2ECC;
`;

const isStockOut = css`
  background-color: #e74c3c;
`;

const isMutable = css`
  background-color: #e79800;
`;
const isClearance = css`
  background-color: #235A91;
`;

const getStyles = ({ type }) => {
  switch (type) {
    case '24':
      return isAvailable;
    case '72':
      return isStockOut;
    case 'mutable':
      return isMutable;
    case 'clearanceSale':
      return isClearance;
    default:
      return isAvailable;
  }
};

export const DivBullet = styled('span')`
  height: 8px;
  width: 8px;
  border-radius: 50%;

  @media (max-width: 991px) {
    height: 15px;
    width: 15px;
  }

  ${getStyles}
`;

const getBGColor = (status) => {
  switch (status) {
    case 72:
      return '#fff4f3';
    case 'clearanceSale':
      return '#F3F9FF';
    default:
      return '#F5ECFF';
  }
};

const getTextColor = (status) => {
  switch (status) {
    case 72:
      return '#E42900';
    case 'clearanceSale':
      return '#235A91';
    default:
      return '#7A2ECC';
  }
};

export const ProductVariantText = styled('p')`
  position: absolute;
  right: 30px;
  top: 25px;
  text-align: center;
  font-weight: 500;
  font-weight: 500;
  font-size: 12px;
  padding: 8px 10px;
  color: ${({ status }) => (getTextColor(status))};
  background-color: ${({ status }) => (getBGColor(status))};
  border-radius: 10px;
  border-radius: 5px;

  @media(max-width: 991px) {
    font-size: 1.5rem;
    border-radius: 20px;
    padding: 10px 15px;


  }
`;

export const PlusIcon = styled(AddFrame)`
&.MuiSvgIcon-root {
  font-size: 3rem;
}

@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    font-size: 7.5rem;
  }
}
`;

export const MinusIcon = styled(MinusFrame)`
  &.MuiSvgIcon-root {
    font-size: 3rem;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      font-size: 7.5rem;
    }
  }
`;

export const BottonGrid = styled(Grid)`
  background-color: #F3F7FC;
  padding: 0px 5px;
  border-radius: 4px;
  @media(max-width: 991px) {
    padding: .9rem;
  }
`;
